import * as React from 'react'

import classNames from 'classnames'

import { ReactComponent as Add } from '../../../assets/svg/add.svg'
import { ReactComponent as Delete } from '../../../assets/svg/decrement.svg'
import Spinner from '../../Presentational/Spinner/Spinner'

import { BUTTON_TYPES } from './constants'
import { IProps } from './types'

import styles from './Button.module.scss'

const Button: React.FunctionComponent<IProps> = ({
  placeholder,
  type,
  onClick,
  className,
  children,
  disabled,
  id,
  loading
}) => {
  const buttonClass = classNames(className, {
    [styles.button]: type === BUTTON_TYPES.GREEN,
    [styles.buttonGrey]: type === BUTTON_TYPES.GREY,
    [styles.buttonText]: type === BUTTON_TYPES.TEXT,
    [styles.buttonRadial]: type === BUTTON_TYPES.RADIAL,
    [styles.buttonTransparent]: type === BUTTON_TYPES.TRANSPARENT,
    [styles.buttonRadialGrey1]: type === BUTTON_TYPES.RADIAL_GREY_1,
    [styles.buttonRadialGreen1]: type === BUTTON_TYPES.RADIAL_GREEN_1,
    [styles.buttonRadialGreen2]: type === BUTTON_TYPES.RADIAL_GREEN_2,
    [styles.buttonRadialGreen3]: type === BUTTON_TYPES.RADIAL_GREEN_3,
    [styles.buttonRadialGreen4]: type === BUTTON_TYPES.RADIAL_GREEN_4,
    [styles.buttonRadialGreen5]: type === BUTTON_TYPES.RADIAL_GREEN_5,
    [styles.buttonRadialDecrement]: type === BUTTON_TYPES.RADIAL_DECREMENT
  })

  return (
    <button className={buttonClass} id={id} disabled={disabled} onClick={onClick}>
      {loading ? <Spinner fill="#ffffff" /> : null}
      {!loading ? (
        type === BUTTON_TYPES.RADIAL_DECREMENT ? (
          <Delete className={styles.iconDecrement} />
        ) : children ? (
          children
        ) : placeholder ? (
          <p>{placeholder}</p>
        ) : (
          <Add className={styles.icon} />
        )
      ) : null}
    </button>
  )
}

export default Button
