import * as React from 'react'

import { Link, RouteComponentProps } from 'react-router-dom'

import { IState } from './types'

import Input from '../../../../../Components/FormElements/Input/Input'
import Button from '../../../../../Components/FormElements/Button/Button'
import { ReactComponent as Arrow } from '../../../../../assets/svg/arrowRight.svg'

import { showTextError, getUserToken, showSucces, showError } from '../../../../../Utils/helpers'
import { ROUTES } from '../../../../../Utils/routes'
import { updatePassword } from '../../../../../Utils/api'
import { validateNewPassword } from '../../../../../Utils/constants/validation'
import styles from './ChangePassword.module.scss'
import { AxiosError } from 'axios'

class ChangePassword extends React.Component<RouteComponentProps, IState> {
  readonly state: IState = {
    isLoading: false,
    passwords: {
      old: '',
      new: '',
      confirm: ''
    }
  }

  handleChange = (id: string, value: string) => {
    this.setState((state: IState) => ({
      passwords: { ...state.passwords, [id]: value }
    }))
  }

  handleSubmit = () => {
    const { passwords } = this.state
    const token = getUserToken()

    if (passwords.new !== passwords.confirm) {
      showTextError('Please make sure passwords match!')
      return
    } else if (!validateNewPassword(passwords.new)) {
      showTextError('Password not strong enough!')
      return
    }

    if (token) {
      this.setState({ isLoading: true })
      updatePassword({ password: passwords.old, newPassword: passwords.new }, token)
        .then(() => {
          showSucces('Password has been updated!')
          this.props.history.push(ROUTES.ACCOUNT_SETTINGS)
        })
        .catch((err: AxiosError) => {
          this.setState({ isLoading: false })
          showError(err)
        })
    }
  }

  handleDiscard = () => {
    this.props.history.push(ROUTES.ACCOUNT_SETTINGS)
  }

  render() {
    const { passwords, isLoading } = this.state

    return (
      <>
        <Input
          id="old"
          type="password"
          label="Old password"
          value={passwords.old}
          placeholder="*********"
          className={styles.input}
          onChange={this.handleChange}
        />
        <Input
          id="new"
          type="password"
          label="New passsword"
          value={passwords.new}
          placeholder="*********"
          className={styles.input}
          onChange={this.handleChange}
        />
        <Input
          id="confirm"
          type="password"
          placeholder="*********"
          label="Confirm password"
          className={styles.input}
          value={passwords.confirm}
          onChange={this.handleChange}
        />
        <div className={styles.buttonContainer}>
          <Button className={styles.buttonText} type="text" onClick={this.handleDiscard}>
            <p>Discard</p>
          </Button>
          <Button
            className={`${styles.buttonSubmit} ${isLoading ? styles.buttonLoading : ''}`}
            onClick={this.handleSubmit}
            type="green"
            loading={isLoading}
          >
            <p>Save new info</p>
            <div className={styles.buttonDot}>
              <Arrow />
            </div>
          </Button>
        </div>
      </>
    )
  }
}

export default ChangePassword
