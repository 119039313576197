import * as React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { ReactComponent as Remove } from '../../../assets/svg/remove.svg'
import { ReactComponent as Add } from '../../../assets/svg/add.svg'
import { getTotalMeals, getOrderType, getPackageType } from '../../../Redux/Services/OrderService/helpers'
import { ORDER_TYPE } from '../../../Utils/constants'
import { IReduxStore } from '../../../Utils/types'
import { IProps, IState } from './types'
import styles from './Counter.module.scss'

class Counter extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    amount: 0
  }

  componentDidMount() {
    const { initialAmount } = this.props

    initialAmount && this.setState({ amount: initialAmount })
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.orderType !== this.props.orderType) {
      this.setState({ amount: 0 })
    }

    if (prevProps.initialAmount !== this.props.initialAmount && this.props.initialAmount !== undefined) {
      this.setState({ amount: this.props.initialAmount })
    }
  }

  handleIncrement = () => {
    const { orderType, type, total, packageType } = this.props

    if (orderType === ORDER_TYPE.SUBSCRIPTION && type === 'meal') {
      if (total < packageType!) {
        this.setState((state: IState) => ({ amount: state.amount + 1 }), () => this.props.onChange(this.state.amount))
      }
    } else {
      this.setState((state: IState) => ({ amount: state.amount + 1 }), () => this.props.onChange(this.state.amount))
    }
  }

  handleDecrement = () => {
    if (this.state.amount > 0) {
      this.setState((state: IState) => ({ amount: state.amount - 1 }), () => this.props.onChange(this.state.amount))
    }
  }

  render() {
    const { disabled, className } = this.props
    const { amount } = this.state

    const counterClass = classNames(styles.counter, {
      [styles.counterDisabled]: disabled,
      [styles.counterGreen]: amount > 0,
      [`${className}`]: className
    })

    const iconClass = classNames(styles.icon, {
      [styles.iconDisabled]: disabled,
      [styles.iconGreen]: amount > 0
    })

    return (
      <div className={counterClass}>
        <div className={styles.items}>
          <Remove className={iconClass} onClick={this.handleDecrement} />
          <p>{amount}</p>
          <Add className={iconClass} onClick={this.handleIncrement} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  total: getTotalMeals(state),
  orderType: getOrderType(state),
  packageType: getPackageType(state)
})

export default connect(mapStateToProps)(Counter)
