import * as React from 'react'

import classNames from 'classnames'

import { ReactComponent as Arrow } from '../../../../../assets/svg/arrowRight.svg'

import styles from './Plan.module.scss'

interface IProps {
  amount: number
  active?: boolean
  className?: string
  onClick: () => void
}

const Plan: React.FunctionComponent<IProps> = ({ amount, className, active, onClick }) => {
  const containerClass = classNames(styles.container, className, {
    [styles.containerActive]: active
  })

  return (
    <div className={containerClass}>
      <div className={styles.top}>
        <div className={styles.discountContainer}>
          <div className={styles.line}>
            <div />
          </div>
        </div>
        {!active ? (
          <button className={styles.button} onClick={onClick}>
            <Arrow className={styles.icon} />
          </button>
        ) : null}
      </div>
      <div className={styles.info}>
        <h3>
          <span className={styles.amount}>{amount}</span>
          <span className={styles.mealsText}>meals</span>
        </h3>
        <p className={styles.weekText}>per week</p>
      </div>
      {active && <p className={styles.activeText}>Your package</p>}
    </div>
  )
}

export default Plan
