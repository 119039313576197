import * as React from 'react'

import classNames from 'classnames'
import IMask from 'imask'

import { IProps, IState } from './types'

import styles from './Input.module.scss'

const MASKED_PHONE = IMask.createMask({
  mask: '+ 1 - 000 - 000 - 0000'
})

class Input extends React.Component<IProps, IState> {
  input = React.createRef<HTMLInputElement>()

  state = {
    isInvalid: false
  }

  handleChange = (e: any) => {
    e.preventDefault()
    if (this.props.type === 'phone') {
      const masked = MASKED_PHONE.resolve(e.target.value)
      this.props.onChange(this.props.id, masked)
    } else {
      this.props.onChange(this.props.id, e.target.value)
    }
  }

  handleFocus = () => {
    this.input.current!.focus()
  }

  handleBlur = () => {
    this.input.current!.blur()
  }

  handleValidate = () => {
    if (this.props.validate) {
      !this.props.validate(this.props.value) ? this.setState({ isInvalid: true }) : this.setState({ isInvalid: false })
    }
  }

  render() {
    const {
      value,
      label,
      className,
      id,
      placeholder,
      maxLength,
      hint,
      onEnter,
      onBlur,
      onFocus,
      disabled,
      type
    } = this.props
    const { isInvalid } = this.state
    const containerClass = classNames(styles.container, className)

    return (
      <div className={containerClass}>
        <label className={styles.label}>{label}</label>
        <input
          id={id}
          value={value}
          ref={this.input}
          disabled={disabled}
          maxLength={maxLength}
          className={styles.input}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={this.handleValidate}
          type={type === 'password' ? type : 'text'}
          onBlurCapture={() => (onBlur ? onBlur(id) : null)}
          onFocus={() => this.setState({ isInvalid: false })}
          onFocusCapture={() => (onFocus ? onFocus(id) : null)}
          onKeyDown={(e) => e.keyCode === 13 && onEnter && onEnter()}
        />
        {isInvalid && hint && <p className={styles.hint}>{hint}</p>}
      </div>
    )
  }
}

export default Input
