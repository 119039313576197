import * as React from 'react'
import { IProps } from './types'
import { ReactComponent as Clock } from '../../../assets/svg/Clock.svg'
import styles from './ContentHeader.module.scss'

const ContentHeader: React.FunctionComponent<IProps> = ({ title, deliveryDate, isSubscription }) => (
  <div className={styles.contentHeader}>
    <h3 className={styles.h3}>{title}</h3>
    {deliveryDate && isSubscription && (
      <div className={styles.deliveryDate}>
        <Clock />
        <h4 className={styles.h4}>Next Delivery date:</h4>
        <p className={styles.p}>{deliveryDate.format('MM-DD-YYYY')}</p>
      </div>
    )}
  </div>
)

export default ContentHeader
