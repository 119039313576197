export const ROUTES = {
  MAIN: '/',

  REPORT: '/ZA7FNQ97XrRI8SWnrNAmUqEYIkWORRcq',
  ORDER: '/order',
  DISHES: '/order/dishes',
  ADDONS: '/order/addons',
  SUMMARY: '/order/summary',
  PAYMENT: '/order/payment',
  SHIPPING: '/order/shipping',
  SUCCESS: '/order/success',
  USER_ACTIVATE: '/activate/:email/:hash',

  ACCOUNT: '/account',
  ACCOUNT_BILLING: '/account/billing',
  ACCOUNT_HISTORY: '/account/history',
  ACCOUNT_HISTORY_ITEM: '/account/history/:itemId',
  ACCOUNT_SHIPPING: '/account/shipping',
  ACCOUNT_SETTINGS: '/account/settings',
  ACCOUNT_SETTINGS_PASSWORD: '/account/settings/password',
  ACCOUT_SETTINGS_INFO: '/account/settings/info',
  ACCOUNT_SUBSCRIBE: '/account/subscribe',
  ACCOUNT_ACTIVE_ORDER: '/account/subscription'
}
