import * as React from 'react'
import { connect } from 'react-redux'

import {
  getTotalMeals,
  getPackageType,
  getOrderType,
  getMeals,
  getAddons
} from '../../../Redux/Services/OrderService/helpers'
import { selectData } from '../../../Redux/Services/DataService/DataService'
import { IMeal, IAddon, IAddonObj, IMealObj, IReduxStore } from '../../../Utils/types'
import { addMeal, addAddon } from '../../../Redux/Services/OrderService/OrderService'
import { LOCAL_STORAGE_ITEMS } from '../../../Utils/constants'
import { IProps } from './types'
import { getItem } from '../../../Helpers'

import ErrorHandler from '../../Presentational/ErrorHandler/ErrorHandler'
import AddonList from './Components/AddonList/AddonList'
import MealList from './Components/MealList/MealList'

import styles from './styles/index.module.scss'
import { MainContext } from '../../../Containers/MainContainer/MainContainer'

class ProductPicker extends React.Component<IProps> {
  handleInitialValues = (item: IMeal | IAddon, type: 'meals' | 'addons'): number | undefined => {
    const stored = getItem(LOCAL_STORAGE_ITEMS.ORDER)

    if (stored) {
      const objKey = type.slice(0, -1) as 'addon' | 'meal'
      const data = stored[type].find((data: IMealObj & IAddonObj) => data[objKey].id === item.id)
      return data && data.amount
    }
  }

  render() {
    const { type, addAddon, addMeal, total, packageType, data, orderMeals, orderAddons } = this.props
    const { error, loading, products } = data
    const disabled = total >= packageType!

    return (
      <MainContext.Consumer>
        {({ openProteinModal }) => (
          <div className={styles.container}>
            <MealList
              type={type}
              meals={products.meals}
              addMeal={addMeal}
              loading={loading}
              disabled={disabled}
              error={error.state}
              openModal={openProteinModal}
              orderMeals={orderMeals}
              handleInitialValues={this.handleInitialValues}
            />

            <AddonList
              type={type}
              addons={products.addons}
              loading={loading}
              addAddon={addAddon}
              disabled={disabled}
              error={error.state}
              orderAddons={orderAddons}
              handleInitialValues={this.handleInitialValues}
            />

            <ErrorHandler error={error} />
          </div>
        )}
      </MainContext.Consumer>
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  total: getTotalMeals(state),
  orderType: getOrderType(state),
  packageType: getPackageType(state),
  data: selectData(state),
  orderMeals: getMeals(state),
  orderAddons: getAddons(state)
})

export default connect(
  mapStateToProps,
  { addMeal, addAddon }
)(ProductPicker)
