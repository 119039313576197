import * as React from 'react'

import classNames from 'classnames'
import Carousel from 'nuka-carousel'

import { IState } from './types'
import { items } from './constants'

import Item from './components/Item/Item'

import styles from './Testimonials.module.scss'

class Testimonials extends React.Component<{}, IState> {
  state: Readonly<IState> = {
    activeSlide: 1
  }

  handleChangeSlide = (slide: number) => this.setState({ activeSlide: slide })

  render() {
    const dotClass = (id: number) =>
      classNames(styles.dot, {
        [styles.dotActive]: id === this.state.activeSlide
      })

    return (
      <div className={styles.container} id="testimonials">
        <h2 className={styles.h2}>Why people love us:</h2>
        <Carousel
          slideIndex={1}
          cellSpacing={200}
          cellAlign="center"
          slideWidth="680px"
          withoutControls={true}
          afterSlide={(slide: number) => this.handleChangeSlide(slide)}
        >
          {items.map((item, i) => (
            <Item
              text={item.text}
              active={i === this.state.activeSlide}
              img={item.img}
              author={item.author}
              key={`${item.author}-${i}`}
            />
          ))}
        </Carousel>
        <div className={styles.pagination}>
          {items.map((_, i) => (
            <div className={dotClass(i)} key={`dot-${i}`} />
          ))}
        </div>
      </div>
    )
  }
}

export default Testimonials
