import {
  updateState,
  updateMeals,
  updateProtein,
  updateAddons,
  updateOrderType,
  updateOrder,
  handleResetOrder,
  updateShippingData,
  setShippingData,
  updateSubscriptionType,
  saveInLocalStorage,
  updateDiscount
} from './helpers'
import { IAddon, IOrderType, IPackageInfo, IShipping, IAddonDetails, IMealDetails } from '../../../Utils/types'
import { IMeal, ISubscriptionType, IProtein } from '../../../Utils/types'
import { IAction, IKey } from './types'
import { initState, DEFAULT_STATE } from './constants'
import { IDiscount } from '../../../Utils/constants'

const SET_SUBSCRIPTION_TYPE = 'SET_SUBSCRIPTION_TYPE'
const UPDATE_SHIPPING = 'UPDATE_SHIPPING'
const SET_SHIPPING_DATA = 'SET_SHIPPING_DATA'
const SET_ORDER_TYPE = 'SET_ORDER_TYPE'
const RESET_ORDER = 'RESET_ORDER'
const SET_ORDER = 'SET_ORDER'
const ADD_ADDON = 'ADD_ADDON'
const ADD_MEAL = 'ADD_MEAL'
const ADD_PROTEIN = 'ADD_PROTEIN'
const SET_DISCOUNT = 'SET_DISCOUNT'
const RESTART_STATE = 'RESTART_STATE'

export default (state = initState, action: IAction) => {
  switch (action.type) {
    case ADD_MEAL:
      return updateState(updateMeals, [state, action.amount, action.meal])
    case ADD_ADDON:
      return updateState(updateAddons, [state, action.amount, action.addon])
    case ADD_PROTEIN:
      return updateState(updateProtein, [state, action.mealId, action.protein])
    case SET_ORDER_TYPE:
      return updateState(updateOrderType, [state, action.orderType])
    case SET_ORDER:
      return updateState(updateOrder, [state, action.meals, action.addons])
    case SET_SUBSCRIPTION_TYPE:
      return updateState(updateSubscriptionType, [action.subscriptionType, action.packageInfo])
    case UPDATE_SHIPPING:
      return updateState(updateShippingData, [state, action.key, action.value])
    case SET_SHIPPING_DATA:
      return updateState(setShippingData, [state, action.shippingData])
    case SET_DISCOUNT:
      return updateState(updateDiscount, [state, action.discount])

    case RESET_ORDER:
      return updateState(handleResetOrder, [])
    case RESTART_STATE:
      saveInLocalStorage({ ...DEFAULT_STATE, shipping: state.shipping })
      return { ...DEFAULT_STATE, shipping: state.shipping }
    default:
      return state
  }
}

export const restartOrder = () => ({
  type: RESTART_STATE
})

export const addMeal = (amount: number, meal: IMeal) => {
  return {
    type: ADD_MEAL,
    amount,
    meal
  }
}

export const addAddon = (amount: number, addon: IAddon) => {
  return {
    type: ADD_ADDON,
    amount,
    addon
  }
}

export const setOrderType = (orderType: IOrderType) => {
  return {
    type: SET_ORDER_TYPE,
    orderType
  }
}

export const setShippingDataAction = (shippingData: IShipping) => {
  return {
    type: SET_SHIPPING_DATA,
    shippingData
  }
}

export const setOrder = (meals: IMealDetails[], addons: IAddonDetails[]) => {
  return {
    type: SET_ORDER,
    meals,
    addons
  }
}

export const setSubscriptionType = (subscriptionType: ISubscriptionType, packageInfo: IPackageInfo) => {
  return {
    type: SET_SUBSCRIPTION_TYPE,
    subscriptionType,
    packageInfo
  }
}

export const updateShipping = (key: IKey, value: string) => {
  return {
    type: UPDATE_SHIPPING,
    key,
    value
  }
}

export const addProtein = (mealId: string, protein: IProtein) => ({
  type: ADD_PROTEIN,
  mealId,
  protein
})

export const updateDiscountCode = (discount: IDiscount) => ({
  type: SET_DISCOUNT,
  discount
})

export const resetOrder = () => ({ type: RESET_ORDER })
