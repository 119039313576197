import React from 'react'
import useCollapse from 'react-collapsed'

import styles from './styles.module.scss'

const items = [
  {
    title: 'How does Elevated Chefs meal delivery work?',
    content:
      'Our delivery days are Wednesday nights and Sunday nights. The timer on the top of the website corresponds with the delivery deadline for the upcoming delivery date. All you need to do is choose whether you want to do a one time order versus a subscription, choose your meals, add proteins, select breakfast options, and choose your delivery date.'
  },
  {
    title: 'What are the order deadlines and delivery dates? ',
    content:
      'Our deadlines are typically 48 hours before the delivery date. <br/> We deliver on Wednesday nights and the deadline is 12PM Monday. <br/> We deliver on Sunday nights and the deadline is 12PM Friday.'
  },
  {
    title: 'Why do you have order deadlines?',
    content:
      'We organize a grocery list the day of the order deadline, we meal prep the next day, and we cook everything the day we deliver (Wednesday/Sunday). Your meals will never be frozen, but always fresh! But we need to know what we are cooking at least 48 hours in advance!'
  },
  {
    title: 'How do I decide which order type to choose - One Time Order versus Recurring Order?',
    content:
      'A one time order will only charge you one time for one delivery date. If you want to try out and see if you like the meals, this is the best option to choose. <br/><br/> A recurring order will be enabled when you select a “package” of meals. This will give you a discounted along with free shipping. You will be able to modify the order weekly before the deadline or else it will automatically send the same meals from the previous week.'
  },
  {
    title: 'How do I place a one time order?',
    content:
      "Make sure that a meal package is NOT selected at the top (5, 10 , 15 or 20) and begin your one time order by selecting at least 5 meals (don't forget to add a protein source) from the website. Once you select at least 5, it will allow you to go to the next page to choose breakfast & snacks. Should be smooth sailing from here."
  },
  {
    title: 'How do I set up a recurring order? ',
    content: `To place a recurring weekly order, choose a package of meals, select your meals (don't forget to add a protein source), and then choose your breakfast/snack items. This is good for a "set it and forget it" type client. If you are busy, and don't want to put too much thought into it. This is the way to go.`
  },
  {
    title: 'How do I cancel my recurring order',
    content:
      'To cancel a recurring order please click “Account” and “Cancel all future orders”. This will cancel all future subscriptions from your account!'
  },
  {
    title: 'Site limitations and recommended hack to receive multiple deliveries per week.',
    content:
      'Our website is designed to only allow one subscription per user account. If you want fresh food delivered twice a week (Sunday evening and Wednesday evening) then I would suggest using two separate emails. So that means you will set up two different accounts on our ordering site with two separate subscriptions. Until we upgrade our website to manage two subscriptions from one account, this is the only way to bypass this limitation.. If you are ordering 20-30 meals per week we think that splitting up your order may be the better option if you want your food to only be 2-3 days old by the time you get to it. If you do plan on doing this, please get in touch with us at hello@elevatedliving.com.'
  },
  {
    title: 'Who do I contact if I have any questions?',
    content:
      '<p>You can contact Chef Phil or Sal at <a href="mailto:orders@elevatedchefs.co">orders@elevatedchefs.co</a> or email our support team at <a href="mailto:hello@elevatedliving.com">hello@elevatedliving.com</a> (email monitored 7 days a week 6AM-9PM).</p>'
  }
]

const Collapse: React.FC<{ title: string; content: string }> = ({ title, content }) => {
  const { getCollapseProps, getToggleProps, isOpen } = useCollapse()

  return (
    <>
      <div {...getToggleProps()} type="div" className={styles.header}>
        <p>{title}</p>
        <button className={styles.button}>{isOpen ? '-' : '+'}</button>
      </div>
      <section {...getCollapseProps()} className={styles.content}>
        <div dangerouslySetInnerHTML={{ __html: content }} className={styles.contentText} />
      </section>
    </>
  )
}

const FAQ = () => {
  return (
    <div className={styles.container} id="faq">
      <h2 className={styles.h2}>Frequently asked questions</h2>
      <div className={styles.table}>
        {items.map((item) => (
          <Collapse {...item} />
        ))}
      </div>
    </div>
  )
}

export default FAQ
