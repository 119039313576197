import * as React from 'react'

import { IProps } from './types'
import { TEXTS } from '../../../Utils/constants'

import SelectionList from '../../../Components/Order/SelectionList/SelectionList'
import PriceSummary from '../../../Components/Order/PriceSummary/PriceSummary'

import styles from './SummaryContainerView.module.scss'

const SummaryContainerView: React.FunctionComponent<IProps> = ({ meals, addons, addAddon, addMeal }) => (
  <div className={styles.container}>
    <h2 className={styles.h2}>{TEXTS.SUMMARY.HEADING}</h2>
    <div className={styles.infoContainer}>
      <div className={styles.tablesContainer}>
        <SelectionList addMeal={addMeal} heading={TEXTS.SUMMARY.FIRST_TABLE_HEADING} meals={meals} />
        <SelectionList
          addAddon={addAddon}
          className={styles.list}
          heading={TEXTS.SUMMARY.SECOND_TABLE_HEADING}
          addons={addons}
        />
      </div>
      <PriceSummary />
    </div>
  </div>
)

export default SummaryContainerView
