export type PACKAGE_FIRST = 5
export type PACKAGE_SECOND = 10
export type PACKAGE_THIRD = 15
export type PACKAGE_FOURHT = 20

export const tax = 0.1025
export const shippingPrice = 4.99

export const SUBSCRIPTIONS = {
  SMALL: {
    NAME: 'small',
    PRICE: 10.99,
    MEALS: 5
  },
  MEDIUM: {
    NAME: 'medium',
    PRICE: 12.99,
    MEALS: 10
  },
  MEDIUM_RARE: {
    NAME: 'medium-rare',
    PRICE: 13.99,
    MEALS: 15
  },
  BIG: {
    NAME: 'big',
    PRICE: 15.99,
    MEALS: 20
  }
}

export enum ORDER_TYPE {
  SINGLE,
  SUBSCRIPTION
}

export enum STEPS {
  SELECT_DISHES,
  SELECT_ADDONS,
  SUMMARY,
  SHIPPING,
  PAYMENT,
  SUCCESS
}

export interface IDiscount {
  id: string
  name: string
  type: 'Percent' | 'Cash'
  amount: number
}
