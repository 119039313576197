import * as React from 'react'
import { connect } from 'react-redux'
import { getOrder } from '../../../Redux/Services/OrderService/helpers'
import { addAddon } from '../../../Redux/Services/OrderService/OrderService'
import { IReduxStore } from '../../../Utils/types'
import { IProps } from './types'

import AddonsContainerView from '../../../Views/Order/AddonsContainerView/AddonsContainerView'

class AddonsContainer extends React.Component<IProps> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { order, addAddon } = this.props

    return <AddonsContainerView addAddon={addAddon} order={order} />
  }
}

const mapStateToProps = (state: IReduxStore) => ({ order: getOrder(state) })

export default connect(
  mapStateToProps,
  { addAddon }
)(AddonsContainer)
