import * as React from 'react'
import classnames from 'classnames'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getOrderType, getTotalMeals } from '../../../Redux/Services/OrderService/helpers'
import { selectUserData } from '../../../Redux/Services/UserService/UserService'
import { LOCAL_STORAGE_ITEMS } from '../../../Utils/constants'
import { getItem } from '../../../Helpers/localStorage'
import { IReduxStore } from '../../../Utils/types'
import { ROUTES } from '../../../Utils/routes'
import { IProps, IState } from './types'
import Logo from '../../../assets/img/logo.png'
import { ReactComponent as MenuIcon } from '../../../assets/svg/menu.svg'
import { ReactComponent as Cart } from '../../../assets/svg/cart.svg'
import Button from '../../FormElements/Button/Button'
import styles from './Header.module.scss'

class Header extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    navigatePath: ROUTES.MAIN,
    isMenuOpen: false
  }

  componentDidMount() {
    this.handleRoute()
  }

  componentDidUpdate() {
    this.handleRoute()
  }

  handleToggleMobileMenu = () => {
    this.setState((state: IState) => ({
      isMenuOpen: !state.isMenuOpen
    }))
  }

  handleRoute = () => {
    const { navigatePath } = this.state

    if (this.props.orderType || this.props.total > 0) {
      const item = getItem(LOCAL_STORAGE_ITEMS.LAST_STEP)

      if (item && item.path && item.path !== navigatePath) {
        this.setState({ navigatePath: item.path })
      }
    } else {
      this.state.navigatePath !== ROUTES.MAIN && this.setState({ navigatePath: ROUTES.MAIN })
    }
  }

  render() {
    const { total, user } = this.props
    const { isMenuOpen } = this.state
    const menuClassName = classnames(styles.menu, {
      [styles.menuOpen]: isMenuOpen
    })

    return (
      <div className={styles.header}>
        <NavLink to="/">
          <img className={styles.logo} src={Logo} />
        </NavLink>
        <div className={styles.flex}>
          <button onClick={this.handleToggleMobileMenu} className={styles.menuButton}>
            <MenuIcon />
          </button>

          <div className={menuClassName}>
            <button onClick={this.handleToggleMobileMenu} className={styles.menuClose}>
              <span />
              <span />
            </button>

            <a onClick={this.handleToggleMobileMenu} className={styles.link} href="/#food">
              Food
            </a>
            <a onClick={this.handleToggleMobileMenu} className={styles.link} href="/#about">
              How it works
            </a>
            <a onClick={this.handleToggleMobileMenu} className={styles.link} href="/#testimonials">
              Testimonials
            </a>
            <a onClick={this.handleToggleMobileMenu} className={styles.link} href="/#faq">
              FAQ
            </a>

            {user.name ? (
              <Link onClick={this.handleToggleMobileMenu} className={styles.profileLink} to={ROUTES.ACCOUNT}>
                Welcome back {user.name}
              </Link>
            ) : (
              <>
                <Button
                  className={styles.button}
                  type="transparent"
                  placeholder="log in"
                  onClick={() => {
                    this.handleToggleMobileMenu()
                    this.props.openModal('login')
                  }}
                />
                <div className={styles.divider} />
                <Button
                  className={styles.buttonRegister}
                  type="green"
                  placeholder="register"
                  onClick={() => {
                    this.handleToggleMobileMenu()
                    this.props.openModal('register')
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  total: getTotalMeals(state),
  orderType: getOrderType(state),
  user: selectUserData(state)
})

export default connect(mapStateToProps)(Header)
