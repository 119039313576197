import * as React from 'react'

import { connect } from 'react-redux'

import { getShipping } from '../../../Redux/Services/OrderService/helpers'
import { IReduxStore } from '../../../Utils/types'
import { IProps } from './types'

import styles from './ShippingInfo.module.scss'

const ShippingInfo: React.FunctionComponent<IProps> = ({ shipping }) => (
  <div>
    <h3 className={styles.h3}>Your information</h3>
    <div className={styles.shipping}>
      <div>
        <p className={styles.item}>Full name</p>
        <p className={styles.item}>Phone</p>
        <p className={styles.item}>Email</p>
        <p className={styles.item}>Ship to</p>
      </div>
      <div>
        <p className={styles.p}>{shipping.name}</p>
        <p className={styles.p}>{shipping.phone}</p>
        <p className={styles.p}>{shipping.email}</p>
        <p className={styles.p}>{`${shipping.address}, ${shipping.apartment}`}</p>
        <p className={styles.p}>{`${shipping.city}, ${shipping.zip}`}</p>
      </div>
    </div>
  </div>
)

const mapStateToProps = (state: IReduxStore) => ({
  shipping: getShipping(state)
})

export default connect(mapStateToProps)(ShippingInfo)
