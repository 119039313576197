import * as React from 'react'
import { AxiosResponse, AxiosError } from 'axios'
import { Route } from 'react-router-dom'

import TableHeader from './Components/TableHeader/TableHeader'
import OrderDetails from './Components/OrderDetails/OrderDetails'
import ShouldRender from '../../../Components/Logical/ShouldRender/ShouldRender'

import { ReactComponent as Eye } from '../../../assets/svg/eye.svg'

import { getOrders } from '../../../Utils/api'
import { getUserToken, formatDate, showError } from '../../../Utils/helpers'
import { ROUTES } from '../../../Utils/routes'
import { IState, IItem, IMeal } from './Types'
import styles from './HistoryContainer.module.scss'
import { IAddon } from '../../../Utils/types'

class HistoryContainer extends React.Component<any, IState> {
  state: Readonly<IState> = {
    items: [],
    pageLimit: 12,
    currentPage: 0,
    totalPages: 0,
    selected: undefined
  }

  componentDidMount() {
    const { pageLimit, currentPage } = this.state
    this.handleFetchOrders(currentPage, pageLimit)
  }

  handleChangePage = (page: number) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, pageLimit } = this.state
      this.handleFetchOrders(currentPage, pageLimit)
    })
  }

  handleFetchOrders = (page: number, limit: number) => {
    const token = getUserToken()
    const { pageLimit } = this.state

    if (token) {
      getOrders(page, limit, token)
        .then(({ data }: AxiosResponse) => {
          const items = data.results
          const total = data.status.total

          this.setState({ items, totalPages: Math.ceil(total / pageLimit) })
        })
        .catch((err: AxiosError) => {
          showError(err)
        })
    }
  }

  handleShowProductInfo = (item: IItem) => {
    this.setState({ selected: item }, () => {
      this.props.history.push(`/account/history/${item._id}`)
    })
  }

  handleCalculateTotalMealsPrice = (meals: IMeal[]) =>
    meals.reduce((total: number, meal: IMeal) => (total += meal.price), 0)

  handleCalculateTotalAddonsPrice = (addons: IAddon[]) =>
    addons.reduce((total: number, addon: IAddon) => (total += addon.price), 0)

  render() {
    const { items, totalPages, currentPage } = this.state

    return (
      <div className={styles.container}>
        <ShouldRender
          onlyIf={window.location.pathname === ROUTES.ACCOUNT_HISTORY}
          render={
            <>
              <TableHeader />
              {items.map((item: IItem) => (
                <div className={styles.item} key={item._id}>
                  <div className={styles.cell}>
                    <p className={styles.dataText}>...{item._id.slice(-5)}</p>
                  </div>
                  <div className={styles.cell}>
                    <p className={styles.dataText}>{formatDate(item.createdAt)}</p>
                  </div>
                  <div className={`${styles.cell} ${styles.cellMobile}`}>
                    <p className={styles.dataText}>{item.maxMeals} per week</p>
                  </div>
                  <div className={`${styles.cell} ${styles.cellMobile}`}>
                    <p className={styles.dataText}>${this.handleCalculateTotalMealsPrice(item.meals)}</p>
                  </div>
                  <div className={`${styles.cell} ${styles.cellMobile}`}>
                    <p className={styles.dataText}>${this.handleCalculateTotalAddonsPrice(item.addons)}</p>
                  </div>
                  <div className={styles.cell}>
                    <p className={styles.dataText}>${item.totalPrice}</p>
                  </div>
                  <div className={styles.infoCell}>
                    <Eye className={styles.icon} />
                    <p className={styles.info} onClick={() => this.handleShowProductInfo(item)}>
                      View full info
                    </p>
                  </div>
                </div>
              ))}
              <div className={styles.pagination}>
                {Array(totalPages)
                  .fill(0)
                  .map((item: number, index: number) => index)
                  .map((page: number) => (
                    <p
                      className={currentPage === page ? styles.pageActive : styles.page}
                      key={`page-${page}`}
                      onClick={() => this.handleChangePage(page)}
                    >
                      {page + 1}
                    </p>
                  ))}
              </div>
            </>
          }
        />

        <Route path={ROUTES.ACCOUNT_HISTORY_ITEM} render={() => <OrderDetails item={this.state.selected!} />} />
      </div>
    )
  }
}

export default HistoryContainer
