import * as React from 'react'
import Modal from '../../Logical/Modal/Modal'
import Button from '../../FormElements/Button/Button'
import MetaDataItem from './components/MetaDataItem/MetaDataItem'
import MultiCheckbox from './components/MultiCheckbox/MultiCheckbox'
import Counter from '../../FormElements/Counter/Counter'
import { ReactComponent as Stars } from '../../../assets/svg/stars.svg'
import { IProps, IState } from './types'
import styles from './ProteinModal.module.scss'
import { IProtein } from '../../../Utils/types'

class ProteinModal extends React.Component<IProps, IState> {
  readonly state: IState = {
    selectedProtein: undefined
  }

  componentDidUpdate(prevProps: IProps) {
    const { data } = this.props
    const { data: prevData } = prevProps

    if (data && prevData && prevData.addedProtein !== data.addedProtein) {
      this.setState({ selectedProtein: data.addedProtein })
    }
  }

  handleSelectProtein = (protein: IProtein) => {
    const { selectedProtein } = this.state

    if (selectedProtein && selectedProtein.id === protein.id) {
      this.setState({ selectedProtein: undefined })
    } else {
      this.setState({ selectedProtein: protein })
    }
  }

  handleSubmit = () => {
    const { data, addProtein } = this.props
    const { selectedProtein } = this.state

    if (data && selectedProtein) {
      addProtein(data.id, selectedProtein)
      this.props.modal.current!.handleClose()
    }
  }

  render() {
    const { selectedProtein } = this.state
    const { data, proteins, amount, addMeal } = this.props

    return data ? (
      <Modal className={styles.container} ref={this.props.modal}>
        <div className={styles.leftSide}>
          <header>
            <img className={styles.background} src={data.metadata.image} />
            <div className={styles.metaData}>
              <MetaDataItem title="CALORIES" value={data.metadata.calories} />
              <MetaDataItem title="PROTEIN" value={`${data.metadata.protein}g`} />
              <MetaDataItem title="FAT" value={`${data.metadata.fat}g`} />
              <MetaDataItem title="CARBS" value={`${data.metadata.carbs}g`} />
              <MetaDataItem title="SODIUM" value={`${data.metadata.sodium}mg`} />
            </div>
          </header>
          <div className={styles.leftContect}>
            <p className={styles.name}>{data!.name}</p>
            <Stars className={styles.stars} />
            <h3 className={styles.ingredientTitle}>Ingredients:</h3>
            <div className={styles.ingredients}>{data.metadata.ingredients}</div>
            <div className={styles.bottomRow}>
              <Counter type="meal" initialAmount={amount} onChange={(amount: number) => addMeal(amount, data)} />
              <p className={styles.price}>${data!.price}</p>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <p className={styles.proteinTitle}>Add/Remove Protein</p>
          <div className={styles.options}>
            <p>Protein options:</p>
            <MultiCheckbox values={proteins} selectedValue={selectedProtein} onChange={this.handleSelectProtein} />
          </div>
          <div className={styles.total}>
            <p className={styles.totalHeading}>Total Price</p>
            <p className={styles.totalPrice}>${selectedProtein ? (selectedProtein.price / 100).toFixed(2) : 0}</p>
          </div>
          <div className={styles.buttons}>
            <Button type="text" onClick={() => this.props.modal.current!.handleClose()}>
              Cancel
            </Button>
            <Button type="green" onClick={this.handleSubmit}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    ) : null
  }
}

export default ProteinModal
