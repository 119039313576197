import React from 'react'
import { AxiosError } from 'axios'
import { RouteComponentProps } from 'react-router'
import { showError, showSucces } from '../../Utils/helpers'
import { ROUTES } from '../../Utils/routes'
import { activateUser } from '../../Utils/api'

export default class ActivateContainer extends React.Component<RouteComponentProps<{ email: string; hash: string }>> {
  componentDidMount() {
    const { email, hash } = this.props.match.params

    activateUser(email, hash)
      .then(() => {
        showSucces('User activated successfull')
        this.props.history.push({
          pathname: ROUTES.MAIN,
          state: {
            openModal: true
          }
        })
      })
      .catch((err: AxiosError) => {
        showError(err)
        this.props.history.push(ROUTES.MAIN)
      })
  }

  render() {
    return null
  }
}
