import * as React from 'react'

import classNames from 'classnames'

import { IProps, IState } from './types'

import { ReactComponent as Checkmark } from '../../../assets/svg/checkmark.svg'

import styles from './Checkbox.module.scss'

class Checkbox extends React.Component<IProps> {
  handleClick = () => {
    this.props.onClick(this.props.id)
  }

  render() {
    const { checked } = this.props
    const { label, containerClassName } = this.props

    const checkboxClass = classNames(styles.container, {
      [styles.checked]: checked
    })

    const containerClass = classNames(styles.flex, containerClassName)

    return (
      <div className={containerClass}>
        <div className={checkboxClass} onClick={this.handleClick}>
          <Checkmark className={styles.icon} />
        </div>
        {label && <p className={styles.label}>{label}</p>}
      </div>
    )
  }
}

export default Checkbox
