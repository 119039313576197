import * as React from 'react'
import { connect } from 'react-redux'

import { updateShipping, setShippingDataAction } from '../../../Redux/Services/OrderService/OrderService'
import { getShipping, getPackageType } from '../../../Redux/Services/OrderService/helpers'
import { IKey } from '../../../Redux/Services/OrderService/types'
import { IReduxStore } from '../../../Utils/types'
import { IProps, IState } from './types'

import ShippingContainerView from '../../../Views/Order/ShippingContainerView/ShippingContainerView'
import { getUserToken } from '../../../Utils/helpers'

class ShippingContainer extends React.Component<IProps, IState> {
  state: IState = {
    zip: this.props.shipping.zip ? this.props.shipping.zip : '',
    city: this.props.shipping.city ? this.props.shipping.city : '',
    name: this.props.shipping.name ? this.props.shipping.name : '',
    email: this.props.shipping.email ? this.props.shipping.email : '',
    phone: this.props.shipping.phone ? this.props.shipping.phone : '',
    address: this.props.shipping.address ? this.props.shipping.address : '',
    apartment: this.props.shipping.apartment ? this.props.shipping.apartment : ''
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.shipping.address !== state.address) {
      return {
        ...props.shipping
      }
    }

    return null
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    const token = getUserToken()

    if (!token) {
      this.props.setShippingDataAction(this.state)
    }
  }

  handleChange = (id: string, value: string) => {
    const { updateShipping } = this.props

    this.setState({ [id]: value } as Pick<IState, keyof IState>, () => {
      updateShipping(id as IKey, id === 'phone' ? value.replace(/\s/g, '') : value)
    })
  }

  render() {
    const { zip, address, phone, apartment, name, city, email } = this.state
    const { handleSelectDate, selectedDate, packageType, isShippingAddressShowed } = this.props

    return (
      <ShippingContainerView
        zip={zip}
        name={name}
        city={city}
        phone={phone}
        email={email}
        address={address}
        apartment={apartment}
        selectedDate={selectedDate}
        isAddressShowed={isShippingAddressShowed}
        handleSelectDate={handleSelectDate}
        packageType={packageType}
        onChange={this.handleChange}
      />
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  shipping: getShipping(state),
  packageType: getPackageType(state)
})

export default connect(
  mapStateToProps,
  { updateShipping, setShippingDataAction }
)(ShippingContainer)
