import * as React from 'react'
import Input from '../../../../../Components/FormElements/Input/Input'
import styles from '../../View/BillingContainerView.module.scss'
import { ICardInfo } from '../../../../../Utils/types/global'

export interface IProps {
  cardInfo: ICardInfo
}

const CardInfo: React.FunctionComponent<IProps> = ({ cardInfo }) => {
  return (
    <div className={styles.cardItems}>
      <div className={styles.cardItem}>
        <Input
          disabled
          className={styles.fullWidth}
          id="number"
          value={`**** **** **** ${cardInfo.last4}`}
          label="CARD NUMBER"
          onChange={() => {}}
        />
      </div>
      <div className={styles.flex}>
        <div className={`${styles.cardItem} ${styles.cardExpiration}`}>
          <Input
            disabled
            className={styles.halfWidth}
            id="expirationDate"
            value={`${cardInfo.expMonth}/${cardInfo.expYear}`}
            label="EXPIRATION DATE"
            onChange={() => {}}
          />
        </div>
        <div className={`${styles.cardItem} ${styles.cardCVC}`}>
          <Input disabled className={styles.halfWidth} id="cvc" value="***" label="CVC" onChange={() => {}} />
        </div>
      </div>
    </div>
  )
}

export default CardInfo
