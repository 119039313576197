import * as React from 'react'
import { connect } from 'react-redux'

import { setOrderType, setSubscriptionType, setOrder } from '../../Redux/Services/OrderService/OrderService'
import { getTotalMeals, getPackageType } from '../../Redux/Services/OrderService/helpers'
import { IReduxStore, ISubscriptionType, IPackageInfo } from '../../Utils/types'
import { ORDER_TYPE } from '../../Utils/constants'
import { ROUTES } from '../../Utils/routes'
import { IState, IProps } from './types'

import LandingView from '../../Views/LandingView/LandingView'
import withMainConsumer, { IWithConsumerProps } from '../../HOC/withMainConsumer'
import { getUserToken } from '../../Utils/helpers'
import { selectActiveOrder } from '../../Redux/Services/UserService/UserService'

interface StyleDeclaration extends CSSStyleDeclaration {
  scrollBehavior: string
}

class LandingContainer extends React.Component<IProps & IWithConsumerProps, IState> {
  state: Readonly<IState> = {
    selectedMeals: []
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const style: StyleDeclaration = document.getElementsByTagName('html')[0].style as StyleDeclaration
    style.scrollBehavior = 'smooth'
  }

  componentWillUnmount() {
    const style: StyleDeclaration = document.getElementsByTagName('html')[0].style as StyleDeclaration
    style.scrollBehavior = ''
  }

  handleSingleOrder = () => {
    this.props.setOrderType(ORDER_TYPE.SINGLE)
    this.props.history.push(ROUTES.ADDONS)
  }

  handleSubscription = (packageType: ISubscriptionType, packageInfo: IPackageInfo) => {
    const token = getUserToken()
    const { activeOrder, setOrderType, setSubscriptionType, updateUser, setOrder, history } = this.props

    if (this.props.packageType !== packageType) {
      setOrderType(ORDER_TYPE.SUBSCRIPTION)
      setSubscriptionType(packageType, packageInfo)

      if (token) {
        updateUser({ maxMeals: packageType }, token)
      }
    }

    if (activeOrder && activeOrder.meals && token) {
      setOrder(activeOrder!.meals, activeOrder!.addons)
      history.push({
        pathname: ROUTES.ORDER,
        state: {
          changeActiveMeals: true
        }
      })
    } else {
      history.push(ROUTES.DISHES)
    }
  }

  render() {
    const isProceedDisabled = this.props.total < 5

    return (
      <LandingView
        isProceedDisabled={isProceedDisabled}
        handleSingleOrder={this.handleSingleOrder}
        setSubscriptionType={this.handleSubscription}
      />
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  total: getTotalMeals(state),
  packageType: getPackageType(state),
  activeOrder: selectActiveOrder(state)
})

export default connect(
  mapStateToProps,
  { setOrderType, setSubscriptionType, setOrder }
)(withMainConsumer(LandingContainer))
