import * as React from 'react'
import moment, { Moment } from 'moment'
import { ToastContainer } from 'react-toastify'
import { Switch, Route, Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { AxiosResponse, AxiosError } from 'axios'

import { ROUTES } from '../../Utils/routes'
import { userLogin, userRegister, resendActivationLink, updateUser as updateUserData } from '../../Utils/api'
import { IUserLogin, IUserRegisterData, IUserData } from '../../Utils/types/requests'
import {
  userLogin as login,
  updateUser,
  selectUserData,
  selectIsSubscription
} from '../../Redux/Services/UserService/UserService'
import { getUserToken, RecursivePartial, showError, showSucces } from '../../Utils/helpers'
import { getPackageType } from '../../Redux/Services/OrderService/helpers'
import { setShippingDataAction, restartOrder, addMeal } from '../../Redux/Services/OrderService/OrderService'
import { fetchData, selectProteins } from '../../Redux/Services/DataService/DataService'
import { IProps, IState } from './types'
import { IReduxStore, IMeal, IProtein, IShipping } from '../../Utils/types'

import Report from '../Report/Report'
import AccountContainer from '../Account/AccountContainer/AccountContainer'
import LoginModal from '../../Components/Modals/LoginModal/LoginModal'
import RegisterModal from '../../Components/Modals/RegisterModal/RegisterModal'
import ProteinModal from '../../Components/Modals/ProteinModal/ProteinModal'
import ResendActivationModal from '../../Components/Modals/ResendActivationModal/ResendActivationModal'
import SummaryContainer from '../Order/OrderContainer/OrderContainer'
import LandingContainer from '../LandingContainer/LandingContainer'
import ActivateContainer from '../ActivateContainer/ActivateContainer'
import Footer from '../../Components/Presentational/Footer/Footer'
import Header from '../../Components/Logical/Header/Header'
import Modal from '../../Components/Logical/Modal/Modal'
import Timer from '../../Components/Logical/Timer/Timer'

import 'react-toastify/dist/ReactToastify.css'
import '../../Utils/styles/global/index.scss'
import { addProtein } from '../../Redux/Services/OrderService/OrderService'

export interface IContext {
  isLoading: boolean
  error: {
    state: boolean
    msg: string
  }
  deliveryDates: Moment[]
  updateUser: (data: RecursivePartial<IUserData['user']>, token: string) => void
  openModal: (type: 'login' | 'register' | 'resendActivation', isShippingLogin?: boolean) => void
  openProteinModal: (modalData: IMeal, amount: number) => void
}

export const MainContext = React.createContext<IContext>({
  isLoading: false,
  error: {
    state: false,
    msg: ''
  },
  deliveryDates: [],
  updateUser: (data: RecursivePartial<IUserData['user']>, token: string) => null,
  openProteinModal: (data: IMeal, amount) => null,
  openModal: (type: 'login' | 'register' | 'resendActivation', isShippingLogin?: boolean) => null
})

class MainContainer extends React.Component<RouteComponentProps & IProps, IState> {
  private loginModal = React.createRef<Modal>()
  private registerModal = React.createRef<Modal>()
  private resendActivationModal = React.createRef<Modal>()
  private proteinModal = React.createRef<Modal>()

  state: Readonly<IState> = {
    location: {
      hash: '',
      search: '',
      pathname: ''
    },
    error: {
      state: false,
      msg: ''
    },
    deliveryDates: [],
    proteinModalData: {
      amount: 0,
      data: null
    },
    isShippingLogin: false,
    isLogged: false,
    loading: false
  }

  componentDidMount() {
    this.handleCalculateDeliveryTime()
    this.props.fetchData()
  }

  componentDidUpdate(prevProps: RouteComponentProps & IProps) {
    const { location, history } = this.props
    const token = getUserToken()
    this.handleLocation()

    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === ROUTES.MAIN) {
      this.props.restartOrder()
    }

    if (location.state && location.state.openModal) {
      this.loginModal.current!.handleOpen()
      history.replace({ state: {} })
    }

    if (token && this.props.userData.shipping && this.props.userData.shipping !== prevProps.userData.shipping) {
      this.props.setShippingDataAction(this.handleMapLoginDataToShipping(this.props.userData))
    }
  }

  componentWillUnmount() {
    this.props.restartOrder()
  }

  handleLocation = () => {
    if (this.props.location.pathname !== this.state.location.pathname) {
      this.setState({ location: this.props.location })
    }
  }

  handleOpenModal = (type: 'login' | 'register' | 'resendActivation', isShippingLogin?: boolean) => {
    if (isShippingLogin) {
      this.setState({ isShippingLogin })
    }

    if (type === 'login') {
      this.loginModal.current!.handleOpen()
    } else if (type === 'resendActivation') {
      this.resendActivationModal.current!.handleOpen()
    } else {
      this.registerModal.current!.handleOpen()
    }
  }

  handleOpenProteinModal = (data: IMeal & { addedProtein?: IProtein }, amount: number) => {
    this.setState({ proteinModalData: { amount, data } }, () => {
      this.proteinModal.current!.handleOpen()
    })
  }

  handleCloseModal = () => {
    this.loginModal.current!.handleClose()
    this.registerModal.current!.handleClose()
    this.resendActivationModal.current!.handleClose()
  }

  handleError = (err: AxiosError) => {
    this.setState(
      { loading: false, error: { state: true, msg: err.response ? err.response.data.message : 'Error' } },
      () => {
        setTimeout(() => {
          this.setState({ error: { state: false, msg: '' } })
        }, 5000)
      }
    )
  }

  handleUserLogin = (data: IUserLogin) => {
    this.setState({ loading: true })
    userLogin(data)
      .then(({ data }: AxiosResponse<IUserData>) => {
        this.handleCloseModal()
        localStorage.setItem('token', data.token)
        this.setState({ loading: false, isLogged: true })

        if (!this.state.isShippingLogin) {
          this.props.history.push(ROUTES.ACCOUNT_SUBSCRIBE)
        } else {
          this.handleUpdateUser({ maxMeals: this.props.packageType })
          this.props.history.replace({ state: { isBuyWithConfirm: true } })
        }

        this.props.login(data.user)
        this.props.setShippingDataAction(this.handleMapLoginDataToShipping(data.user))
      })
      .catch((err: AxiosError) => {
        showError(err)
        this.handleError(err)
      })
  }

  handleMapLoginDataToShipping = (userData: IUserData['user']): IShipping => ({
    zip: userData.shipping ? userData.shipping.zipCode : '',
    name: userData.name ? userData.name : '',
    city: userData.shipping ? userData.shipping.city : '',
    phone: userData.phone ? userData.phone : '',
    email: userData.user.email,
    address: userData.shipping ? userData.shipping.address : '',
    apartment: userData.shipping ? `${userData.shipping.apartment}` : ''
  })

  handleUserRegister = (data: IUserRegisterData) => {
    const userData = {
      name: data.name,
      user: {
        email: data.email,
        password: data.password
      }
    }
    this.setState({ loading: true })
    return userRegister(userData)
      .then((res) => {
        this.setState({ loading: false })
        return Promise.resolve()
      })
      .catch((err: AxiosError) => {
        showError(err)
        this.handleError(err)
        return Promise.reject()
      })
  }

  handleResendActivationLink = (email: string) => {
    this.setState({ loading: true })
    resendActivationLink(email)
      .then(() => {
        showSucces('An activation link has been sent!')
        this.setState({ loading: false })
      })
      .catch((err: AxiosError) => {
        showError(err)
        this.setState({ loading: false })
      })
  }

  handleSwitchModal = (type: 'login' | 'register' | 'resendActivation') => {
    if (type === 'login') {
      this.registerModal.current!.handleClose()
      this.resendActivationModal.current!.handleClose()
      this.loginModal.current!.handleOpen()
    } else if (type === 'resendActivation') {
      this.loginModal.current!.handleClose()
      this.registerModal.current!.handleClose()
      this.resendActivationModal.current!.handleOpen()
    } else {
      this.loginModal.current!.handleClose()
      this.resendActivationModal.current!.handleClose()
      this.registerModal.current!.handleOpen()
    }
  }

  handleUpdateUser = (data: RecursivePartial<IUserData['user']>) => {
    const token = getUserToken()

    if (token) {
      updateUserData(data, token)
        .then(({ data }: AxiosResponse) => {
          this.props.updateUser(data)
        })
        .catch((err: AxiosError) => {
          showError(err)
        })
    }
  }

  handleCalculateDeliveryTime = () => {
    let nextSunday = moment()
      .isoWeekday(7)
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })

    let nextWednesday = moment()
      .isoWeekday(3)
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })

    if (
      moment()
        .add(48, 'hours')
        .isAfter(nextSunday)
    ) {
      nextSunday.add(7, 'days')
    }

    if (
      moment()
        .add(48, 'hours')
        .isAfter(nextWednesday)
    ) {
      nextWednesday.add(7, 'days')
    }

    const deliveryDates = nextWednesday.isAfter(nextSunday) ? [nextSunday, nextWednesday] : [nextWednesday, nextSunday]
    this.setState({ deliveryDates })
  }

  render() {
    const { loading, error, proteinModalData, deliveryDates } = this.state
    const { proteins, addProtein, userData, addMeal, isSubscription } = this.props
    const deliveryDatesinMs =
      deliveryDates.length > 0
        ? [+deliveryDates[0].format('x'), +deliveryDates[1].format('x')]
        : [+moment().format('x'), +moment().format('x')]
    const token = getUserToken()

    return (
      <MainContext.Provider
        value={{
          isLoading: loading,
          error: error,
          openModal: this.handleOpenModal,
          updateUser: this.handleUpdateUser,
          openProteinModal: this.handleOpenProteinModal,
          deliveryDates
        }}
      >
        <div style={{ overflow: 'hidden' }}>
          <Header openModal={this.handleOpenModal} location={this.state.location} />
          <Timer deliveryDates={deliveryDatesinMs} />

          <Switch>
            <Route exact path={ROUTES.MAIN} component={LandingContainer} />
            <Route exact path={ROUTES.REPORT} component={Report} />
            <Route
              path={ROUTES.ACCOUNT}
              render={(props) =>
                token ? (
                  <AccountContainer
                    {...props}
                    isSubscription={isSubscription}
                    deliveryDates={deliveryDates}
                    userDeliveryDate={userData.deliveryDate}
                    userData={{ name: userData.name, email: userData.user.email }}
                  />
                ) : (
                  <Redirect to={ROUTES.MAIN} />
                )
              }
            />
            <Route path={ROUTES.USER_ACTIVATE} component={ActivateContainer} />
            <Route path={ROUTES.ORDER} component={SummaryContainer} />
            <Redirect to={ROUTES.MAIN} />
          </Switch>
          <Footer />

          <LoginModal
            modal={this.loginModal}
            onClose={this.handleCloseModal}
            switchModal={this.handleSwitchModal}
            onSubmit={this.handleUserLogin}
          />
          <RegisterModal
            modal={this.registerModal}
            onClose={this.handleCloseModal}
            switchModal={this.handleSwitchModal}
            onSubmit={this.handleUserRegister}
          />
          <ResendActivationModal
            modal={this.resendActivationModal}
            onClose={this.handleCloseModal}
            switchModal={this.handleSwitchModal}
            onSubmit={this.handleResendActivationLink}
          />

          <ProteinModal
            data={proteinModalData.data}
            amount={proteinModalData.amount}
            addMeal={addMeal}
            proteins={proteins}
            modal={this.proteinModal}
            addProtein={addProtein}
            switchModal={this.handleSwitchModal}
          />
        </div>
        <ToastContainer className="toast-container" autoClose={5000} />
      </MainContext.Provider>
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  packageType: getPackageType(state),
  proteins: selectProteins(state),
  userData: selectUserData(state),
  isSubscription: selectIsSubscription(state)
})

const Container = connect(
  mapStateToProps,
  { login, fetchData, updateUser, addProtein, addMeal, setShippingDataAction, restartOrder }
)(MainContainer)
export default withRouter(Container)
