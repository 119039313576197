import { TEXTS, STEPS } from '../../../Utils/constants'
import { ROUTES } from '../../../Utils/routes'

export const DEF_STEP_DATA = {
  activeStep: STEPS.SELECT_DISHES,
  prevStep: ROUTES.MAIN,
  nextStep: {
    path: ROUTES.ADDONS,
    index: STEPS.SELECT_ADDONS,
    name: TEXTS.BOTTOM_BAR_BTN_NAMES.MEALS
  }
}
