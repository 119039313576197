import React from 'react'
import { IProps } from './types'
import styles from './MultiCheckbox.module.scss'
import { IProtein } from '../../../../../Utils/types'

const MultiCheckbox: React.FunctionComponent<IProps> = ({ selectedValue, values, onChange }) => {
  return (
    <div className={styles.container}>
      {values.map((value: IProtein) => (
        <div key={value.id} className={styles.item}>
          <div className={styles.leftSide}>
            <input
              id={value.id}
              type="checkbox"
              className={styles.input}
              checked={selectedValue && value.id === selectedValue.id}
              onChange={() => onChange(value)}
            />
            <div className={styles.icon} />

            <label className={styles.label} htmlFor={value.id}>
              {value.name}
            </label>
          </div>
          <div className={styles.price}>${(value.price / 100).toFixed(2)}</div>
        </div>
      ))}
    </div>
  )
}

export default MultiCheckbox
