import * as React from 'react'
import { ReactComponent as Instagram } from '../../../assets/svg/instagram.svg'
import { ReactComponent as Facebook } from '../../../assets/svg/facebook.svg'
import Logo from '../../../assets/img/logo.png'
import styles from './Footer.module.scss'

const Footer: React.FunctionComponent = () => (
  <div className={styles.container}>
    <div className={styles.leftSide}>
      <img src={Logo} className={styles.logo} />
      <p className={styles.text}>Your personal chefs.</p>
      <p className={styles.text}>So you can continue grinding.</p>
    </div>
    <div className={styles.flex}>
      <div className={styles.linkBox}>
        <a href="https://www.facebook.com/elevatedchefs" target="_blank" className={styles.link}>
          <Facebook className={styles.icon} />
        </a>
        <a href="https://www.instagram.com/elevatedchefschi/" target="_blank" className={styles.link}>
          <Instagram className={styles.icon} />
        </a>
      </div>
      <p className={styles.copy}>2019 © «elevated chefs»</p>
    </div>
  </div>
)

export default Footer
