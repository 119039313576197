import { Dispatch } from 'redux'
import { getProductList } from '../../../Utils/api'
import { IGetProducts } from '../../../Utils/types/requests'
import { initialState } from './constants'
import { IAction, IData } from './types'
import { AxiosResponse, AxiosError } from 'axios'
import { IReduxStore } from '../../../Utils/types'
import { ERRORS } from '../../../Utils/constants/errors'
import { showError } from '../../../Utils/helpers'

const FETCH_DATA = 'FETCH_DATA'
const FETCH_START = 'FETCH_START'
const FETCH_FAIL = 'FETCH_FAIL'

export default (state = initialState as IData, action: IAction): IData => {
  switch (action.type) {
    case FETCH_START:
      return { ...state, loading: true }
    case FETCH_FAIL:
      return { ...state, loading: false, error: { state: true, msg: action.error! } }
    case FETCH_DATA:
      return { ...state, loading: false, products: { ...action.data! } }
    default:
      return state
  }
}

const fetchStart = () => ({
  type: FETCH_START
})

const fetchFail = (msg: string) => ({
  type: FETCH_FAIL,
  error: msg
})

export const fetchData = () => (dispach: Dispatch<IAction>) => {
  dispach(fetchStart())

  getProductList()
    .then((res: AxiosResponse<IGetProducts>) => {
      const meals = res.data.meals.map((meal) => {
        meal.price = Number((meal.price / 100).toFixed(2))
        return meal
      })

      const addons = res.data.addons.map((addon) => {
        addon.price = Number((addon.price / 100).toFixed(2))
        return addon
      })

      dispach({
        type: FETCH_DATA,
        data: {
          meals,
          addons,
          proteins: res.data.proteins
        }
      })
    })
    .catch((err: AxiosError) => {
      showError(err)
      dispach(fetchFail(ERRORS.GET_PRODUCTS))
    })
}

export const selectData = (state: IReduxStore) => state.data
export const selectMeals = (state: IReduxStore) => state.data.products.meals
export const selectAddons = (state: IReduxStore) => state.data.products.addons
export const selectProteins = (state: IReduxStore) => state.data.products.proteins
