import * as React from 'react'

import { IProps } from './types'
import styles from './Table.module.scss'
import { IMealDetails, IAddonDetails, IProtein } from '../../../../../Utils/types'

const Table: React.FunctionComponent<IProps> = ({ data, type }) => {
  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <p className={styles.headerText}>{type === 'meals' ? 'Meal Name' : 'Addon Name'}</p>
        <p className={styles.headerText}>Quantity</p>
        <p className={styles.headerText}>{type === 'meals' ? 'Protein' : ''}</p>
        <p className={styles.totalPrice}>Total Price</p>
      </div>
      {type === 'meals'
        ? (data as IMealDetails[]).map((item: IMealDetails) => (
            <div className={styles.item} key={item.planId}>
              <div className={styles.itemTitle}>
                <p className={styles.mobileHeader}>Meal name</p>
                <div className={styles.titleContent}>
                  <img className={styles.image} src={item.image} />
                  <p className={styles.itemText}>{item.name}</p>
                </div>
              </div>
              <div className={styles.itemQuantity}>
                <p className={styles.mobileHeader}>Quantity</p>
                <p>x{item.quantity}</p>
              </div>
              <div className={styles.itemProtein}>
                <p className={styles.mobileHeader}>Protein</p>
                <p>
                  {item.proteins
                    ? item.proteins.map(
                        (protein: IProtein, index: number, array: IProtein[]) =>
                          `${protein.name}${index !== array.length - 1 ? ',' : ''}`
                      )
                    : null}
                </p>
              </div>
              <div className={styles.itemPrice}>
                <p className={styles.mobileHeader}>Total price</p>
                <p>${item.price}</p>
              </div>
            </div>
          ))
        : null}

      {type === 'addons'
        ? (data as IAddonDetails[]).map((item: IAddonDetails) => (
            <div className={styles.item} key={item._id}>
              <div className={styles.itemTitle}>
                <p className={styles.mobileHeader}>Addon name</p>
                <div className={styles.titleContent}>
                  <img className={styles.image} src={item.image} />
                  <p className={styles.itemText}>{item.name}</p>
                </div>
              </div>
              <div className={styles.itemQuantity}>
                <p className={styles.mobileHeader}>Quantity</p>
                <p>x{item.quantity}</p>
              </div>
              <div className={styles.itemProtein}>
                <p className={styles.mobileHeader}>Protein</p>
              </div>
              <div className={styles.itemPrice}>
                <p className={styles.mobileHeader}>Total price</p>
                <p>${item.price}</p>
              </div>
            </div>
          ))
        : null}
    </div>
  )
}

export default Table
