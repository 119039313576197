export const TEXTS = {
  LANDING: {
    HERO_HEADING: 'Less time, More Life!',
    PLANS_HEADING: 'Choose your plan',
    PLANS_DESCRIPTION: 'Save money on shipping and bulk orders. These are our most popular plans!',
    MEALS_DESCRIPTION: 'All of our base meals are vegan, gluten free, and made from the freshest ingredients.',
    HERO_DESC: 'Enjoy healthy, fresh, and tasty meals created by your personal chef.',
    MEALS_HEADING: 'Try a one-time order!'
  },
  ORDER_STEPS: {
    FIRST: 'Select meals',
    SECOND: "Select add'ons",
    THIRD: 'Summary',
    FOURTH: 'Payment'
  },
  BOTTOM_BAR_BTN_NAMES: {
    MEALS: "Go to add'ons",
    ADDONS: 'Go to summary',
    SUMMARY: 'Go to payment',
    CHANGE_SHIPPING: 'Go to shipping',
    SHIPPING: 'make payment now!',
    UPDATE_ORDER: 'Update my order',
    ADD_CARD: 'Go to add your credit card',
    ORDER: 'Order',
    PAYMENT: 'make payment now!',
    CONFIRM: 'Confirm',
    NEXT: 'Next'
  },
  ADDONS: {
    TABLE_HEADING: 'Your meals',
    HEADING: `Don't forget to choose your snacks!`
  },
  SUMMARY: {
    HEADING: 'Order summary',
    FIRST_TABLE_HEADING: 'Meals',
    SECOND_TABLE_HEADING: "Add-on's"
  },
  SHIPPING: {
    HEADING: 'Please login or provide delivery data to finalize your order!',
    HEADING2: 'Please login or register to finalize your order'
  },
  PRICE_TABLE: {
    HEADING: 'Shopping Cart'
  },
  LOGIN_POPUP: {
    SLOGAN: '- <<An apple in a day keeps doctor away>>'
  }
}
