import * as React from 'react'

import MealItem from '../MealItem/MealItem'

import { IMeal, IAddon, IMealObj, IProtein } from '../../../../../Utils/types'

interface IProps {
  meals: IMeal[]
  loading: boolean
  disabled: boolean
  error: boolean
  type: 'meal' | 'addon'
  orderMeals: IMealObj[]
  openModal: (modalData: IMeal & { addedProtein?: IProtein }, amount: number) => void
  addMeal: (amount: number, meal: IMeal) => void
  handleInitialValues: (item: IMeal | IAddon, type: 'meals' | 'addons') => number | undefined
}

const MealList: React.FunctionComponent<IProps> = ({
  type,
  error,
  meals,
  addMeal,
  loading,
  disabled,
  orderMeals,
  openModal,
  handleInitialValues
}) =>
  error || loading || type !== 'meal' ? null : (
    <>
      {meals.map((meal: IMeal) => {
        const amount = handleInitialValues(meal, 'meals')

        return (
          <MealItem
            key={meal.id}
            type={type}
            meal={meal}
            amount={amount}
            addMeal={addMeal}
            orderMeals={orderMeals}
            disabled={disabled}
            openModal={openModal}
          />
        )
      })}
    </>
  )

export default MealList
