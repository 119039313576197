import * as React from 'react'

import classNames from 'classnames'

import { IProps, IState } from './types'

import Loader from '../../../../Presentational/Loader/Loader'
import Counter from '../../../../FormElements/Counter/Counter'

import styles from '../../styles/index.module.scss'
import { IMeal, IMealObj } from '../../../../../Utils/types'

class MealItem extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    loaded: false
  }

  handleLoad = () => {
    this.setState({ loaded: true })
  }

  render() {
    const { type, disabled, amount, meal, addMeal, openModal, orderMeals } = this.props
    const { loaded } = this.state

    const imageClass = classNames(styles.mealImage, {
      [styles.invisible]: !loaded
    })

    const mealInOrder = orderMeals.find((item: IMealObj) => item.meal.id === meal.id)

    const containerClass = (amount: number) =>
      classNames(styles.mealContainer, {
        [styles.mealContainerActive]: amount > 0
      })

    if (mealInOrder && mealInOrder.protein) {
      meal.addedProtein = mealInOrder.protein
    }

    return (
      <div className={amount ? containerClass(amount) : styles.mealContainer}>
        <img className={imageClass} src={meal.metadata.image} onLoad={this.handleLoad} />
        {!loaded && (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        )}

        <div className={styles.mealContent}>
          <h3 className={styles.mealName}>{meal.name}</h3>
          <div className={styles.counterContainer}>
            <p className={styles.mealPrice}>${meal.price}</p>
            <Counter
              type={type}
              disabled={disabled}
              initialAmount={amount}
              onChange={(amount: number) => addMeal(amount, meal)}
            />
            <p className={styles.mealCalories}>{meal.metadata.calories} cal</p>
          </div>
          <p onClick={() => (mealInOrder ? openModal(meal, amount!) : false)} className={styles.editButton}>
            Add protein
          </p>
        </div>
      </div>
    )
  }
}

export default MealItem
