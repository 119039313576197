import * as React from 'react'

import classNames from 'classnames'

import IProps from './types'

import styles from './Loader.module.scss'

const LoaderComponent = ({ className }: IProps) => {
  const loaderClass = classNames(styles.loader, className)
  return <div className={loaderClass} />
}

export default LoaderComponent
