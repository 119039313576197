import * as React from 'react'
import { AxiosError } from 'axios'
import { connect } from 'react-redux'

import { ReactStripeElements } from 'react-stripe-elements'
import Modal from '../../../Components/Logical/Modal/Modal'

import {
  getTotalMeals,
  getOrderType,
  getPackageType,
  getShipping,
  getAddons,
  getMeals,
  getDiscountCode
} from '../../../Redux/Services/OrderService/helpers'
import {
  selectUserData,
  updateUser as updateReduxUser,
  selectIsSubscription
} from '../../../Redux/Services/UserService/UserService'
import { LOCAL_STORAGE_ITEMS, ORDER_TYPE, STEPS, validateZipWithAnvalibleCodes } from '../../../Utils/constants'
import { buy, buyWithoutLogin, addCard, updateUser, agileCrm } from '../../../Utils/api'
import { getUserToken, showError, showTextError } from '../../../Utils/helpers'
import { IMealObj, IAddonObj } from '../../../Utils/types/order'
import { IBuyData, IUserData } from '../../../Utils/types/requests'
import { saveItem } from '../../../Helpers/localStorage'
import { getSteps } from '../../../Helpers/getSteps'
import { IReduxStore } from '../../../Utils/types'
import { RouteComponentProps } from 'react-router-dom'
import { ROUTES } from '../../../Utils/routes'
import { DEF_STEP_DATA } from './constants'
import { IState, IProps } from './types'

import OrderContainerView from '../../../Views/Order/OrderContainerView/OrderContainerView'
import { resetOrder } from '../../../Redux/Services/OrderService/OrderService'

class OrderContainer extends React.Component<RouteComponentProps & IProps, IState> {
  private createToken: ReactStripeElements.StripeProps['createToken'] | undefined
  private confirmModal = React.createRef<Modal>()
  private changeModalRef = React.createRef<Modal>()

  readonly state: IState = {
    code: '',
    step: DEF_STEP_DATA,
    isEditActiveOrder: false,
    isBuyWithConfirm: false,
    isShippingAddressShowed: getUserToken() ? true : false,
    isLoading: false,
    selectedDate: 0,
    isRefsSet: false,
    cardValidInfo: {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false
    }
  }

  componentDidMount() {
    const isUserAddCreditCard = this.props.user.creditCard
    const { isEditActiveOrder, isShippingAddressShowed } = this.state
    const { location, isSubscription, orderType } = this.props
    const step = getSteps(
      this.props.orderType!,
      !!isUserAddCreditCard,
      isEditActiveOrder,
      !isSubscription && orderType === ORDER_TYPE.SUBSCRIPTION,
      !isShippingAddressShowed
    )

    if (location.state && location.state.changeActiveMeals && isSubscription) {
      this.setState(
        (state: IState) => ({
          step: { ...state.step, prevStep: ROUTES.ACCOUNT_ACTIVE_ORDER },
          isEditActiveOrder: true
        }),
        () => {
          this.props.history.push(ROUTES.DISHES)
        }
      )
    } else if (location.state && location.state.changeActiveMeals) {
      this.props.history.push(ROUTES.DISHES)
    } else {
      this.setState({ step })
    }

    if (this.props.orderType === undefined) {
      this.props.history.push(ROUTES.MAIN)
    } else if (this.props.orderType === ORDER_TYPE.SINGLE && this.props.totalMeals < 5) {
      this.props.history.push(ROUTES.MAIN)
    }
  }

  componentWillMount() {
    this.props.history.replace({ state: {} })
  }

  componentDidUpdate(prevProps: RouteComponentProps & IProps, prevState: IState) {
    if (this.props.location.state && !prevProps.location.state && this.props.location.state.isBuyWithConfirm) {
      this.setState({ isBuyWithConfirm: true, isShippingAddressShowed: true }, () => {
        saveItem(LOCAL_STORAGE_ITEMS.LAST_STEP, { path: this.props.location.pathname })
        this.handleCheckPath()
      })
    } else if (
      this.props.location.pathname !== prevProps.location.pathname ||
      prevState.isShippingAddressShowed !== this.state.isShippingAddressShowed
    ) {
      saveItem(LOCAL_STORAGE_ITEMS.LAST_STEP, { path: this.props.location.pathname })
      this.handleCheckPath()
    }
  }

  handleFormValid = (event: ReactStripeElements.ElementChangeResponse) => {
    this.setState((state: IState) => ({
      cardValidInfo: {
        ...state.cardValidInfo,
        [event.elementType]: event.complete
      }
    }))
  }

  handleSelectDate = (date: number) => {
    this.setState((state) => ({ selectedDate: state.selectedDate === date ? 0 : date }))
  }

  handleSetCreateTokenFunction = (createToken: ReactStripeElements.StripeProps['createToken']) => {
    this.createToken = createToken
  }

  handleCheckPath = () => {
    let { isEditActiveOrder, isBuyWithConfirm, isShippingAddressShowed } = this.state
    const isUserAddCreditCard = this.props.user.creditCard

    const stepInfo = getSteps(
      this.props.orderType!,
      !!isUserAddCreditCard,
      isEditActiveOrder,
      isBuyWithConfirm,
      !isShippingAddressShowed
    )
    this.setState({ step: stepInfo })
  }

  handleNextStep = async () => {
    const { user, isSubscription, orderType, shipping } = this.props
    let { isBuyWithConfirm, step, isShippingAddressShowed } = this.state
    const userToken = getUserToken()

    if (step.activeStep !== STEPS.SHIPPING && step.activeStep !== STEPS.PAYMENT) {
      this.props.history.push(step.nextStep.path)
    } else if (step.activeStep === STEPS.SHIPPING) {
      const isZipCodeValid = validateZipWithAnvalibleCodes(shipping.zip)

      if (!isZipCodeValid && isShippingAddressShowed) {
        showTextError(
          `We are sorry, we are currently not delivering meals in your zip code. Please email orders@elevatedchefs.co to submit new zip code suggestions or for any questions.`
        )
        return
      }

      if (!userToken && !isShippingAddressShowed) {
        this.setState({ isLoading: true })
        agileCrm({ email: shipping.email, phone: shipping.phone, name: shipping.name })
          .then(() => {
            this.setState({ isShippingAddressShowed: true, isLoading: false })
          })
          .catch(() => this.setState({ isLoading: false }))
      } else if (!user.creditCard) {
        this.props.history.push(step.nextStep.path)
      } else {
        if (
          isBuyWithConfirm ||
          (!isSubscription && orderType === ORDER_TYPE.SUBSCRIPTION) ||
          orderType === ORDER_TYPE.SINGLE
        ) {
          this.confirmModal.current!.handleOpen()
        } else {
          this.changeModalRef.current!.handleOpen()
        }
      }
    } else if (step.activeStep === STEPS.PAYMENT) {
      if (this.createToken && userToken) {
        const { token } = await this.createToken()

        if (token) {
          this.setState({ isLoading: true })
          addCard(token.id, userToken).then(({ data }) => {
            this.handleBuy(data)
          })
        }
      } else if (this.createToken && !userToken) {
        this.setState({ isLoading: true })
        this.handleBuy()
      }
    }
  }

  handleConfirmBuy = async () => {
    const userToken = getUserToken()
    this.confirmModal.current!.handleClose()
    this.changeModalRef.current!.handleClose()

    if (this.state.step.activeStep === STEPS.PAYMENT) {
      if (this.createToken && userToken) {
        const { token } = await this.createToken()

        if (token) {
          this.setState({ isLoading: true })
          addCard(token.id, userToken).then(() => {
            this.handleBuy()
          })
        }
      }
    } else {
      this.setState({ isLoading: true })
      this.handleBuy()
    }
  }

  handleBuy = async (creditCard?: IUserData['user']['creditCard']) => {
    const token = getUserToken()
    const { selectedDate } = this.state
    const { shipping, user, discount } = this.props
    const oneTime = this.props.orderType === ORDER_TYPE.SINGLE
    const shippingData = {
      apartment: shipping.apartment,
      address: shipping.address,
      city: shipping.city,
      zipCode: shipping.zip
    }

    const meals = this.props.meals.map((meal: IMealObj) => {
      if (meal.protein && meal.protein.id) {
        return { quantity: meal.amount, plan: meal.meal.id, proteins: [{ plan: meal.protein.id }] }
      }

      return { quantity: meal.amount, plan: meal.meal.id }
    })

    const addons = this.props.addons.map((addon: IAddonObj) => ({ quantity: addon.amount, plan: addon.addon.id }))
    const buyData: IBuyData = {
      meals,
      deliveryDate: selectedDate,
      oneTime,
      shipping: shippingData,
      ...(discount.id && { coupon: discount.id })
    }

    if (addons.length) {
      buyData.addons = addons
    }

    if (token) {
      const shippingData = {
        apartment: +shipping.apartment,
        address: shipping.address,
        city: shipping.city,
        zipCode: shipping.zip
      }
      const deliveryDateObj = new Date(selectedDate)
      const deliveryDate = {
        dayInWeek: deliveryDateObj.getDay(),
        hour: deliveryDateObj.getHours(),
        minute: deliveryDateObj.getMinutes()
      }

      let userData = {
        ...user,
        deliveryDate,
        shipping: shippingData
      }

      if (creditCard) {
        userData.creditCard = creditCard
      }

      this.props.updateUser(userData)

      updateUser({ deliveryDate, shipping: shippingData }, token)

      buy(buyData, token)
        .then(() => {
          this.setState({ isLoading: false })
          this.props.resetOrder()
          this.props.history.push(ROUTES.SUCCESS)
        })
        .catch((err: AxiosError) => {
          showError(err)
          this.setState({ isLoading: false })
        })
    } else {
      if (this.createToken) {
        const { token } = await this.createToken()
        buyData.cardTokenId = token!.id
        buyData.client = {
          email: shipping.email,
          name: shipping.name,
          phone: shipping.phone
        }

        buyWithoutLogin(buyData)
          .then(() => {
            this.props.resetOrder()
            this.setState({ isLoading: false })
            this.props.history.push(ROUTES.SUCCESS)
          })
          .catch((err: AxiosError) => {
            showError(err)
            this.setState({ isLoading: false })
          })
      }
    }
  }

  handlePrevStep = () => {
    this.props.history.push(this.state.step.prevStep)
  }

  render() {
    const { step, isLoading, selectedDate, isEditActiveOrder, cardValidInfo, isShippingAddressShowed } = this.state

    return (
      <OrderContainerView
        step={step}
        modalRef={this.confirmModal}
        changeModalRef={this.changeModalRef}
        history={this.props.history}
        prevStep={this.handlePrevStep}
        nextStep={this.handleNextStep}
        isLoading={isLoading}
        isEditActiveOrder={isEditActiveOrder}
        isShippingAddressShowed={isShippingAddressShowed}
        selectedDate={selectedDate}
        submitOnConfirm={this.handleConfirmBuy}
        handleSelectDate={this.handleSelectDate}
        isCardFormValid={cardValidInfo.cardCvc && cardValidInfo.cardExpiry && cardValidInfo.cardNumber}
        handleFormValid={this.handleFormValid}
        getCreateToken={this.handleSetCreateTokenFunction}
      />
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  meals: getMeals(state),
  addons: getAddons(state),
  shipping: getShipping(state),
  orderType: getOrderType(state),
  totalMeals: getTotalMeals(state),
  packageType: getPackageType(state),
  discount: getDiscountCode(state),
  user: selectUserData(state),
  isSubscription: selectIsSubscription(state)
})

export default connect(
  mapStateToProps,
  { updateUser: updateReduxUser, resetOrder }
)(OrderContainer)
