import React from 'react'

import { ReactComponent as ConfirmIcon } from '../../../assets/svg/confirmOrder.svg'
import Modal from '../../../Components/Logical/Modal/Modal'
import Button from '../../../Components/FormElements/Button/Button'

import styles from './ConfirmModal.module.scss'
import { IProps } from './types'

const ConfirmModal: React.FunctionComponent<IProps> = ({ modal, onSubmit }) => (
  <Modal className={styles.container} ref={modal}>
    <h1>Review your order</h1>
    <div className={styles.icon}>
      <ConfirmIcon />
    </div>
    <div className={styles.text}>Please confirm that your order and delivery date is correct.</div>
    <div className={styles.buttons}>
      <Button onClick={() => modal.current!.handleClose()} className={styles.buttonText} type="text">
        CANCEL
      </Button>
      <Button onClick={() => onSubmit()} className={styles.button} type="green">
        Confirm
      </Button>
    </div>
  </Modal>
)

export default ConfirmModal
