import * as React from 'react'
import { Moment } from 'moment'
import { NavLink } from 'react-router-dom'
import { IItemProps } from './types'
import { SIDEBAR_NAMES } from '../../../Utils/constants'
import { ROUTES } from '../../../Utils/routes'
import ShouldRender from '../../Logical/ShouldRender/ShouldRender'
import { ReactComponent as Settings } from '../../../assets/svg/settings.svg'
import { ReactComponent as SettingsGreen } from '../../../assets/svg/settingsGreen.svg'
import { ReactComponent as Clock } from '../../../assets/svg/Clock.svg'
import styles from './Sidebar.module.scss'

interface IProps {
  name: string
  email: string
  isSubscription: boolean
  deliveryDate: Moment | null
}

class Sidebar extends React.Component<IProps> {
  static Item = ({ name, path }: IItemProps) => (
    <NavLink to={path} className={styles.item} activeClassName={styles.itemActive}>
      <ShouldRender onlyIf={window.location.pathname.includes(path)} render={<div className={styles.dot} />} />
      <p className={styles.itemName}>{name}</p>
    </NavLink>
  )

  render() {
    const isSettingsStep =
      window.location.pathname === ROUTES.ACCOUNT_SETTINGS ||
      window.location.pathname === ROUTES.ACCOUNT_SETTINGS_PASSWORD ||
      window.location.pathname === ROUTES.ACCOUT_SETTINGS_INFO

    const { name, email, deliveryDate, isSubscription } = this.props

    return (
      <div className={styles.sidebar}>
        <div>
          <div className={styles.sidebarHeader}>
            <h3 className={styles.username}>{name}</h3>
            <h4 className={styles.email}>{email}</h4>
            {deliveryDate && isSubscription && (
              <div className={styles.deliveryDate}>
                <Clock />
                <h4 className={styles.h4}>Next Delivery date:</h4>
                <p className={styles.p}>{deliveryDate.format('MM-DD-YYYY')}</p>
              </div>
            )}
          </div>
          {React.Children.map(this.props.children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child as React.ReactElement<IItemProps>, {
                name: (child as React.ReactElement<IItemProps>).props.name,
                path: (child as React.ReactElement<IItemProps>).props.path
              })
            }
          })}
        </div>
        <div className={styles.settings}>
          <ShouldRender onlyIf={isSettingsStep} render={<SettingsGreen className={styles.icon} />} />
          <ShouldRender onlyIf={!isSettingsStep} render={<Settings className={styles.icon} />} />
          <NavLink to={ROUTES.ACCOUNT_SETTINGS} activeClassName={styles.settingsActive}>
            <p className={styles.settingsLink}>{SIDEBAR_NAMES.SETTINGS}</p>
          </NavLink>
        </div>
      </div>
    )
  }
}

export default Sidebar
