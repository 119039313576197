import React from 'react'

import { ReactComponent as ConfirmIcon } from '../../../assets/svg/changeConfirm.svg'
import Modal from '../../../Components/Logical/Modal/Modal'
import Button from '../../../Components/FormElements/Button/Button'

import styles from './ConfirmChangeModal.module.scss'
import { IProps } from './types'

const ConfirmChangeModal: React.FunctionComponent<IProps> = ({ modal, onSubmit }) => (
  <Modal className={styles.container} ref={modal}>
    <h1>Updating your subscription</h1>
    <div className={styles.icon}>
      <ConfirmIcon />
    </div>
    <div className={styles.text}>Please confirm that you wish to modify your subscription.</div>
    <div className={styles.buttons}>
      <Button onClick={() => modal.current!.handleClose()} className={styles.buttonText} type="text">
        CANCEL
      </Button>
      <Button onClick={() => onSubmit()} className={styles.button} type="green">
        Update
      </Button>
    </div>
  </Modal>
)

export default ConfirmChangeModal
