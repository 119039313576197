import * as React from 'react'

import Dialog from '@material-ui/core/Dialog'

import { IProps } from './types'

import Button from '../../../../Components/FormElements/Button/Button'
import Plan from '../Components/Plan/Plan'

import { ReactComponent as Document } from '../../../../assets/svg/document.svg'
import { ReactComponent as Arrow } from '../../../../assets/svg/arrowRight.svg'
import { ReactComponent as Clock } from '../../../../assets/svg/Clock.svg'

import { SUBSCRIPTIONS } from '../../../../Utils/constants'
import { ISubscriptionType } from '../../../../Utils/types'
import Slide from '@material-ui/core/Slide'
import styles from './SubscribeContainerView.module.scss'

function Transition(props: any) {
  return <Slide direction="up" {...props} />
}

const SubscribeContainerView: React.FunctionComponent<IProps> = ({
  onClick,
  isModalOpen,
  onClose,
  onSubmit,
  activePackage,
  deliveryDate
}) => (
  <div className={styles.container}>
    <div className={styles.info}>
      <Document />
      <p className={styles.p}>Change your subscription at any time. Order more, Save more!</p>
    </div>
    <div>
      <div className={styles.flexRow}>
        <Plan
          active={activePackage === SUBSCRIPTIONS.SMALL.MEALS}
          className={styles.marginRight}
          amount={5}
          onClick={() =>
            onClick({
              type: SUBSCRIPTIONS.SMALL.MEALS as ISubscriptionType,
              price: SUBSCRIPTIONS.SMALL.PRICE,
              name: SUBSCRIPTIONS.SMALL.NAME
            })
          }
        />
        <Plan
          active={activePackage === SUBSCRIPTIONS.MEDIUM.MEALS}
          amount={10}
          onClick={() =>
            onClick({
              type: SUBSCRIPTIONS.MEDIUM.MEALS as ISubscriptionType,
              price: SUBSCRIPTIONS.MEDIUM.PRICE,
              name: SUBSCRIPTIONS.MEDIUM.NAME
            })
          }
        />
      </div>
      <div className={styles.flexRow}>
        <Plan
          active={activePackage === SUBSCRIPTIONS.MEDIUM_RARE.MEALS}
          className={styles.marginRight}
          amount={15}
          onClick={() =>
            onClick({
              type: SUBSCRIPTIONS.MEDIUM_RARE.MEALS as ISubscriptionType,
              price: SUBSCRIPTIONS.MEDIUM_RARE.PRICE,
              name: SUBSCRIPTIONS.MEDIUM_RARE.NAME
            })
          }
        />
        <Plan
          active={activePackage === SUBSCRIPTIONS.BIG.MEALS}
          amount={20}
          onClick={() =>
            onClick({
              type: SUBSCRIPTIONS.BIG.MEALS as ISubscriptionType,
              price: SUBSCRIPTIONS.BIG.PRICE,
              name: SUBSCRIPTIONS.BIG.NAME
            })
          }
        />
      </div>
    </div>
    <Dialog
      open={isModalOpen}
      maxWidth="md"
      onClose={onClose}
      TransitionComponent={Transition}
      className={styles.modal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div className={styles.content}>
        <h2 className={styles.h2} id="alert-dialog-slide-title">
          Do you want to change your plan?
        </h2>
        {deliveryDate ? (
          <div className={styles.deliveryDate}>
            <Clock />
            <h4 className={styles.h4}>Next Delivery date:</h4>
            <p className={styles.dateText}>{deliveryDate.format('MM-DD-YYYY')}</p>
          </div>
        ) : null}
        <div id="alert-dialog-slide-description" className={styles.buttonContainer}>
          <Button className={styles.buttonText} type="text" onClick={onClose}>
            <p>Discard</p>
          </Button>
          <Button className={styles.buttonSubmit} onClick={onSubmit} type="green">
            <p>Save new info</p>
            <div className={styles.buttonDot}>
              <Arrow />
            </div>
          </Button>
        </div>
      </div>
    </Dialog>
  </div>
)

export default SubscribeContainerView
