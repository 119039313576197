import * as React from 'react'
import { connect } from 'react-redux'

import PaymentContainerView from '../../../Views/Order/PaymentContainerView/PaymentContainerView'

import { getMeals, getAddons } from '../../../Redux/Services/OrderService/helpers'
import { IReduxStore } from '../../../Utils/types'
import { IProps } from './types'

class PaymentContainer extends React.Component<IProps> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { meals, addons, getCreateToken, handleFormValid } = this.props

    return (
      <PaymentContainerView
        handleFormValid={handleFormValid}
        getCreateToken={getCreateToken}
        meals={meals}
        addons={addons}
      />
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  meals: getMeals(state),
  addons: getAddons(state)
})

export default connect(mapStateToProps)(PaymentContainer)
