import * as React from 'react'
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements'
import { IElement } from '../../Types'
import styles from '../../View/BillingContainerView.module.scss'

export interface IProps {
  handleSetRef: (element: IElement, type: string) => void
}

const CardEdit: React.FunctionComponent<IProps> = ({ handleSetRef }) => {
  return (
    <div className={styles.cardItems}>
      <div className={styles.cardItem}>
        <p className={styles.cardLabel}>
          CARD NUMBER <span>*</span>
        </p>
        <CardNumberElement
          className={styles.stripeElement}
          onReady={(element: IElement) => handleSetRef(element, 'name')}
        />
      </div>
      <div className={styles.flex}>
        <div className={`${styles.cardItem} ${styles.cardExpiration}`}>
          <p className={styles.cardLabel}>
            EXPIRATION DATE <span>*</span>
          </p>
          <CardExpiryElement
            className={styles.stripeElement}
            onReady={(element: IElement) => handleSetRef(element, 'cvv')}
          />
        </div>
        <div className={`${styles.cardItem} ${styles.cardCVC}`}>
          <p className={styles.cardLabel}>
            CVC <span>*</span>
          </p>
          <CardCVCElement
            className={styles.stripeElement}
            onReady={(element: IElement) => handleSetRef(element, 'expire')}
          />
        </div>
      </div>
    </div>
  )
}

export default CardEdit
