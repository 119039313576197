import { getOrderFromLocalStorage } from '../helpers'
import { IState } from '../types'

const stored = getOrderFromLocalStorage()

export const initState: Readonly<IState> = {
  totalPrice: stored ? stored.totalPrice : 0,
  totalMeals: stored ? stored.totalMeals : 0,
  meals: stored ? [...stored.meals] : [],
  addons: stored ? [...stored.addons] : [],
  orderType: stored ? stored.orderType : undefined,
  packageType: stored ? stored.packageType : undefined,
  discountCode: stored ? stored.discountCode : { id: '', type: 'Percent', amount: 0, name: '' },
  packageInfo: {
    price: stored ? stored.packageInfo.price : 0,
    name: stored ? stored.packageInfo.name : ''
  },
  shipping: stored
    ? stored.shipping
    : {
        zip: '',
        name: '',
        city: '',
        email: '',
        phone: '',
        address: '',
        apartment: ''
      }
}

export const DEFAULT_STATE: IState = {
  totalPrice: 0,
  totalMeals: 0,
  meals: [],
  addons: [],
  discountCode: {
    id: '',
    type: 'Percent',
    amount: 0,
    name: ''
  },
  orderType: undefined,
  packageType: undefined,
  packageInfo: {
    price: 0,
    name: ''
  },
  shipping: {
    zip: '',
    name: '',
    city: '',
    email: '',
    phone: '',
    address: '',
    apartment: ''
  }
}
