import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import Calendar from 'react-calendar'
import { connect } from 'react-redux'
import cx from 'classnames'
import DayJS from 'dayjs'

import { ReportState } from './types'
import Button from '../../Components/FormElements/Button/Button'
import styles from './styles.module.scss'
import './calendar.scss'
import { selectUserData } from '../../Redux/Services/UserService/UserService'
import { IUserData, IReduxStore } from '../../Utils/types'

const Report: React.FC<{ user: IUserData['user'] } & RouteComponentProps> = ({ user, history }) => {
  const role = user.user.email
  const [state, setState] = useState<ReportState>({ date: null, type: null, error: null })
  const onClickDay = (value: Date) => setState({ ...state, date: DayJS(value).format('YYYY-MM-DD') })
  const onSelectType = (type: 'chef' | 'delivery') => setState({ ...state, type })
  const btnClass = (type: 'chef' | 'delivery') =>
    cx(styles.buttonTransparent, {
      [styles.buttonTransparentActive]:
        (type === 'chef' && state.type === 'chef') || (type === 'delivery' && state.type === 'delivery')
    })

  useEffect(() => {
    if (role !== 'chefs@elevatedliving.com') {
      history.push('/')
    }
  }, [])

  return (
    <section className={styles.container}>
      <h3 className={styles.h3}>Select date:</h3>
      <Calendar
        value={state.date ? new Date(state.date) : undefined}
        onClickDay={onClickDay}
        calendarType="US"
        tileDisabled={({ activeStartDate, date }) =>
          date.getDay() === 1 ||
          date.getDay() === 2 ||
          date.getDay() === 4 ||
          date.getDay() === 5 ||
          date.getDay() === 6
        }
      />
      <h3 className={styles.h3}>Type of services:</h3>
      <div>
        <Button className={btnClass('chef')} type="transparent" onClick={() => onSelectType('chef')}>
          Chef
        </Button>
        <Button className={btnClass('delivery')} type="transparent" onClick={() => onSelectType('delivery')}>
          Delivery
        </Button>
      </div>
      {state.error && <p>{state.error}</p>}
      <a
        onClick={(e) => {
          e.preventDefault()
          setState({ date: null, type: null, error: null })
          window.open(`${process.env.REACT_APP_API_URL}/report/${state.type}?date=${state.date}`)
        }}
        href={`${process.env.REACT_APP_API_URL}/report/${state.type}?date=${state.date}`}
      >
        <Button className={styles.button} type="grey">
          Submit
        </Button>
      </a>
    </section>
  )
}

export default connect((state: IReduxStore) => ({ user: selectUserData(state) }))(Report)
