import * as React from 'react'
import { AxiosResponse, AxiosError } from 'axios'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import TopBar from './Components/TopBar/TopBar'
import Table from './Components/Table/Table'

import { showError } from '../../../Utils/helpers'
import { IActiveOrder, IMealDetails, IProtein, IPackageInfo, ISubscriptionType } from '../../../Utils/types'
import { getActiveMeals } from '../../../Utils/api'
import { getUserToken } from '../../../Utils/helpers'
import { setOrder, setSubscriptionType } from '../../../Redux/Services/OrderService/OrderService'
import { setActiveOrder } from '../../../Redux/Services/UserService/UserService'
import { SUBSCRIPTIONS } from '../../../Utils/constants'
import { IState, IProps } from './types'
import styles from './ActiveOrderContainer.module.scss'
import { ROUTES } from '../../../Utils/routes'
import { ORDER_TYPE } from '../../../Utils/constants'

class ActiveOrderContainer extends React.Component<RouteComponentProps & IProps, IState> {
  readonly state: IState = {
    activeOrder: null
  }

  componentDidMount() {
    this.handleGetActiveMeals()
  }

  handleGetActiveMeals = () => {
    const token = getUserToken()

    if (token) {
      getActiveMeals(token)
        .then(({ data }: AxiosResponse<IActiveOrder>) => {
          data.meals = data.meals.map((meal: IMealDetails) => {
            return {
              ...meal,
              proteins: meal.proteins
                ? meal.proteins.map((protein: IProtein) => ({ ...protein, price: protein.price * 100 }))
                : undefined
            }
          })

          this.setState({ activeOrder: data })
          this.props.setActiveOrder(data)
        })
        .catch((err: AxiosError) => {
          showError(err, 'Error')
        })
    }
  }

  handleGoToEditSubscription = () => {
    const { activeOrder } = this.state

    const subscription: { subscriptionType: ISubscriptionType; subscribsionDetail: IPackageInfo } = {
      subscriptionType: SUBSCRIPTIONS.SMALL.MEALS as ISubscriptionType,
      subscribsionDetail: {
        price: SUBSCRIPTIONS.SMALL.PRICE,
        name: SUBSCRIPTIONS.SMALL.NAME
      }
    }

    if (activeOrder!.maxMeals === 10) {
      subscription.subscriptionType = SUBSCRIPTIONS.MEDIUM.MEALS as ISubscriptionType
      subscription.subscribsionDetail.name = SUBSCRIPTIONS.MEDIUM.NAME
      subscription.subscribsionDetail.price = SUBSCRIPTIONS.MEDIUM.PRICE
    } else if (activeOrder!.maxMeals === 15) {
      subscription.subscriptionType = SUBSCRIPTIONS.MEDIUM_RARE.MEALS as ISubscriptionType
      subscription.subscribsionDetail.name = SUBSCRIPTIONS.MEDIUM_RARE.NAME
      subscription.subscribsionDetail.price = SUBSCRIPTIONS.MEDIUM_RARE.PRICE
    } else if (activeOrder!.maxMeals === 20) {
      subscription.subscriptionType = SUBSCRIPTIONS.BIG.MEALS as ISubscriptionType
      subscription.subscribsionDetail.name = SUBSCRIPTIONS.BIG.NAME
      subscription.subscribsionDetail.price = SUBSCRIPTIONS.BIG.PRICE
    }

    const updatedMeals = activeOrder!.meals.map((meal) => {
      return {
        ...meal,
        price: meal.price / meal.quantity
      }
    })

    this.props.setSubscriptionType(subscription.subscriptionType, subscription.subscribsionDetail)
    this.props.setOrder(updatedMeals, activeOrder!.addons)
    this.props.history.push({
      pathname: ROUTES.ORDER,
      state: {
        changeActiveMeals: true
      }
    })
  }

  render() {
    const { activeOrder } = this.state

    return activeOrder ? (
      <div className={styles.container}>
        <TopBar goToEditSubscription={this.handleGoToEditSubscription} mealPack={activeOrder.maxMeals} />
        <div className={styles.mealsContainer}>
          <h3 className={styles.h3}>Meals</h3>
          <Table type="meals" data={activeOrder.meals} />
        </div>
        <div className={styles.addonsContainer}>
          <h3 className={styles.h3}>ADD-ON's</h3>
          <Table type="addons" data={activeOrder.addons} />
        </div>
      </div>
    ) : null
  }
}

export default connect(
  null,
  { setActiveOrder, setOrder, setSubscriptionType }
)(ActiveOrderContainer)
