import * as React from 'react'

import { SUBSCRIPTIONS, TEXTS } from '../../../../Utils/constants'
import { ISubscriptionType } from '../../../../Utils/types'
import { IProps } from './types'

import BorderCard from '../../../../Components/Presentational/BorderCard/BorderCard'
import Button from '../../../../Components/FormElements/Button/Button'

import styles from './Plans.module.scss'

const Plans: React.FunctionComponent<IProps> = ({ setSubscriptionType }) => (
  <div className={styles.container}>
    <h2 className={styles.h2}>{TEXTS.LANDING.PLANS_HEADING}</h2>
    <p className={styles.description}>{TEXTS.LANDING.PLANS_DESCRIPTION}</p>
    <div className={styles.plans}>
      <BorderCard type="dashed">
        <div className={styles.plan}>
          <h4 className={styles.headingInvisible}>No discount</h4>
          <div className={styles.action}>
            <div className={styles.info}>
              <p className={styles.p}>
                <span className={styles.amount}>{SUBSCRIPTIONS.SMALL.MEALS}</span>{' '}
                <span className={styles.mealsSpan}>meals</span>
              </p>
              <p className={styles.weekSpan}>per week</p>
            </div>

            <Button
              type="radial-green-1"
              className={styles.button}
              onClick={() =>
                setSubscriptionType(SUBSCRIPTIONS.SMALL.MEALS as ISubscriptionType, {
                  price: SUBSCRIPTIONS.SMALL.PRICE,
                  name: SUBSCRIPTIONS.SMALL.NAME
                })
              }
            />
          </div>
        </div>
      </BorderCard>
      <BorderCard type="normal">
        <div className={styles.plan}>
          <h4 className={styles.heading}>4% discount</h4>
          <div className={styles.action}>
            <div className={styles.info}>
              <p className={styles.p}>
                <span className={styles.amount}>{SUBSCRIPTIONS.MEDIUM.MEALS}</span>{' '}
                <span className={styles.mealsSpan}>meals</span>
              </p>
              <p className={styles.weekSpan}>per week</p>
            </div>
            <Button
              type="radial-green-1"
              className={styles.button}
              onClick={() =>
                setSubscriptionType(SUBSCRIPTIONS.MEDIUM.MEALS as ISubscriptionType, {
                  price: SUBSCRIPTIONS.MEDIUM.PRICE,
                  name: SUBSCRIPTIONS.MEDIUM.NAME
                })
              }
            />
          </div>
        </div>
      </BorderCard>
      <BorderCard type="normal">
        <div className={styles.plan}>
          <h4 className={styles.heading}>7% discount</h4>
          <div className={styles.action}>
            <div className={styles.info}>
              <p className={styles.p}>
                <span className={styles.amount}>{SUBSCRIPTIONS.MEDIUM_RARE.MEALS}</span>{' '}
                <span className={styles.mealsSpan}>meals</span>
              </p>
              <p className={styles.weekSpan}>per week</p>
            </div>
            <Button
              type="radial-green-1"
              className={styles.button}
              onClick={() =>
                setSubscriptionType(SUBSCRIPTIONS.MEDIUM_RARE.MEALS as ISubscriptionType, {
                  price: SUBSCRIPTIONS.MEDIUM_RARE.PRICE,
                  name: SUBSCRIPTIONS.MEDIUM_RARE.NAME
                })
              }
            />
          </div>
        </div>
      </BorderCard>
      <BorderCard type="normal">
        <div className={styles.plan}>
          <h4 className={styles.heading}>10% discount</h4>
          <div className={styles.action}>
            <div className={styles.info}>
              <p className={styles.p}>
                <span className={styles.amount}>{SUBSCRIPTIONS.BIG.MEALS}</span>{' '}
                <span className={styles.mealsSpan}>meals</span>
              </p>
              <p className={styles.weekSpan}>per week</p>
            </div>
            <Button
              type="radial-green-1"
              className={styles.button}
              onClick={() =>
                setSubscriptionType(SUBSCRIPTIONS.BIG.MEALS as ISubscriptionType, {
                  price: SUBSCRIPTIONS.BIG.PRICE,
                  name: SUBSCRIPTIONS.BIG.NAME
                })
              }
            />
          </div>
        </div>
      </BorderCard>
    </div>
  </div>
)

export default Plans
