import * as React from 'react'
import { ReactComponent as Checkmark } from '../../../../assets/svg/aboutCheckmark.svg'
import { ReactComponent as Carrot } from '../../../../assets/svg/aboutCarrot.svg'
import { ReactComponent as Car } from '../../../../assets/svg/aboutCar.svg'
import Button from '../../../../Components/FormElements/Button/Button'
import styles from './About.module.scss'

const About = () => (
  <div className={styles.container} id="about">
    <div className={styles.inner}>
      <div className={styles.content}>
        <div className={styles.info}>
          <h2 className={styles.h2}>How it works?</h2>
          <p className={styles.p}>
            We get it! You are out there grinding and don't have time to prepare healthy & fresh meals for yourself.
            Don't leave your nutrition to chance. Leave it to Elevated Chefs.
          </p>
          <div className={styles.items}>
            <div className={styles.item}>
              <Checkmark className={styles.icon} />
              <p className={styles.itemText}>Pick your meal plan</p>
            </div>
            <div className={styles.item}>
              <Car className={styles.icon} />
              <p className={styles.itemText}>We deliver</p>
            </div>
            <div className={styles.item}>
              <Carrot className={styles.icon} />
              <p className={styles.itemText}>You enjoy</p>
            </div>
          </div>
          <a href="/#food" className={styles.button} placeholder="get started">
            Get started
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default About
