import {
  IMeal,
  IAddon,
  IMealObj,
  IAddonObj,
  IOrderType,
  ISubscriptionType,
  IPackageInfo,
  IProtein,
  IShipping,
  IMealDetails,
  IAddonDetails,
  PackageType
} from '../../../../Utils/types'
import { LOCAL_STORAGE_ITEMS, ORDER_TYPE, IDiscount } from '../../../../Utils/constants'
import { saveInLocalStorage, deleteFromLocalStorage } from './localStorage'
import { DEFAULT_STATE } from '../constants'
import { IState, IKey } from '../types'

const countTotal = (meals: IMealObj[]) => {
  return meals.reduce((total, item) => total + item.amount, 0)
}

const countTotalPrice = (meals: IMealObj[], addons: IAddonObj[]): number => {
  if (meals.length > 0 || addons.length > 0) {
    const mealsPrice = meals.reduce((prev, item) => prev + item.amount * item.meal.price + (item.protein ? (item.protein.price / 100) * item.amount : 0), 0)
    const addonsPrice = addons.reduce((prev, item) => prev + item.amount * item.addon.price, 0)

    let totalPrice = (mealsPrice + addonsPrice).toFixed(2)

    return +totalPrice
  }

  return 0
}

type ObjInArr = { exist: boolean; index: number }

const objInArr = (newItem: IAddon | IMeal, meals?: IMealObj[], addons?: IAddonObj[]): ObjInArr => {
  let index: number = 0

  if (!meals && !addons) {
    return { exist: false, index }
  } else {
    if (meals) {
      index = meals.findIndex((item: IMealObj) => item.meal.name === newItem.name)
    }

    if (addons) {
      index = addons.findIndex((item: IAddonObj) => item.addon.id == newItem.id)
    }

    return { exist: index !== -1, index }
  }
}

export const updateState = (func: Function, params: any) => {
  deleteFromLocalStorage()
  const nextState = func(...params)
  if (nextState.orderType || nextState.totalPrice > 0) {
    saveInLocalStorage(nextState)
  }

  return nextState
}

export const updateMeals = (state: IState, amount: number, meal: IMeal): IState => {
  const inState = objInArr(meal, state.meals)

  if (inState.exist) {
    const meals = [...state.meals]

    amount === 0 ? meals.splice(inState.index, 1) : (meals[inState.index].amount = amount)

    let nextState = { ...state, meals, totalMeals: countTotal(meals) }
    const totalPrice = countTotalPrice(nextState.meals, nextState.addons)
    nextState = { ...nextState, totalPrice }
    return nextState
  } else {
    const meals = [...state.meals, { amount, meal }]
    let nextState = { ...state, meals, totalMeals: countTotal(meals) }
    const totalPrice = countTotalPrice(nextState.meals, nextState.addons)
    nextState = { ...nextState, totalPrice }
    return nextState
  }
}

export const updateAddons = (state: IState, amount: number, addon: IAddon): IState => {
  const inState = objInArr(addon, undefined, state.addons)

  if (inState.exist) {
    const addons = [...state.addons]

    amount === 0 ? addons.splice(inState.index, 1) : (addons[inState.index].amount = amount)

    let nextState = { ...state, addons }
    const totalPrice = countTotalPrice(nextState.meals, nextState.addons)
    nextState = { ...nextState, totalPrice }
    return nextState
  } else {
    let nextState = { ...state, addons: [...state.addons, { amount, addon }] }
    const totalPrice = countTotalPrice(nextState.meals, nextState.addons)
    nextState = { ...nextState, totalPrice }
    return nextState
  }
}

export const updateOrderType = (state: IState, orderType: IOrderType) => {
  const nextState = { ...state, orderType }
  return nextState
}

export const updateShippingData = (state: IState, key: IKey, value: string) => {
  const nextState = { ...state, shipping: { ...state.shipping, [key]: value } }
  return nextState
}

export const setShippingData = (state: IState, shippingData: IShipping) => {
  return { ...state, shipping: shippingData }
}

export const updateSubscriptionType = (subscriptionType: ISubscriptionType, packageInfo: IPackageInfo) => {
  const nextState = {
    ...DEFAULT_STATE,
    orderType: ORDER_TYPE.SUBSCRIPTION,
    packageType: subscriptionType,
    packageInfo
  }
  return nextState
}

export const updateOrder = (state: IState, meals: IMealDetails[], addons: IAddonDetails[]): IState => {
  const mapedMeal: IMealObj[] = meals.map((meal: IMealDetails) => {
    const data: IMealObj = {
      amount: meal.quantity,
      meal: {
        id: meal.planId,
        price: meal.price,
        name: meal.name,
        metadata: {
          calories: `${meal.calories}`,
          protein: `${meal.protein}`,
          fat: `${meal.fat}`,
          carbs: `${meal.carbs}`,
          sodium: `${meal.sodium}`,
          image: meal.image,
          ingredients: meal.ingredients
        }
      }
    }
    if (meal.proteins) {
      data.protein = meal.proteins[0]
    }

    return data
  })

  const mapedAddons: IAddonObj[] = addons.map((addon: IAddonDetails) => ({
    addon: {
      id: addon.planId,
      price: Number(addon.price),
      name: addon.name,
      metadata: {
        calories: addon.calories,
        image: addon.image
      }
    },
    amount: addon.quantity
  }))

  return {
    ...state,
    meals: mapedMeal,
    addons: mapedAddons
  }
}

export const handleResetOrder = () => {
  deleteFromLocalStorage(LOCAL_STORAGE_ITEMS.LAST_STEP)
  return DEFAULT_STATE
}

export const updateProtein = (state: IState, mealId: string, protein: IProtein) => {
  const meals = state.meals.map((meal: IMealObj) => {
    if (mealId === meal.meal.id) {
      meal.protein = protein
    }

    return meal
  })

  return { ...state, meals }
}

export const updateDiscount = (state: IState, discount: IDiscount) => {
  return { ...state, discountCode: discount }
}
