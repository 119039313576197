import * as React from 'react'
import * as ReactDOM from 'react-dom'
import classNames from 'classnames'

import { IState, IProps } from './types'

import styles from './Modal.module.scss'

class Modal extends React.Component<IProps, IState> {
  modal = React.createRef<HTMLDivElement>()
  state = {
    isOpen: false
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    const { onClose } = this.props
    this.setState({ isOpen: false })
    onClose && onClose()
  }

  render() {
    const { isOpen } = this.state

    const modalClass = classNames(styles.modal, this.props.className)

    return ReactDOM.createPortal(
      isOpen && (
        <div className={styles.container} onClick={this.handleClose}>
          <div className={modalClass} ref={this.modal} onClick={(e) => e.stopPropagation()}>
            {this.props.children}
          </div>
        </div>
      ),
      document.getElementById('modal-root') as HTMLElement
    )
  }
}

export default Modal
