import * as React from 'react'
import classNames from 'classnames'
import { TEXTS } from '../../../Utils/constants'
import { IOrder, IAddon } from '../../../Utils/types'
import MealPicker from '../../../Components/Order/ProductPicker/ProductPicker'
import SelectionList from '../../../Components/Order/SelectionList/SelectionList'
import PriceSummary from '../../../Components/Order/PriceSummary/PriceSummary'
import styles from './AddonsContainerView.module.scss'

interface IProps {
  order: IOrder
  addAddon: (amount: number, addon: IAddon) => void
}

const AddonsContainerView: React.FunctionComponent<IProps> = ({ order, addAddon }) => {
  const bottomInfoClass = classNames(styles.bottomInfo, {
    [styles.flexModifier]: order.addons.length === 0
  })

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>{TEXTS.ADDONS.HEADING}</h2>
      <MealPicker type="addon" />
      <div className={bottomInfoClass}>
        <SelectionList addAddon={addAddon} addons={order.addons} heading={TEXTS.ADDONS.TABLE_HEADING} />
        <PriceSummary className={styles.summary} />
      </div>
    </div>
  )
}

export default AddonsContainerView
