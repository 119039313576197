import { getUserFromLocalStorage } from '../helpers'

const stored = getUserFromLocalStorage()
export const initialStateData = {
  user: {
    _id: '',
    name: '',
    phone: '',
    maxMeals: 0,
    user: {
      role: '',
      emailConfirmation: false,
      _id: '',
      email: '',
      createdAt: new Date()
    },
    createdAt: new Date()
  },
  activeOrder: {
    _id: '',
    user: '',
    deliveryDate: new Date(),
    totalPrice: 0,
    maxMeals: 0,
    meals: [],
    addons: [],
    createdAt: new Date(),
    __v: ''
  }
}

export const initialState = stored
  ? stored
  : {
      user: {
        _id: '',
        name: '',
        phone: '',
        maxMeals: 0,
        user: {
          role: '',
          emailConfirmation: false,
          _id: '',
          email: '',
          createdAt: new Date()
        },
        createdAt: new Date()
      },
      activeOrder: {
        _id: '',
        user: '',
        deliveryDate: new Date(),
        totalPrice: 0,
        maxMeals: 0,
        meals: [],
        addons: [],
        createdAt: new Date(),
        __v: ''
      }
    }
