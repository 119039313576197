export const SIDEBAR_NAMES = {
  HISTORY: 'Order History',
  MEALS: 'Your Active Meals',
  SETTINGS: 'Account Settings',
  SHIPPING: 'Shipping Address',
  BILLING: 'Billing Information',
  SUBSCRIBE: 'Subscribe and Save'
}

export const SETTINGS_TEXTS = {
  CHANGE_PW: 'Change your password',
  CHANGE_SETTINGS: 'Change your account settings',
  MARKETING:
    'Signup for our weekly newsletter to get latest news, updates and amazing offers deivered directly in your inbox.'
}
