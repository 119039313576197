import { saveItem, removeItem, getItem } from '../../../../Helpers/localStorage'
import { LOCAL_STORAGE_ITEMS } from '../../../../Utils/constants'
import { IUserService } from '../types'

export const deleteFromLocalStorage = (item?: string) => {
  removeItem(item ? item : LOCAL_STORAGE_ITEMS.USER)
}

export const getUserFromLocalStorage = () => {
  const item = getItem(LOCAL_STORAGE_ITEMS.USER)
  return item as IUserService
}

export const saveInLocalStorage = (state: IUserService) => {
  saveItem(LOCAL_STORAGE_ITEMS.USER, state)
}
