import * as React from 'react'

import RegisterBG from '../../../assets/img/register.jpg'
import { IProps, IState } from './types'

import Button from '../../FormElements/Button/Button'
import Input from '../../FormElements/Input/Input'
import Modal from '../../Logical/Modal/Modal'
import { ReactComponent as ConfirmSvg } from '../../../assets/svg/confirm.svg'

import { MainContext } from '../../../Containers/MainContainer/MainContainer'
import { validateName, validateEmail, validatePassword } from '../../../Utils/constants/validation'
import styles from './RegisterModal.module.scss'

type GetFilteredKeysBy<INPUT, FILTER_BY> = ({ [K in keyof INPUT]: INPUT[K] extends FILTER_BY ? K : never })[keyof INPUT]

type SelectProps<INPUT, FILTER> = Pick<INPUT, GetFilteredKeysBy<INPUT, FILTER>>

class RegisterModal extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    isConfirm: false
  }

  handleClose = () => {
    this.setState({ isConfirm: false })
  }

  handleChange = (id: string, value: string) => {
    const update = { [id]: value } as SelectProps<IState, string>
    this.setState(update)
  }

  handleSubmit = () => {
    const { name, email, password } = this.state
    this.props
      .onSubmit({ name, email: email.toLowerCase(), password })
      .then(() => {
        this.setState({ isConfirm: true })
      })
      .catch(() => this.setState({ isConfirm: false }))
  }

  render() {
    const { name, email, password, confirmPassword, isConfirm } = this.state
    const { switchModal, onClose } = this.props
    const validateConfirmPassword = (value: string) => {
      return validatePassword(value) && value === password
    }

    return (
      <MainContext.Consumer>
        {({ isLoading, error }) => (
          <Modal className={styles.container} ref={this.props.modal} onClose={this.handleClose}>
            <div className={styles.leftSide}>
              <img className={styles.background} src={RegisterBG} />
            </div>
            <div className={styles.rightSide}>
              {!isConfirm ? (
                <>
                  <h2 className={styles.heading}>Get healthy!</h2>
                  <Input
                    className={styles.input}
                    id="name"
                    value={name}
                    label="Full Name"
                    onChange={this.handleChange as (id: string, value: string) => void}
                    validate={validateName}
                    hint="Name have to contain at least 3 characters"
                  />

                  <Input
                    className={styles.input}
                    id="email"
                    value={email}
                    label="Email"
                    onChange={this.handleChange}
                    validate={validateEmail}
                    hint="Email addres is incorrect"
                  />

                  <Input
                    id="password"
                    type="password"
                    value={password}
                    label="Password"
                    className={styles.input}
                    onChange={this.handleChange}
                    validate={validatePassword}
                    hint="The password is not strong enough! Please make sure your password has a minimum of 7 characters, at least one digit and an upper-case letter."
                  />
                  <Input
                    id="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    label="Confirm Password"
                    className={styles.input}
                    onChange={this.handleChange}
                    validate={validateConfirmPassword}
                    hint={
                      password !== confirmPassword
                        ? 'Passwords are not identical'
                        : 'Password have to contain at least 7 characters'
                    }
                  />

                  {error.state ? <p className={`${styles.info} ${styles.infoError}`}>{error.msg}</p> : null}

                  <div className={styles.actionsContainer}>
                    <Button
                      className={styles.loginButton}
                      type="green"
                      placeholder="sign in"
                      loading={isLoading}
                      onClick={this.handleSubmit}
                      disabled={
                        !validateName(name) ||
                        !validateEmail(email) ||
                        !validatePassword(password) ||
                        !validatePassword(confirmPassword) ||
                        password !== confirmPassword
                      }
                    />
                    <div className={styles.infoBox}>
                      <p className={styles.info}>I already have account, </p>
                      <Button
                        className={styles.registerButton}
                        type="text"
                        placeholder="log in"
                        onClick={() => switchModal('login')}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h2 className={`${styles.heading} ${styles.center}`}>Confirm Your Email</h2>
                  <div className={styles.svg}>
                    <ConfirmSvg />
                  </div>
                  <p className={`${styles.info} ${styles.center}`}>
                    Please check your inbox for a confirmation email. Click the link in the email to confirm your email
                    address.
                  </p>
                  <div className={`${styles.actionsContainer} ${styles.centerButtons}`}>
                    <Button className={styles.loginButton} type="green" placeholder="Continue" onClick={onClose} />
                    <Button
                      className={styles.resendButton}
                      onClick={() => switchModal('resendActivation')}
                      type="transparent"
                      placeholder="Re-send confirmation email"
                    />
                  </div>
                </>
              )}
            </div>
          </Modal>
        )}
      </MainContext.Consumer>
    )
  }
}

export default RegisterModal
