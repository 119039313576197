import * as React from 'react'

import LoginBG from '../../../assets/img/login.jpg'
import { IProps, IState } from './types'

import Button from '../../FormElements/Button/Button'
import Input from '../../FormElements/Input/Input'
import Modal from '../../Logical/Modal/Modal'

import { MainContext } from '../../../Containers/MainContainer/MainContainer'
import { validateEmail } from '../../../Utils/constants/validation'
import styles from './ResendActivationModal.module.scss'

class ResendActivationModal extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    email: ''
  }

  handleChange = (id: string, value: string) => {
    this.setState({ [id]: value } as Pick<IState, Exclude<keyof IState, 'isConfirm'>>)
  }

  handleSubmit = () => {
    const { email } = this.state
    const { onSubmit } = this.props

    onSubmit(email.toLowerCase())
  }

  render() {
    const { email } = this.state
    const { onClose } = this.props

    return (
      <MainContext.Consumer>
        {({ isLoading }) => (
          <Modal className={styles.container} ref={this.props.modal}>
            <div className={styles.leftSide}>
              <img className={styles.background} src={LoginBG} />
            </div>
            <div className={styles.rightSide}>
              <h2 className={styles.heading}>Resend activation link</h2>

              <Input
                className={styles.input}
                id="email"
                value={email}
                label="Email"
                onChange={this.handleChange}
                validate={validateEmail}
                hint="Invalid email address"
              />

              <div className={styles.actionsContainer}>
                <Button
                  className={styles.loginButton}
                  type="green"
                  loading={isLoading}
                  placeholder="resend link"
                  onClick={this.handleSubmit}
                  disabled={!validateEmail(email)}
                />

                <Button className={styles.registerButton} type="text" placeholder="cancel" onClick={onClose} />
              </div>
            </div>
          </Modal>
        )}
      </MainContext.Consumer>
    )
  }
}

export default ResendActivationModal
