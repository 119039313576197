import React from 'react'
import { IProps } from './types'
import styles from './MetaDataItem.module.scss'

const MetaDataItem: React.FunctionComponent<IProps> = ({ title, value }) => (
  <div className={styles.item}>
    <div className={styles.value}>{value}</div>
    <p className={styles.title}>{title}</p>
  </div>
)

export default MetaDataItem
