import * as React from 'react'

import styles from './TableHeader.module.scss'

const TableHeader = () => (
  <div className={styles.header}>
    <div className={styles.cell}>
      <p className={styles.text}>ID</p>
    </div>
    <div className={styles.cell}>
      <p className={styles.text}>Date</p>
    </div>
    <div className={`${styles.cell} ${styles.cellMobile}`}>
      <p className={styles.text}>Meals Plan</p>
    </div>
    <div className={`${styles.cell} ${styles.cellMobile}`}>
      <p className={styles.text}>Meals</p>
    </div>
    <div className={`${styles.cell} ${styles.cellMobile}`}>
      <p className={styles.text}>Add-ons</p>
    </div>
    <div className={styles.cell}>
      <p className={styles.text}>Total</p>
    </div>
  </div>
)

export default TableHeader
