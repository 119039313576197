import classNames from 'classnames'

import { STEPS } from '../../../Utils/constants'

import styles from './StepDivider.module.scss'

export const addonsClass = (step: number) =>
  classNames(styles.circle, {
    [styles.circleActive]: step === STEPS.SELECT_ADDONS,
    [styles.circleDone]: step > STEPS.SELECT_ADDONS
  })

export const addonsTitleClass = (step: number) =>
  classNames(styles.title, {
    [styles.titleActive]:
      step === STEPS.SELECT_ADDONS || step === STEPS.SUMMARY || step === STEPS.PAYMENT || step === STEPS.SHIPPING
  })

export const summaryClass = (step: number) =>
  classNames(styles.circle, {
    [styles.circleActive]: step === STEPS.SUMMARY || step === STEPS.PAYMENT || step === STEPS.SHIPPING
  })

export const summaryTitleClass = (step: number) =>
  classNames(styles.title, {
    [styles.titleActive]: step === STEPS.SUMMARY || step === STEPS.PAYMENT || step === STEPS.SHIPPING
  })

export const detailsClass = (step: number) =>
  classNames(styles.circle, {
    [styles.circleActive]: step === STEPS.PAYMENT || step === STEPS.SHIPPING
  })

export const detailsTitleClass = (step: number) =>
  classNames(styles.title, {
    [styles.titleActive]: step === STEPS.PAYMENT || step === STEPS.SHIPPING
  })
