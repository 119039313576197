import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ROUTES } from './Utils/routes'
import ReactDOM from 'react-dom'
import MainContainer from './Containers/MainContainer/MainContainer'
import configureStore from './Redux'
import { StripeProvider } from 'react-stripe-elements'
import moment from 'moment-timezone'
import CovidModal from './Components/Modals/CovidModal/CovidModal'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
const store = configureStore()
moment.tz.setDefault('America/Chicago')

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={ROUTES.MAIN}>
      <StripeProvider apiKey={STRIPE_KEY as string}>
        <Switch>
          <MainContainer />
        </Switch>
      </StripeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
