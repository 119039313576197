import React from 'react'
import { MainContext } from '../Containers/MainContainer/MainContainer'
import { Subtract, RecursivePartial } from '../Utils/helpers'
import { IUserData } from '../Utils/types'

export interface IWithConsumerProps {
  isLoading: boolean
  error: {
    state: boolean
    msg: string
  }
  updateUser: (data: RecursivePartial<IUserData['user']>, token: string) => void
  openModal: (type: 'login' | 'register' | 'resendActivation', isShippingLogin?: boolean) => void
}

const withMainConsumer = <P extends IWithConsumerProps>(Component: React.ComponentType<P>) =>
  class WithConsumer extends React.Component<Subtract<P, IWithConsumerProps>> {
    render() {
      return (
        <MainContext.Consumer>
          {(props: IWithConsumerProps) => <Component {...this.props as P} {...props} />}
        </MainContext.Consumer>
      )
    }
  }

export default withMainConsumer
