import * as React from 'react'
import { Moment } from 'moment'
import Checkbox from '../../../Components/FormElements/Checkbox/Checkbox'

import { IProps } from './types'
import styles from './DeliveryDatePicker.module.scss'

class DeliveryDatePicker extends React.Component<IProps> {
  checkboxFirst = React.createRef<Checkbox>()
  checkboxSecond = React.createRef<Checkbox>()

  render() {
    const { deliveryDates, handleSelectDate, selectedDate } = this.props

    return (
      <div className={styles.container}>
        <h3 className={styles.h3}>Delivery date</h3>
        {deliveryDates.length ? (
          <>
            <Checkbox
              id={deliveryDates[0].format('x')}
              label={deliveryDates[0].format('MMMM DD, YYYY')}
              checked={+deliveryDates[0].format('x') === selectedDate}
              onClick={(id) => {
                handleSelectDate(+id)
              }}
            />
            <Checkbox
              containerClassName={styles.checkbox}
              id={deliveryDates[1].format('x')}
              label={deliveryDates[1].format('MMMM DD, YYYY')}
              checked={+deliveryDates[1].format('x') === selectedDate}
              ref={this.checkboxSecond}
              onClick={(id) => {
                handleSelectDate(+id)
              }}
            />
          </>
        ) : null}
      </div>
    )
  }
}

export default DeliveryDatePicker
