import * as React from 'react'

import { IProps, TPropsKeys } from './types'

import { INPUTS_VALIDATION } from '../../../Utils/constants'
import Button from '../../FormElements/Button/Button'
import Input from '../../FormElements/Input/Input'

import { getUserToken } from '../../../Utils/helpers'
import { MainContext } from '../../../Containers/MainContainer/MainContainer'
import styles from './ShippingForm.module.scss'

class ShippingForm extends React.Component<IProps> {
  zip = React.createRef<Input>()
  city = React.createRef<Input>()
  name = React.createRef<Input>()
  email = React.createRef<Input>()
  phone = React.createRef<Input>()
  address = React.createRef<Input>()
  apartment = React.createRef<Input>()

  componentDidMount() {
    const { isAddressShowed } = this.props
    const token = getUserToken()

    this.handleValidationCheck(
      !isAddressShowed && !token ? ['name', 'email', 'phone'] : (Object.keys(this.props) as TPropsKeys[])
    )
  }

  handleValidationCheck = (items: TPropsKeys[]) => {
    items.map((item: TPropsKeys) => {
      if (this[item] && this.props[item]) {
        this.props[item]!.length !== 0 && this[item].current! && this[item].current!.handleValidate()
      }
    })
  }

  render() {
    const { zip, name, city, phone, email, address, onChange, apartment, isAddressShowed, packageType } = this.props
    const token = getUserToken()

    return (
      <MainContext.Consumer>
        {({ openModal }) => (
          <div className={styles.container}>
            {!token ? (
              <div>
                <h3 className={styles.h3}>Your Contact information</h3>
                <div className={styles.contentContact}>
                  <div className={styles.flexContact}>
                    <Input
                      id="email"
                      label="email"
                      value={email!}
                      ref={this.email}
                      onChange={onChange}
                      className={styles.inputContact}
                      placeholder="Enter your email"
                      hint={INPUTS_VALIDATION.EMAIL.HINT}
                      onEnter={() => this.name.current!.handleFocus()}
                      validate={INPUTS_VALIDATION.EMAIL.VALIDATION}
                      maxLength={INPUTS_VALIDATION.EMAIL.MAX_LENGTH}
                    />
                    <Input
                      id="name"
                      value={name!}
                      ref={this.name}
                      label="full name"
                      onChange={onChange}
                      className={styles.inputContact}
                      placeholder="Enter your full name"
                      hint={
                        name!.length === 0 ? INPUTS_VALIDATION.FULL_NAME.HINT : INPUTS_VALIDATION.FULL_NAME.HINTVALID
                      }
                      onEnter={() => this.phone.current!.handleFocus()}
                      validate={INPUTS_VALIDATION.FULL_NAME.VALIDATION}
                      maxLength={INPUTS_VALIDATION.FULL_NAME.MAX_LENGTH}
                    />
                  </div>
                  <div className={styles.bottomContact}>
                    <Input
                      id="phone"
                      type="phone"
                      value={phone!}
                      ref={this.phone}
                      label="phone number"
                      className={styles.inputContact}
                      placeholder="+ 1 - XXX - XXX - XXXX"
                      hint={INPUTS_VALIDATION.PHONE_NUMBER.HINT}
                      onEnter={() => this.address.current!.handleFocus()}
                      validate={INPUTS_VALIDATION.PHONE_NUMBER.VALIDATION}
                      onChange={(id, value) => {
                        const MASKED_PHONE = INPUTS_VALIDATION.PHONE_NUMBER.MASK
                        const masked = MASKED_PHONE.resolve(value)
                        onChange(id, masked)
                      }}
                    />
                    {!token && !packageType ? (
                      <p className={styles.infoContact}>
                        Create a
                        <Button
                          className={styles.buttonContact}
                          type="text"
                          onClick={() => openModal('register', true)}
                          placeholder="new account"
                        />
                        or
                        <Button
                          className={styles.buttonContact}
                          type="text"
                          onClick={() => openModal('login', true)}
                          placeholder="Log In"
                        />
                      </p>
                    ) : !token && packageType ? (
                      <p className={styles.infoContact}>
                        <Button
                          className={styles.buttonContact}
                          type="text"
                          onClick={() => openModal('login', true)}
                          placeholder="Login"
                        />
                        |
                        <Button
                          className={styles.buttonContact}
                          type="text"
                          onClick={() => openModal('register', true)}
                          placeholder="Create new account"
                        />
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            {isAddressShowed && (
              <div>
                <h3 className={styles.h3}>Your Shipping information:</h3>
                <div className={styles.content}>
                  <div className={styles.flex}>
                    <Input
                      id="address"
                      label="address"
                      value={address}
                      ref={this.address}
                      onChange={onChange}
                      className={styles.inputLong}
                      placeholder="434 McCullough Skyway"
                      hint={INPUTS_VALIDATION.ADDRESS.HINT}
                      validate={INPUTS_VALIDATION.ADDRESS.VALIDATION}
                      maxLength={INPUTS_VALIDATION.ADDRESS.MAX_LENGTH}
                      onEnter={() => this.apartment.current!.handleFocus()}
                    />
                    <Input
                      ref={this.apartment}
                      id="apartment"
                      label="apartment"
                      placeholder="324"
                      value={apartment}
                      className={styles.inputShort}
                      hint={INPUTS_VALIDATION.APARTMENT.HINT}
                      onEnter={() => this.city.current!.handleFocus()}
                      validate={INPUTS_VALIDATION.APARTMENT.VALIDATION}
                      maxLength={INPUTS_VALIDATION.APARTMENT.MAX_LENGTH}
                      onChange={(id, value) => {
                        const MASKED_APARTMENT = INPUTS_VALIDATION.APARTMENT.MASK
                        const masked = MASKED_APARTMENT.resolve(value)
                        onChange(id, masked)
                      }}
                    />
                  </div>
                  <div className={styles.flex}>
                    <Input
                      ref={this.city}
                      id="city"
                      label="city"
                      value={city}
                      onChange={onChange}
                      placeholder="Felipeburgh"
                      className={styles.inputLong}
                      hint={INPUTS_VALIDATION.CITY.HINT}
                      onEnter={() => this.zip.current!.handleFocus()}
                      validate={INPUTS_VALIDATION.CITY.VALIDATION}
                      maxLength={INPUTS_VALIDATION.CITY.MAX_LENGTH}
                    />
                    <Input
                      ref={this.zip}
                      id="zip"
                      value={zip}
                      label="zip code"
                      placeholder="70122"
                      className={styles.inputShort}
                      hint={INPUTS_VALIDATION.ZIP.HINT}
                      validate={INPUTS_VALIDATION.ZIP.VALIDATION}
                      maxLength={INPUTS_VALIDATION.ZIP.MAX_LENGTH}
                      onEnter={() => this.zip.current!.handleBlur()}
                      onChange={(id, value) => {
                        const MASKED_ZIP = INPUTS_VALIDATION.ZIP.MASK
                        const masked = MASKED_ZIP.resolve(value)
                        onChange(id, masked)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </MainContext.Consumer>
    )
  }
}

export default ShippingForm
