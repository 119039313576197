import * as React from 'react'
import { connect } from 'react-redux'

import ShippingContainerView from './View/ShippingContainerView'

import { INPUTS_VALIDATION } from '../../../Utils/constants'
import { updateUser } from '../../../Utils/api'
import { IReduxStore, IUserData } from '../../../Utils/types'
import { selectUserData, updateUser as updateReduxUser } from '../../../Redux/Services/UserService/UserService'
import { IState, IProps } from './types'
import { getUserToken, showError, showSucces } from '../../../Utils/helpers'
import { AxiosResponse, AxiosError } from 'axios'

class ShippingContainer extends React.Component<IProps, IState> {
  readonly state: IState = {
    isEdited: false,
    hasShipping: true,
    inputs: {
      address: '',
      apartment: 0,
      city: '',
      zipCode: ''
    }
  }

  componentDidMount() {
    const { userData } = this.props
    if (userData.shipping) {
      const { address, apartment, city, zipCode } = userData.shipping
      this.setState({ inputs: { address, apartment, city, zipCode } })
    } else {
      this.setState({ isEdited: true, hasShipping: false })
    }
  }

  handleToggleEdited = () => {
    this.setState((prevState: IState) => ({ isEdited: !prevState.isEdited }))
  }

  handleChange = (id: string, value: string) => {
    this.setState((state: IState) => ({
      inputs: {
        ...state.inputs,
        [id]: value
      }
    }))
  }

  handleValidateInputs = () => {
    const {
      inputs: { address, apartment, city, zipCode }
    } = this.state
    const { ADDRESS, APARTMENT, CITY, ZIP } = INPUTS_VALIDATION

    return (
      ADDRESS.VALIDATION(address) &&
      APARTMENT.VALIDATION(`${apartment}`) &&
      CITY.VALIDATION(city) &&
      ZIP.VALIDATION(zipCode)
    )
  }

  handleSubmit = () => {
    const { inputs } = this.state
    const token = getUserToken()

    if (token) {
      updateUser({ shipping: inputs }, token)
        .then(({ data }: AxiosResponse<IUserData['user']>) => {
          this.setState({ isEdited: false })
          this.props.updateReduxUser(data)
          showSucces('User updated successfully!')
        })
        .catch((err: AxiosError) => {
          showError(err)
        })
    }
  }

  render() {
    const { isEdited, inputs, hasShipping } = this.state
    const isFormValidated = this.handleValidateInputs()

    return (
      <ShippingContainerView
        isEdited={isEdited}
        hasShipping={hasShipping}
        toggleEdited={this.handleToggleEdited}
        shippingData={inputs}
        isFormValidated={isFormValidated}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  userData: selectUserData(state)
})

export default connect(
  mapStateToProps,
  { updateReduxUser }
)(ShippingContainer)
