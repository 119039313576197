import Modal from '../../../../../Components/Logical/Modal/Modal'

export interface IProps {
  modal: React.RefObject<Modal>
  loading: boolean
  onSubmit: () => void
}

export interface IState {
  type: ModalTypes
}

export enum ModalTypes {
  CANCEL,
  CANCEL_YES,
  CANCEL_NO
}
