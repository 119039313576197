import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { ReactComponent as SuccessIcon } from '../../../assets/svg/success.svg'
import Button from '../../../Components/FormElements/Button/Button'

import { ROUTES } from '../../../Utils/routes'
import styles from './SuccessContainer.module.scss'

const SuccessContainer: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <h1>Success</h1>
        <div className={styles.icon}>
          <SuccessIcon />
        </div>
        <div className={styles.text}>
          Thank you for your order. Deliveries happen between <strong>6-9PM</strong> on{' '}
          <strong>Wednesday and Sunday</strong>.
        </div>
      </div>
      <div className={`${styles.box} ${styles.boxSmall}`}>
        <div className={styles.text}>If you have any questions or feedback about your order please email</div>
        <a className={styles.link} href="mailto:orders@elevatedchefs.co">
          orders@elevatedchefs.co
        </a>
      </div>

      <Button onClick={() => history.push(ROUTES.MAIN)} className={styles.button} type="text">
        Back to Main page
      </Button>
    </div>
  )
}

export default SuccessContainer
