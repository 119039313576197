import * as React from 'react'

import { IProps } from './types'

import Testimonials from './components/Testimonials/Testimonials'
import About from './components/About/About'
import Plans from './components/Plans/Plans'
import Food from './components/Food/Food'
import Hero from './components/Hero/Hero'
import FAQ from './components/FAQ/FAQ'

import styles from './LandingView.module.scss'

const LandingView = ({ isProceedDisabled, handleSingleOrder, setSubscriptionType }: IProps) => (
  <div className={styles.container}>
    <Hero />
    <Plans setSubscriptionType={setSubscriptionType} />
    <Food handleSingleOrder={handleSingleOrder} isProceedDisabled={isProceedDisabled} />
    <About />
    <Testimonials />
    <FAQ />
  </div>
)

export default LandingView
