import { INPUTS_VALIDATION } from './forms'
const zipCodes = [
  60611,
  60605,
  60610,
  60601,
  60602,
  60603,
  60604,
  60605,
  60606,
  60616,
  60642,
  60654,
  60661,
  60622,
  60612,
  60614,
  60607,
  60069
]

export const validateEmail = (email: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/.test(
    email
  )

export const validatePhone = (phone: string): boolean => {
  const unmasked = phone
    .slice(3)
    .split(' ')
    .join('')
    .split('-')
    .join('')

  return unmasked.length >= 6
}

export const validateZipWithAnvalibleCodes = (zip: string): boolean => zipCodes.includes(+zip)
export const validateZip = (zip: string): boolean => zip.length === 5

export const validateNotEmpty = (value: string): boolean => value.length > 0

export const validatePassword = (value: string): boolean =>
  /^(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-](?=.*[0-9])(?=.*[a-z]).{7,}$/.test(value)

export const validateNewPassword = (value: string): boolean =>
  /^(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-](?=.*[0-9])(?=.*[a-z]).{7,}$/.test(value)

export const validateName = (value: string): boolean => /^[a-zA-Z\s]{3,30}$/.test(value)
