import { saveItem, removeItem, getItem } from '../../../../Helpers/localStorage'
import { LOCAL_STORAGE_ITEMS } from '../../../../Utils/constants'
import { IState } from '../types'

export const deleteFromLocalStorage = (item?: string) => {
  removeItem(item ? item : LOCAL_STORAGE_ITEMS.ORDER)
}

export const getOrderFromLocalStorage = () => {
  const item = getItem(LOCAL_STORAGE_ITEMS.ORDER)
  return item
}

export const saveInLocalStorage = (state: IState) => {
  saveItem(LOCAL_STORAGE_ITEMS.ORDER, state)
}
