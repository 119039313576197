import React from 'react'
import Modal from '../../../../../Components/Logical/Modal/Modal'
import Button from '../../../../../Components/FormElements/Button/Button'
import styles from './CancelModal.module.scss'
import { IProps, IState, ModalTypes } from './types'

class CancelModal extends React.Component<IProps, IState> {
  readonly state: IState = {
    type: ModalTypes.CANCEL
  }

  handleChangeModalType = (type: ModalTypes) => {
    this.setState({ type }, () => {
      if (this.state.type === ModalTypes.CANCEL_YES) {
        this.props.onSubmit()
      }
    })
  }

  handleClose = () => {
    this.props.modal.current!.handleClose()
    this.setState({ type: ModalTypes.CANCEL })
  }

  render() {
    const { modal, loading } = this.props
    const { type } = this.state

    return (
      <Modal className={styles.container} ref={modal}>
        <div className={styles.text}>
          {type === ModalTypes.CANCEL_YES
            ? 'All future orders are cancelled. Please visit your account page if you would like to place a new order.'
            : type === ModalTypes.CANCEL_NO
            ? 'Your meal delivery subscription will remain active. Thank you!'
            : 'Are you sure you would like to cancel your future food delivery orders?'}
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={() =>
              type !== ModalTypes.CANCEL ? this.handleClose() : this.handleChangeModalType(ModalTypes.CANCEL_NO)
            }
            className={styles.buttonText}
            type="text"
          >
            {type === ModalTypes.CANCEL ? 'No' : 'Cancel'}
          </Button>
          {type === ModalTypes.CANCEL && (
            <Button
              loading={loading}
              onClick={() => this.handleChangeModalType(ModalTypes.CANCEL_YES)}
              className={styles.button}
              type="green"
            >
              Yes
            </Button>
          )}
        </div>
      </Modal>
    )
  }
}
export default CancelModal
