import * as React from 'react'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { ORDER_TYPE, validateEmail, validateZip, validatePhone } from '../../../Utils/constants'
import { IReduxStore } from '../../../Utils/types'
import { ROUTES } from '../../../Utils/routes'
import { getUserToken } from '../../../Utils/helpers'
import withMainConsumer, { IWithConsumerProps } from '../../../HOC/withMainConsumer'
import { IProps, IState } from './types'
import {
  getShipping,
  getOrderType,
  getTotalMeals,
  getPackageInfo,
  getPackageType
} from '../../../Redux/Services/OrderService/helpers'

import { ReactComponent as Arrow } from '../../../assets/svg/arrowRight.svg'
import Button from '../../FormElements/Button/Button'

import styles from './BottomBar.module.scss'

class BottomBar extends React.Component<IWithConsumerProps & IProps, IState> {
  private input = React.createRef<HTMLDivElement>()
  readonly state: IState = {
    isBtnDisabled: false,
    isFixed: true
  }

  componentDidMount() {
    this.handleValidButton()

    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.setState({ isFixed: false })
      } else {
        this.setState({ isFixed: true })
      }
    })
  }

  componentDidUpdate() {
    this.handleValidButton()
  }

  validateShippingButton = () => {
    const token = getUserToken()
    const { shipping, renderCondition, isShippingAddressShowed } = this.props

    return token
      ? !(
          this.handleFillCheck([shipping.address, shipping.apartment, shipping.city]) &&
          validateZip(shipping.zip) &&
          renderCondition &&
          token
        )
      : isShippingAddressShowed
      ? !(
          this.handleFillCheck([shipping.address, shipping.apartment, shipping.city, shipping.name]) &&
          validatePhone(shipping.phone) &&
          validateEmail(shipping.email) &&
          validateZip(shipping.zip) &&
          renderCondition
        )
      : !(
          this.handleFillCheck([shipping.name]) &&
          validatePhone(shipping.phone) &&
          validateEmail(shipping.email) &&
          renderCondition
        )
  }

  handleValidButton = () => {
    const { isCardFormValid, orderType, totalMeals, packageType } = this.props
    const { isBtnDisabled } = this.state
    const token = getUserToken()

    switch (window.location.pathname) {
      case ROUTES.SHIPPING:
        const isValid = this.validateShippingButton()
        if (orderType === ORDER_TYPE.SUBSCRIPTION && !token) {
          if (isBtnDisabled !== true) {
            this.setState({ isBtnDisabled: true })
          }
        } else if (isBtnDisabled !== isValid) {
          this.setState({ isBtnDisabled: isValid })
        }
        break
      case ROUTES.DISHES:
        if (orderType === ORDER_TYPE.SUBSCRIPTION && totalMeals !== packageType) {
          if (!isBtnDisabled) {
            this.setState({ isBtnDisabled: true })
          }
        } else if (orderType === ORDER_TYPE.SUBSCRIPTION && totalMeals === packageType) {
          if (isBtnDisabled) {
            this.setState({ isBtnDisabled: false })
          }
        }
        break
      case ROUTES.PAYMENT:
        if (isBtnDisabled !== !isCardFormValid) {
          this.setState({ isBtnDisabled: !isCardFormValid })
        }
        break
      default:
        if (isBtnDisabled === true) {
          this.setState({ isBtnDisabled: false })
        }
    }
  }

  handleFillCheck = (items: string[]): boolean => {
    return items.indexOf('') === -1
  }

  render() {
    const { prevStep, nextStep, orderType, totalMeals, packageInfo, packageType, nextStepName, loading } = this.props
    const { isBtnDisabled } = this.state

    return (
      <div className={styles.container} ref={this.input}>
        <div className={styles.infoContainer}>
          {orderType === ORDER_TYPE.SINGLE ? (
            <p className={styles.infoText}>One Time Order</p>
          ) : (
            <>
              <div className={styles.infoBox}>
                <p className={styles.infoText}>Meals left: {packageType ? packageType - totalMeals : 0}</p>
              </div>
              <div className={styles.infoBox}>
                <p className={styles.infoText}>Your selection: {packageType} meals</p>
              </div>
              <div className={styles.link}>
                <Link to={ROUTES.MAIN} className={styles.link}>
                  <p className={styles.changeText}>Change pack?</p>
                </Link>
              </div>
            </>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.marginModifier} type="transparent" onClick={prevStep}>
            <p>Back</p>
          </Button>
          <Button
            loading={loading}
            className={styles.buttonNext}
            type="green"
            onClick={nextStep}
            disabled={isBtnDisabled || loading}
          >
            <>
              <p>{nextStepName}</p>
              <div className={isBtnDisabled ? styles.buttonIconDisabled : styles.buttonIcon}>
                <Arrow />
              </div>
            </>
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  shipping: getShipping(state),
  orderType: getOrderType(state),
  totalMeals: getTotalMeals(state),
  packageInfo: getPackageInfo(state),
  packageType: getPackageType(state)
})

export default connect(mapStateToProps)(withMainConsumer(BottomBar))
