import * as React from 'react'

import LoginBG from '../../../assets/img/login.jpg'
import { TEXTS } from '../../../Utils/constants'
import { IProps, IState } from './types'

import Button from '../../FormElements/Button/Button'
import Input from '../../FormElements/Input/Input'
import Modal from '../../Logical/Modal/Modal'

import { MainContext } from '../../../Containers/MainContainer/MainContainer'
import { validateEmail, validatePassword } from '../../../Utils/constants/validation'
import styles from './LoginModal.module.scss'

class LoginModal extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    email: '',
    password: ''
  }
  handleChange = (id: string, value: string) => {
    this.setState({ [id]: value } as Pick<IState, keyof IState>)
  }

  handleSubmit = () => {
    const { email, password } = this.state
    this.props.onSubmit!({ email: email.toLowerCase(), password })
  }

  render() {
    const { email, password } = this.state
    const { switchModal } = this.props

    return (
      <Modal className={styles.container} ref={this.props.modal}>
        <MainContext.Consumer>
          {({ isLoading, error }) => (
            <>
              <div className={styles.leftSide}>
                <img className={styles.background} src={LoginBG} />
                <h3 className={styles.slogan}>{TEXTS.LOGIN_POPUP.SLOGAN}</h3>
              </div>
              <div className={styles.rightSide}>
                <h2 className={styles.heading}>Sign in</h2>
                <Input
                  className={styles.input}
                  id="email"
                  value={email}
                  label="Email"
                  onChange={this.handleChange}
                  validate={validateEmail}
                  hint="Email address is invalid"
                />

                <Input
                  id="password"
                  type="password"
                  value={password}
                  label="Password"
                  className={styles.input}
                  onChange={this.handleChange}
                  validate={validatePassword}
                  hint="Password is invalid"
                />
                <div className={styles.actionsContainer}>
                  <Button
                    className={styles.loginButton}
                    loading={isLoading}
                    type="green"
                    placeholder="sign in"
                    onClick={this.handleSubmit}
                    disabled={!validateEmail(email) || !validatePassword(password)}
                  />

                  <div>
                    <div className={styles.infoBox}>
                      <p className={styles.info}>I don't have an account.</p>
                      <Button
                        className={styles.registerButton}
                        type="text"
                        placeholder="Register"
                        loading={isLoading}
                        onClick={() => switchModal('register')}
                      />
                    </div>
                    <div className={styles.infoBox}>
                      <p className={styles.info}>Resend activation link, </p>
                      <Button
                        className={styles.registerButton}
                        type="text"
                        placeholder="Resend"
                        onClick={() => switchModal('resendActivation')}
                      />
                    </div>
                    {error.state ? (
                      <div className={styles.infoBox}>
                        <p className={`${styles.info} ${styles.infoRed}`}>{error.msg}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </MainContext.Consumer>
      </Modal>
    )
  }
}

export default LoginModal
