import React from 'react'

import Input from '../../../../../Components/FormElements/Input/Input'

import { INPUTS_VALIDATION } from '../../../../../Utils/constants'
import styles from './ShipingForm.module.scss'
import { IProps } from './types'

const ShipingForm: React.FunctionComponent<IProps> = ({ data, onChange }) => {
  return (
    <>
      <div className={styles.flex}>
        <Input
          id="address"
          label="address"
          value={data.address}
          onChange={onChange}
          className={styles.inputLong}
          placeholder="434 McCullough Skyway"
          hint={INPUTS_VALIDATION.ADDRESS.HINT}
          validate={INPUTS_VALIDATION.ADDRESS.VALIDATION}
          maxLength={INPUTS_VALIDATION.ADDRESS.MAX_LENGTH}
        />
        <Input
          id="apartment"
          label="apartment"
          placeholder="324"
          value={`${data.apartment}`}
          className={styles.inputShort}
          hint={INPUTS_VALIDATION.APARTMENT.HINT}
          validate={INPUTS_VALIDATION.APARTMENT.VALIDATION}
          maxLength={INPUTS_VALIDATION.APARTMENT.MAX_LENGTH}
          onChange={(id, value) => {
            const MASKED_APARTMENT = INPUTS_VALIDATION.APARTMENT.MASK
            const masked = MASKED_APARTMENT.resolve(value)
            onChange(id, masked)
          }}
        />
      </div>
      <div className={styles.flex}>
        <Input
          id="city"
          label="city"
          value={data.city}
          onChange={onChange}
          placeholder="Felipeburgh"
          className={styles.inputLong}
          hint={INPUTS_VALIDATION.CITY.HINT}
          validate={INPUTS_VALIDATION.CITY.VALIDATION}
          maxLength={INPUTS_VALIDATION.CITY.MAX_LENGTH}
        />
        <Input
          id="zipCode"
          value={data.zipCode}
          label="zip code"
          placeholder="70122"
          className={styles.inputShort}
          hint={INPUTS_VALIDATION.ZIP.HINT}
          validate={INPUTS_VALIDATION.ZIP.VALIDATION}
          maxLength={INPUTS_VALIDATION.ZIP.MAX_LENGTH}
          onChange={(id, value) => {
            const MASKED_ZIP = INPUTS_VALIDATION.ZIP.MASK
            const masked = MASKED_ZIP.resolve(value)
            onChange(id, masked)
          }}
        />
      </div>
    </>
  )
}

export default ShipingForm
