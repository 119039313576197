import { initialState, initialStateData } from './constants'
import { IUserData, IActiveOrder } from '../../../Utils/types/requests'
import { deleteFromLocalStorage, saveInLocalStorage } from './helpers'
import { IReduxStore } from '../../../Utils/types/redux'
import { IAction, IUserService } from './types'
import { ICardInfo } from '../../../Utils/types'

const USER_LOGIN = 'USER_LOGIN'
const USER_UPDATE = 'USER_UPDATE'
const USER_LOGOUT = 'USER_LOGOUT'
const USER_CHANGE_CARD = 'USER_CHANGE_CARD'
const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER'

export default (state = initialState, action: IAction): IUserService => {
  switch (action.type) {
    case USER_LOGIN:
      saveInLocalStorage({ ...state, user: action.user })
      return { ...state, user: action.user }
    case USER_UPDATE:
      deleteFromLocalStorage()
      saveInLocalStorage({ ...state, user: action.user })
      return { ...state, user: action.user }
    case USER_LOGOUT:
      deleteFromLocalStorage()
      deleteFromLocalStorage('token')
      return initialStateData
    case SET_ACTIVE_ORDER:
      deleteFromLocalStorage()
      saveInLocalStorage({ ...state, activeOrder: action.activeOrder })
      return { ...state, activeOrder: action.activeOrder }
    case USER_CHANGE_CARD:
      deleteFromLocalStorage()
      saveInLocalStorage({ ...state, user: { ...state.user, creditCard: action.cardData } })
      return { ...state, user: { ...state.user, creditCard: action.cardData } }
    default:
      return state
  }
}

export const userLogin = (userData: IUserData['user']) => ({
  type: USER_LOGIN,
  user: userData
})

export const updateUserCard = (cardData: ICardInfo) => ({
  type: USER_CHANGE_CARD,
  cardData
})

export const userLogout = () => ({
  type: USER_LOGOUT
})

export const updateUser = (userData: IUserData['user']) => ({
  type: USER_UPDATE,
  user: userData
})

export const setActiveOrder = (activeOrder: IActiveOrder) => ({
  type: SET_ACTIVE_ORDER,
  activeOrder
})

export const selectUserData = (state: IReduxStore) => state.user.user
export const selectActiveOrder = (state: IReduxStore) => state.user.activeOrder
export const selectUserDeliveryDate = (state: IReduxStore) => state.user.activeOrder.deliveryDate
export const selectDeliveryData = (state: IReduxStore) => state.user.user.deliveryDate
export const selectIsSubscription = (state: IReduxStore) => state.user.activeOrder._id.length > 0
