import * as React from 'react'
import { injectStripe, ReactStripeElements } from 'react-stripe-elements'
import { AxiosResponse, AxiosError } from 'axios'
import { connect } from 'react-redux'

import BillingContainerView from './View/BillingContainerView'

import { getUserToken, showError, showSucces } from '../../../Utils/helpers'
import { addCard } from '../../../Utils/api'
import { IReduxStore, ICardInfo } from '../../../Utils/types'
import { updateUserCard, selectUserData } from '../../../Redux/Services/UserService/UserService'
import { IProps, IElement, IState } from './Types'

class BillingContainer extends React.Component<ReactStripeElements.InjectedStripeProps & IProps, IState> {
  readonly state: IState = {
    isLoading: false,
    isEdit: !this.props.userData.creditCard ? true : false
  }

  private cardName: IElement | null = null
  private cardCVV: IElement | null = null
  private cardExpire: IElement | null = null

  handleSubmit = async () => {
    const userToken = getUserToken()
    const { token } = await this.props.stripe!.createToken()

    if (userToken && token && this.handleFormValid()) {
      this.setState({ isLoading: true })
      addCard(token.id, userToken)
        .then(({ data }: AxiosResponse<ICardInfo>) => {
          this.props.updateUserCard(data)
          this.handleClearForm()
          this.setState({ isLoading: false })
          this.handleToggleEdit()
          showSucces('Card updated successfully!')
        })
        .catch((err: AxiosError) => {
          this.setState({ isLoading: false })
          showError(err)
        })
    }
  }

  handleToggleEdit = () => {
    this.setState((state: IState) => ({ isEdit: !state.isEdit }))
  }

  handleFormValid = () => !this.cardName!._invalid && !this.cardCVV!._invalid && !this.cardExpire!._invalid

  handleClearForm = () => {
    this.cardName!.clear()
    this.cardCVV!.clear()
    this.cardExpire!.clear()
  }

  setRef = (element: IElement, type: string) => {
    if (type === 'name') {
      this.cardName = element
    } else if (type === 'cvv') {
      this.cardCVV = element
    } else {
      this.cardExpire = element
    }
  }

  render() {
    const { userData } = this.props
    const { isEdit, isLoading } = this.state

    return (
      <BillingContainerView
        isLoading={isLoading}
        isEdit={isEdit}
        handleSetRef={this.setRef}
        handleToggleEdit={this.handleToggleEdit}
        creditCardData={userData.creditCard as ICardInfo}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  userData: selectUserData(state)
})

export default injectStripe(
  connect(
    mapStateToProps,
    { updateUserCard }
  )(BillingContainer)
)
