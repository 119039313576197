import * as React from 'react'

import { TEXTS } from '../../../..//Utils/constants'

import Button from '../../../../Components/FormElements/Button/Button'
import Image from '../../../../assets/img/heroImg.png'

import styles from './Hero.module.scss'

const Hero = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <h1 className={styles.h1}>
        Less Time - <br /> More life
      </h1>
      <p className={styles.p}>{TEXTS.LANDING.HERO_DESC}</p>
      <div className={styles.buttonContainer}>
        <a href="#food">
          <Button className={styles.buttonGreen} type="green" placeholder="view meals" />
        </a>
        <a href="#about">
          <Button className={styles.buttonWhite} type="text" placeholder="how it works?" />
        </a>
      </div>
    </div>
  </div>
)

export default Hero
