import * as React from 'react'
import { IItem, IMeal } from '../../Types'

import { Link } from 'react-router-dom'

import styles from './OrderDetails.module.scss'

import { ReactComponent as Arrow } from '../../../../../assets/svg/arrowLeft.svg'
import { formatDate } from '../../../../../Utils/helpers'
import { ROUTES } from '../../../../../Utils/routes'
import { IAddon } from '../../../../../Utils/types'

interface IProps {
  item: IItem
}

const OrderDetails: React.FunctionComponent<IProps> = ({ item }) => {
  return (
    <div className={styles.container}>
      <Link to={ROUTES.ACCOUNT_HISTORY} className={styles.back}>
        <Arrow className={styles.icon} />
        Back to list
      </Link>
      <div className={styles.table}>
        <div className={styles.tableLeft}>
          <div className={styles.header}>
            <p className={styles.headerText}>{`Order ID: ${item._id}`}</p>
            <p className={styles.headerText}>{formatDate(item.createdAt)}</p>
          </div>
          <div className={styles.products}>
            <h3 className={styles.h3}>Meals</h3>
            {item.meals.map((meal: IMeal) => (
              <p className={styles.p}>{meal.name}</p>
            ))}
            <h3 className={styles.h3}>Addons</h3>
            {item.addons.map((addon: IAddon) => (
              <p className={styles.p}>{addon.name}</p>
            ))}
          </div>
        </div>
        <div className={styles.tableRight}>
          <div className={styles.summaryHeader}>
            <p className={styles.headerText}>Shopping cart</p>
          </div>
          <div className={styles.pricesContainer}>
            <div className={styles.priceBox}>
              <p>Meals:</p>
              <p>${item.meals.reduce((total: number, meal: IMeal) => (total += meal.price), 0)}</p>
            </div>
            <div className={styles.priceBox}>
              <p>Addons:</p>
              <p>${item.addons.reduce((total: number, addon: IAddon) => (total += addon.price), 0)}</p>
            </div>
          </div>
          <div className={styles.totalContainer}>
            <h2 className={styles.h2}>Total:</h2>
            <p className={styles.totalPrice}>${item.totalPrice}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OrderDetails
