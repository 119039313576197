import * as React from 'react'

import { IAddon } from '../../../../../Utils/types'
import { IProps } from './types'

import AddonItem from '../AddonItem/AddonItem'

const AddonList: React.FunctionComponent<IProps> = ({
  type,
  error,
  addons,
  loading,
  addAddon,
  handleInitialValues,
  orderAddons
}) =>
  error || loading || type !== 'addon' ? null : (
    <>
      {addons.map((addon: IAddon) => {
        const amount = handleInitialValues(addon, 'addons')

        return (
          <AddonItem
            key={addon.id}
            addon={addon}
            amount={amount}
            type={type}
            addAddon={addAddon}
            orderAddons={orderAddons}
          />
        )
      })}
    </>
  )

export default AddonList
