import * as React from 'react'

import styles from './TimeBox.module.scss'

interface IProps {
  time: number | string
  withDivider?: boolean
  type: 'days' | 'hours' | 'minutes' | 'seconds'
}

const TimeBox = ({ time, type, withDivider }: IProps) => (
  <>
    <div className={styles.timeContainer}>
      <p className={styles.timeNumber}>{time}</p>
      <p className={styles.timeText}>{type}</p>
    </div>
    {withDivider && <p className={styles.timeDivider}>:</p>}
  </>
)

export default TimeBox
