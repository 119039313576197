export const BUTTON_TYPES = {
  TRANSPARENT: 'transparent',
  GREEN: 'green',
  GREY: 'grey',
  RADIAL: 'radial',
  TEXT: 'text',
  RADIAL_GREY_1: 'radial-grey-1',
  RADIAL_GREEN_1: 'radial-green-1',
  RADIAL_GREEN_2: 'radial-green-2',
  RADIAL_GREEN_3: 'radial-green-3',
  RADIAL_GREEN_4: 'radial-green-4',
  RADIAL_GREEN_5: 'radial-green-5',
  RADIAL_DECREMENT: 'radial-decrement'
}
