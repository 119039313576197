import * as React from 'react'

import { IError } from '../../../Utils/types'

import styles from './ErrorHandler.module.scss'

interface IProps {
  error: IError
}

const ErrorHandler: React.FunctionComponent<IProps> = ({ error }) =>
  !error.state ? null : (
    <div className={styles.container}>
      <p className={styles.p}>{error.msg}</p>
    </div>
  )

export default ErrorHandler
