import * as React from 'react'
import { Moment } from 'moment'
import { RouteComponentProps } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router'
import { Elements } from 'react-stripe-elements'
import { SIDEBAR_NAMES } from '../../../Utils/constants'
import { ROUTES } from '../../../Utils/routes'
import AccountSettingsContainer from '../AccountSettingsContainer/AccountSettingsContainer'
import ContentHeader from '../../../Components/Account/ContentHeader/ContentHeader'
import ActiveOrderContainer from '../ActiveOrderContainer/ActiveOrderContainer'
import SubscribeContainer from '../SubscribeContainer/SubscribeContainer'
import ShippingContainer from '../ShippingContainer/ShippingContainer'
import BillingContainer from '../BillingContainer/BillingContainer'
import HistoryContainer from '../HistoryContainer/HistoryContainer'
import Sidebar from '../../../Components/Account/Sidebar/Sidebar'

import styles from './AccountContainer.module.scss'
import { getUserToken } from '../../../Utils/helpers'
import { getActiveMeals } from '../../../Utils/api'
import { AxiosResponse } from 'axios'
import { IActiveOrder, IMealDetails, IProtein } from '../../../Utils/types'
import { connect } from 'react-redux'
import { setActiveOrder } from '../../../Redux/Services/UserService/UserService'

interface IProps {
  deliveryDates: Moment[]
  isSubscription: boolean
  userData: { name: string; email: string }
  userDeliveryDate: { dayInWeek: number; hour: number; minute: number } | undefined

  setActiveOrder: (activeOrder: IActiveOrder) => void
}

class AccountContainer extends React.Component<RouteComponentProps & IProps> {
  componentDidMount() {
    const token = getUserToken()

    if (token) {
      getActiveMeals(token)
        .then(({ data }: AxiosResponse<IActiveOrder>) => {
          data.meals = data.meals.map((meal: IMealDetails) => {
            return {
              ...meal,
              proteins: meal.proteins
                ? meal.proteins.map((protein: IProtein) => ({ ...protein, price: protein.price * 100 }))
                : undefined
            }
          })

          this.props.setActiveOrder(data)
        })
        .catch((e) => {})
    }
  }

  handleGetHeaderName = (): string => {
    if (this.props.location.pathname.includes('account/history')) {
      return SIDEBAR_NAMES.HISTORY.toUpperCase()
    } else {
      switch (this.props.location.pathname) {
        case ROUTES.ACCOUNT_ACTIVE_ORDER:
          return SIDEBAR_NAMES.MEALS.toUpperCase()
        case ROUTES.ACCOUNT_SHIPPING:
          return SIDEBAR_NAMES.SHIPPING.toUpperCase()
        case ROUTES.ACCOUNT_BILLING:
          return SIDEBAR_NAMES.BILLING.toUpperCase()
        case ROUTES.ACCOUNT_SUBSCRIBE:
          return SIDEBAR_NAMES.SUBSCRIBE.toUpperCase()
        case ROUTES.ACCOUNT_SETTINGS:
          return SIDEBAR_NAMES.SETTINGS.toUpperCase()
        case ROUTES.ACCOUNT_SETTINGS_PASSWORD:
          return SIDEBAR_NAMES.SETTINGS.toUpperCase()
        case ROUTES.ACCOUT_SETTINGS_INFO:
          return SIDEBAR_NAMES.SETTINGS.toUpperCase()
        default:
          return ''
      }
    }
  }

  render() {
    const { deliveryDates, userDeliveryDate, userData, isSubscription } = this.props
    const deliveryDate =
      userDeliveryDate && deliveryDates.length
        ? deliveryDates[0].day() === userDeliveryDate.dayInWeek
          ? deliveryDates[0]
          : deliveryDates[1]
        : null

    return (
      <div className={styles.container}>
        <h2 className={styles.h2}>Your Dashboard</h2>
        <div className={styles.panel}>
          <Sidebar
            isSubscription={isSubscription}
            deliveryDate={deliveryDate}
            email={userData.email}
            name={userData.name}
          >
            {isSubscription && <Sidebar.Item name={SIDEBAR_NAMES.MEALS} path={ROUTES.ACCOUNT_ACTIVE_ORDER} />}
            <Sidebar.Item name={SIDEBAR_NAMES.SHIPPING} path={ROUTES.ACCOUNT_SHIPPING} />
            <Sidebar.Item name={SIDEBAR_NAMES.BILLING} path={ROUTES.ACCOUNT_BILLING} />
            <Sidebar.Item name={SIDEBAR_NAMES.HISTORY} path={ROUTES.ACCOUNT_HISTORY} />
            <Sidebar.Item name={SIDEBAR_NAMES.SUBSCRIBE} path={ROUTES.ACCOUNT_SUBSCRIBE} />
          </Sidebar>
          <div className={styles.content}>
            <ContentHeader
              isSubscription={isSubscription}
              title={this.handleGetHeaderName()}
              deliveryDate={deliveryDate}
            />
            <Elements>
              <Switch>
                <Route exact path={ROUTES.ACCOUNT_ACTIVE_ORDER} component={ActiveOrderContainer} />
                <Route
                  exact
                  path={ROUTES.ACCOUNT_SUBSCRIBE}
                  render={(props) => <SubscribeContainer {...props} deliveryDate={deliveryDate} />}
                />
                <Route exact path={ROUTES.ACCOUNT_SHIPPING} component={ShippingContainer} />
                <Route exact path={ROUTES.ACCOUNT_BILLING} component={BillingContainer} />
                <Route path={ROUTES.ACCOUNT_HISTORY} component={HistoryContainer} />
                <Route path={ROUTES.ACCOUNT_SETTINGS} component={AccountSettingsContainer} />
                <Redirect to={isSubscription ? ROUTES.ACCOUNT_ACTIVE_ORDER : ROUTES.ACCOUNT_SUBSCRIBE} />
              </Switch>
            </Elements>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  { setActiveOrder }
)(AccountContainer)
