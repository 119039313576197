import * as React from 'react'
import { Elements } from 'react-stripe-elements'
import { Route, Switch } from 'react-router'

import { ROUTES } from '../../../Utils/routes'
import { IProps } from './types'

import SuccessContainer from '../../../Containers/Order/SuccessContainer/SuccessContainer'
import ShippingContainer from '../../../Containers/Order/ShippingContainer/ShippingContainer'
import PaymentContainer from '../../../Containers/Order/PaymentContainer/PaymentContainer'
import SummaryContainer from '../../../Containers/Order/SummaryContainer/SummaryContainer'
import AddonsContainer from '../../../Containers/Order/AddonsContainer/AddonsContainer'
import MealsContainer from '../../../Containers/Order/MealsContainer/MealsContainer'
import StepDivider from '../../../Components/Order/StepDivider/StepDivider'
import BottomBar from '../../../Components/Order/BottomBar/BottomBar'
import ConfirmModal from '../../../Components/Modals/ConfirmModal/ConfirmModal'
import ConfirmChangeModal from '../../../Components/Modals/ConfirmChangeModal/ConfirmChangeModal'

import styles from './OrderContainerView.module.scss'
import { STEPS } from '../../../Utils/constants'

const OrderContainerView: React.FunctionComponent<IProps> = ({
  step,
  history,
  prevStep,
  nextStep,
  getCreateToken,
  selectedDate,
  handleSelectDate,
  isEditActiveOrder,
  submitOnConfirm,
  modalRef,
  changeModalRef,
  isShippingAddressShowed,
  isCardFormValid,
  handleFormValid,
  isLoading
}) => (
  <div className={styles.container}>
    {step.activeStep !== STEPS.SUCCESS ? (
      <div className={styles.background}>
        <div className={styles.bgTop} />
        <div className={styles.bgBottom} />
        <StepDivider step={step.activeStep} history={history} />
      </div>
    ) : null}
    <Elements>
      <Switch>
        <Route path={ROUTES.DISHES} component={MealsContainer} />
        <Route path={ROUTES.ADDONS} component={AddonsContainer} />
        <Route path={ROUTES.SUMMARY} component={SummaryContainer} />
        <Route path={ROUTES.SUCCESS} component={SuccessContainer} />
        <Route
          path={ROUTES.PAYMENT}
          render={(props) => (
            <PaymentContainer {...props} getCreateToken={getCreateToken} handleFormValid={handleFormValid} />
          )}
        />
        <Route
          path={ROUTES.SHIPPING}
          render={(props) => (
            <ShippingContainer
              {...props}
              isShippingAddressShowed={isShippingAddressShowed}
              selectedDate={selectedDate}
              handleSelectDate={handleSelectDate}
            />
          )}
        />
      </Switch>
    </Elements>
    {step.activeStep !== STEPS.SUCCESS ? (
      <BottomBar
        isShippingAddressShowed={isShippingAddressShowed}
        isEditActiveOrder={isEditActiveOrder}
        renderCondition={selectedDate !== 0}
        isCardFormValid={isCardFormValid}
        prevStep={prevStep}
        nextStep={nextStep}
        nextStepName={step.nextStep.name}
        loading={isLoading}
      />
    ) : null}

    <ConfirmChangeModal onSubmit={submitOnConfirm} modal={changeModalRef} />
    <ConfirmModal onSubmit={submitOnConfirm} modal={modalRef} />
  </div>
)

export default OrderContainerView
