import { STEPS, TEXTS, ORDER_TYPE } from '../Utils/constants'
import { IStepData, IOrderType } from '../Utils/types'
import { ROUTES } from '../Utils/routes'

export const getSteps = (
  orderType: IOrderType,
  isCreditCardAdded: boolean,
  isEditActiveOrder: boolean,
  isBuyWithConfirm: boolean,
  isNext: boolean
): IStepData => {
  const location = window.location.pathname

  const data: IStepData = {
    activeStep: STEPS.SELECT_DISHES,
    prevStep: ROUTES.MAIN,
    nextStep: {
      path: ROUTES.ADDONS,
      index: STEPS.SELECT_ADDONS,
      name: TEXTS.BOTTOM_BAR_BTN_NAMES.MEALS
    }
  }

  const shippingButtonName = () => {
    if (isEditActiveOrder) {
      return TEXTS.BOTTOM_BAR_BTN_NAMES.UPDATE_ORDER
    } else if (isBuyWithConfirm) {
      return TEXTS.BOTTOM_BAR_BTN_NAMES.CONFIRM
    } else if (isNext) {
      return TEXTS.BOTTOM_BAR_BTN_NAMES.NEXT
    } else if (!isCreditCardAdded) {
      return TEXTS.BOTTOM_BAR_BTN_NAMES.ADD_CARD
    } else {
      return TEXTS.BOTTOM_BAR_BTN_NAMES.ORDER
    }
  }

  switch (location) {
    case ROUTES.DISHES:
      data.prevStep = isEditActiveOrder ? ROUTES.ACCOUNT_ACTIVE_ORDER : ROUTES.MAIN
      data.nextStep.path = ROUTES.ADDONS
      data.activeStep = STEPS.SELECT_DISHES
      data.nextStep.index = STEPS.SELECT_ADDONS
      data.nextStep.name = TEXTS.BOTTOM_BAR_BTN_NAMES.MEALS
      break

    case ROUTES.ADDONS:
      data.nextStep.index = STEPS.SUMMARY
      data.nextStep.path = ROUTES.SUMMARY
      data.activeStep = STEPS.SELECT_ADDONS
      data.nextStep.name = TEXTS.BOTTOM_BAR_BTN_NAMES.ADDONS
      data.prevStep = isEditActiveOrder ? ROUTES.DISHES : orderType === ORDER_TYPE.SINGLE ? ROUTES.MAIN : ROUTES.DISHES
      break

    case ROUTES.SUMMARY:
      data.prevStep = ROUTES.ADDONS
      data.activeStep = STEPS.SUMMARY
      data.nextStep.index = STEPS.SHIPPING
      data.nextStep.path = ROUTES.SHIPPING
      data.nextStep.name = isEditActiveOrder
        ? TEXTS.BOTTOM_BAR_BTN_NAMES.CHANGE_SHIPPING
        : TEXTS.BOTTOM_BAR_BTN_NAMES.CHANGE_SHIPPING
      break

    case ROUTES.SHIPPING:
      data.prevStep = ROUTES.SUMMARY
      data.activeStep = STEPS.SHIPPING
      data.nextStep.index = !isCreditCardAdded ? STEPS.PAYMENT : STEPS.SHIPPING
      data.nextStep.path = !isCreditCardAdded ? ROUTES.PAYMENT : ROUTES.SHIPPING
      data.nextStep.name = shippingButtonName()
      break

    case ROUTES.PAYMENT:
      data.activeStep = STEPS.PAYMENT
      data.prevStep = ROUTES.SHIPPING
      data.nextStep.index = STEPS.PAYMENT
      data.nextStep.path = ROUTES.PAYMENT
      data.nextStep.name = TEXTS.BOTTOM_BAR_BTN_NAMES.PAYMENT
      break

    case ROUTES.SUCCESS:
      data.activeStep = STEPS.SUCCESS
      data.prevStep = ROUTES.SUCCESS
      data.nextStep.index = STEPS.SUCCESS
      data.nextStep.path = ROUTES.SUCCESS
      data.nextStep.name = ''
      break

    default:
      break
  }

  return data
}
