import * as React from 'react'
import classNames from 'classnames'
import { MainContext } from '../../../Containers/MainContainer/MainContainer'
import Counter from '../../FormElements/Counter/Counter'
import { IMealObj, IAddonObj } from '../../../Utils/types'
import { IProps } from './types'
import styles from './SelectionList.module.scss'

const SelectionList: React.FunctionComponent<IProps> = ({ heading, addons, meals, className, addMeal, addAddon }) => {
  const containerClass = classNames(styles.container, className)
  const isEmpty = (addons && addons.length === 0) || (meals && meals.length === 0)

  return (
    <MainContext.Consumer>
      {({ openProteinModal }) =>
        !isEmpty && (
          <div className={containerClass}>
            <h3 className={styles.h3}>{heading}</h3>

            {addons && (
              <>
                {addons.map((addonObj: IAddonObj) => (
                  <div key={addonObj.addon.id} className={styles.item}>
                    <div className={styles.row}>
                      <div className={styles.left}>
                        <img className={styles.image} src={addonObj.addon.metadata.image} />
                        <div className={styles.content}>
                          <h4 className={styles.title}>{addonObj.addon.name}</h4>
                          <Counter
                            className={styles.counter}
                            type="addon"
                            initialAmount={addonObj.amount}
                            onChange={(amount: number) => {
                              addAddon && addAddon(amount, addonObj.addon)
                            }}
                          />
                        </div>
                      </div>

                      <h4 className={styles.price}>${(addonObj.addon.price * addonObj.amount).toFixed(2)}</h4>
                    </div>
                  </div>
                ))}
              </>
            )}

            {meals && (
              <>
                {meals.map((mealObj: IMealObj) => (
                  <div key={mealObj.meal.id} className={styles.item}>
                    <div className={styles.row}>
                      <div className={styles.left}>
                        <img className={styles.image} src={mealObj.meal.metadata.image} />
                        <div className={styles.content}>
                          <h4 className={styles.title}>{mealObj.meal.name}</h4>
                          <Counter
                            className={styles.counter}
                            type="meal"
                            initialAmount={mealObj.amount}
                            onChange={(amount: number) => {
                              addMeal && addMeal(amount, mealObj.meal)
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.right}>
                        <p onClick={() => openProteinModal(mealObj.meal, mealObj.amount)} className={styles.textLink}>
                          Change Protein
                        </p>
                        <h4 className={styles.price}>
                          {(
                            mealObj.meal.price * mealObj.amount +
                            (mealObj.protein ? (mealObj.protein!.price / 100) * mealObj.amount : 0)
                          ).toFixed(2)}
                        </h4>
                      </div>
                    </div>
                    {mealObj.protein ? (
                      <div className={`${styles.row} ${styles.proteinRow}`}>
                        <div className={styles.proteinInner}>
                          <p className={styles.proteinName}>+{mealObj.protein.name}</p>
                          <p className={styles.proteinPrice}>${(mealObj.protein.price / 100).toFixed(2)}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </>
            )}
          </div>
        )
      }
    </MainContext.Consumer>
  )
}

export default SelectionList
