import { IOrder, PackageType } from '../Utils/types'
import { tax, shippingPrice, ORDER_TYPE } from '../Utils/constants'

interface ISummaryData {
  discount: number
  tax: number
  shipping: number
  total: number | string
  subtotal: number | string
  mealsPrice: number | string
  addonsPrice: number | string
}

const getDiscount = (packageType: PackageType) => {
  if (packageType === 10) {
    return 0.04
  } else if (packageType === 15) {
    return 0.07
  } else if (packageType === 20) {
    return 0.1
  }

  return 1
}

export const getPriceSummary = (order: IOrder): ISummaryData => {
  const subTotal = order.totalPrice
  const discountCode = order.discountCode
  const discount = !discountCode.amount
    ? getDiscount(order.packageType)
    : discountCode.type === 'Percent'
    ? discountCode.amount / 100
    : discountCode.amount
  const subTotalWithDiscount =
    discount < 1
      ? subTotal - subTotal * discount
      : discountCode.type === 'Cash'
      ? subTotal - discountCode.amount
      : subTotal
  const taxValue = +subTotalWithDiscount * tax
  const shipping = order.orderType === ORDER_TYPE.SINGLE ? shippingPrice : 0

  const mealsPrice = order.meals.reduce(
    (total, item) =>
      total + (item.amount * item.meal.price + (item.protein ? (item.protein.price / 100) * item.amount : 0)),
    0
  )
  const addonsPrice = order.addons.reduce((total, item) => total + item.amount * item.addon.price, 0)

  const total = shipping > 0 ? subTotalWithDiscount + taxValue + shipping : subTotalWithDiscount + taxValue

  const data = {
    discount,
    tax: +taxValue.toFixed(2),
    shipping,
    total: total.toFixed(2),
    subtotal: subTotal.toFixed(2),
    mealsPrice: mealsPrice.toFixed(2),
    addonsPrice: addonsPrice.toFixed(2)
  }

  return data
}
