import * as React from 'react'

import classNames from 'classnames'

import { IProps } from './types'

import styles from './BorderCard.module.scss'

const BorderCard: React.FunctionComponent<IProps> = ({ children, className, type }) => {
  const containerClass = classNames(styles.container, className, {
    [styles.container]: type === 'normal',
    [styles.containerDashed]: type === 'dashed'
  })

  return <div className={containerClass}>{children}</div>
}

export default BorderCard
