import * as React from 'react'

import { connect } from 'react-redux'
import classNames from 'classnames'

import { getOrder } from '../../../Redux/Services/OrderService/helpers'
import { getPriceSummary } from '../../../Helpers/getPriceSummary'
import { IReduxStore } from '../../../Utils/types'
import { getCoupon } from '../../../Utils/api'
import { TEXTS } from '../../../Utils/constants'
import { updateDiscountCode } from '../../../Redux/Services/OrderService/OrderService'
import { IProps, IState } from './types'

import styles from './PriceSummary.module.scss'
import Input from '../../FormElements/Input/Input'
import Button from '../../FormElements/Button/Button'

class PriceSummary extends React.Component<IProps, IState> {
  readonly state: IState = {
    isCouponActive: false,
    promocode: '',
    isLoading: false,
    isCouponError: false
  }

  handleToggleOpenCouponForm = () => {
    this.setState((state: IState) => ({ isCouponActive: !state.isCouponActive }))
  }

  handleChangePromoCode = (id: string, value: string) => {
    this.setState({ promocode: value })
  }

  handleGetPromoCode = async () => {
    const { promocode } = this.state
    try {
      this.setState({ isLoading: true })
      const { data } = await getCoupon(promocode)
      this.props.updateDiscountCode({ id: promocode, type: data.type, amount: data.value, name: data.name })
      this.setState({
        isLoading: false,
        isCouponActive: false
      })
    } catch (err) {
      this.setState({ isCouponError: true, isLoading: false })
    }
  }

  render() {
    const { order, className } = this.props
    const { isCouponActive, promocode, isLoading, isCouponError } = this.state
    const { discountCode } = order
    const isDiscount = (order.packageType !== undefined && order.packageType !== 5) || discountCode.amount > 0
    const summary = getPriceSummary(order)
    const containerClass = classNames(styles.container, className)

    return (
      <div className={styles.topContainer}>
        <h2 className={styles.h2}>{TEXTS.PRICE_TABLE.HEADING}</h2>
        <div className={containerClass}>
          <div className={styles.summary}>
            <div className={styles.summaryColumn}>
              <p className={styles.p}>Meals:</p>
              <p className={styles.p}>Add-on's:</p>
              <p className={styles.p}>Shipping:</p>
              <p className={styles.p}>Subtotal:</p>
              <p className={styles.p}>Food & Beverage Tax:</p>
            </div>
            <div className={styles.summaryColumn}>
              <p className={styles.p}>{summary.mealsPrice > 0 ? `$${summary.mealsPrice}` : '-'}</p>
              <p className={styles.p}>{summary.addonsPrice > 0 ? `$${summary.addonsPrice}` : '-'}</p>
              <p className={styles.p}>{summary.shipping > 0 ? `$${summary.shipping}` : '-'}</p>
              <p className={styles.p}>{summary.subtotal > 0 ? `$${summary.subtotal}` : '-'}</p>
              <p className={styles.p}>${+summary.tax}</p>
            </div>
          </div>
          {isDiscount && (
            <div className={styles.discount}>
              <p>Discount for {order.packageType} meals:</p>
              <strong>{(summary.discount * 100).toFixed(0)}%</strong>
            </div>
          )}
          <div className={styles.total}>
            <div>
              <h3 className={styles.h3}>Total</h3>
            </div>
            <div>
              <h4 className={styles.h4}>{summary.total > 0 ? `$${summary.total}` : '-'}</h4>
            </div>
          </div>
          <div className={styles.coupon}>
            {!isCouponActive ? (
              <p onClick={this.handleToggleOpenCouponForm}>I have a promo code </p>
            ) : (
              <div className={styles.code}>
                <Input
                  id="promocode"
                  label="Your promocode"
                  value={promocode}
                  className={`${styles.input} ${isCouponError && styles.inputError}`}
                  onChange={this.handleChangePromoCode}
                />
                <Button
                  className={styles.buttonSubmit}
                  onClick={this.handleGetPromoCode}
                  type="green"
                  loading={isLoading}
                >
                  <p>Active code</p>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({ order: getOrder(state) })

export default connect(
  mapStateToProps,
  { updateDiscountCode }
)(PriceSummary)
