import * as React from 'react'

import { ReactComponent as Location } from '../../../../assets/svg/location.svg'
import ShipingForm from '../Components/ShipingForm/ShipingForm'
import { ReactComponent as Arrow } from '../../../../assets/svg/arrowRight.svg'

import { IProps } from './types'
import styles from './ShippingContainerView.module.scss'
import Button from '../../../../Components/FormElements/Button/Button'

const ShippingContainerView: React.FunctionComponent<IProps> = ({
  isEdited,
  toggleEdited,
  shippingData,
  onChange,
  onSubmit,
  hasShipping,
  isFormValidated
}) => (
  <div className={styles.root}>
    {isEdited ? (
      <div className={styles.form}>
        <ShipingForm onChange={onChange} data={shippingData!} />
        <div className={`${styles.flex} ${styles.buttons}`}>
          {hasShipping ? (
            <Button className={styles.buttonText} type="text" onClick={toggleEdited}>
              <p>Cancel</p>
            </Button>
          ) : null}
          <Button disabled={!isFormValidated} className={styles.buttonSubmit} onClick={onSubmit} type="green">
            <p>{shippingData!.address ? 'Save new info' : 'Save'}</p>
            <div className={styles.buttonDot}>
              <Arrow />
            </div>
          </Button>
        </div>
      </div>
    ) : shippingData!.address ? (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.action}>
            <Location className={styles.icon} />
            <button onClick={toggleEdited} className={styles.button}>
              Edit
            </button>
          </div>
          <div>
            <p className={styles.textBold}>{shippingData!.address}</p>
            <p className={styles.textBold}>Apt. {shippingData!.apartment}</p>
            <p className={styles.text}>
              {shippingData!.zipCode}, {shippingData!.city}
            </p>
          </div>
        </div>
      </div>
    ) : null}
  </div>
)

export default ShippingContainerView
