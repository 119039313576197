import axios, { AxiosPromise } from 'axios'
import { IGetProducts, IUserLogin, IUserData, IUserRegister, IBuyData, IActiveOrder, ICardInfo } from '../types'
import { RecursivePartial } from '../helpers'

const API_URL = process.env.REACT_APP_API_URL

export const getProductList = (): AxiosPromise<IGetProducts> =>
  axios.get(`${API_URL}/shop/products`, {
    headers: {
      'Content-type': 'application/json'
    }
  })

export const userLogin = (data: IUserLogin): AxiosPromise<IUserData> =>
  axios.post(`${API_URL}/login`, data, {
    headers: {
      'Content-type': 'application/json'
    }
  })

export const userRegister = (data: IUserRegister): AxiosPromise<{}> =>
  axios.post(`${API_URL}/user`, data, {
    headers: {
      'Content-type': 'application/json'
    }
  })

export const activateUser = (email: string, hash: string) =>
  axios.post(
    `${API_URL}/activate`,
    { email, hash },
    {
      headers: {
        'Content-type': 'application/json'
      }
    }
  )

export const resendActivationLink = (email: string) =>
  axios.post(
    `${API_URL}/activate/resend`,
    { email },
    {
      headers: {
        'Content-type': 'application/json'
      }
    }
  )

export const buy = (data: IBuyData, token: string): AxiosPromise<{}> =>
  axios.post(`${API_URL}/shop/buy`, data, {
    headers: {
      'Content-type': 'application/json',
      token
    }
  })

export const buyWithoutLogin = (data: IBuyData): AxiosPromise<{}> =>
  axios.post(`${API_URL}/shop/buy`, data, {
    headers: {
      'Content-type': 'application/json'
    }
  })

export const addCard = (cardTokenId: string, token: string): AxiosPromise<ICardInfo> =>
  axios.post(
    `${API_URL}/user/card`,
    { cardTokenId },
    {
      headers: {
        'Content-type': 'application/json',
        token
      }
    }
  )

export const updateUser = (
  userData: RecursivePartial<IUserData['user']>,
  token: string
): AxiosPromise<IUserData['user']> =>
  axios.put(`${API_URL}/user`, userData, {
    headers: {
      'Content-type': 'application/json',
      token
    }
  })

export const getOrders = (page: number, limit: number, token: string): AxiosPromise =>
  axios.get(`${API_URL}/order/${page}/${limit}`, {
    headers: {
      token
    }
  })

export const getActiveMeals = (token: string): AxiosPromise<IActiveOrder> =>
  axios.get(`${API_URL}/user/meals`, {
    headers: {
      token
    }
  })

export const updatePassword = (data: { password: string; newPassword: string }, token: string): AxiosPromise =>
  axios.put(`${API_URL}/password/update`, data, {
    headers: {
      'Content-type': 'application/json',
      token
    }
  })

export const cancelSubscription = (token: string): AxiosPromise<IUserData['user']> =>
  axios.put(
    `${API_URL}/user/cancel/subscription`,
    {},
    {
      headers: {
        'Content-type': 'application/json',
        token
      }
    }
  )

export const getCoupon = (
  couponID: string
): AxiosPromise<{
  name: string
  value: number
  type: 'Percent' | 'Cash'
}> => axios.get(`${API_URL}/coupon/${couponID}`)

export const agileCrm = (data: { email: string; phone: string; name: string }): AxiosPromise =>
  axios.post(`${API_URL}/agilecrm`, data, {
    headers: {
      'Content-type': 'application/json'
    }
  })
