export const initialState = {
  products: {
    meals: [],
    addons: [],
    proteins: []
  },
  loading: false,
  error: {
    state: false,
    msg: ''
  }
}
