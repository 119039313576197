import * as React from 'react'
import { connect } from 'react-redux'
import { getMeals, getAddons } from '../../../Redux/Services/OrderService/helpers'
import { addAddon, addMeal } from '../../../Redux/Services/OrderService/OrderService'
import { IReduxStore } from '../../../Utils/types'
import { IState, IProps } from './types'
import SummaryContainerView from '../../../Views/Order/SummaryContainerView/SummaryContainerView'

class SummaryContainer extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    code: ''
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleChange = (id: string, value: string) => {
    this.setState({ code: value })
  }

  handleNavigate = (path: string) => {
    this.props.history.push(path)
  }

  render() {
    const { meals, addons, addAddon, addMeal } = this.props
    return <SummaryContainerView addMeal={addMeal} addAddon={addAddon} meals={meals} addons={addons} />
  }
}

const mapStateToProps = (state: IReduxStore) => ({ meals: getMeals(state), addons: getAddons(state) })

export default connect(
  mapStateToProps,
  {
    addMeal,
    addAddon
  }
)(SummaryContainer)
