import IMask from 'imask'

import { validateEmail, validateZip, validateName, validateNotEmpty, validatePhone } from './validation'

const HINTS = {
  INVALID_EMAIL: 'Invalid email format',
  NOT_FILLED: 'Field required',
  ZIP: 'Required 5 digits',
  PHONE: 'Required 6 digits'
}

export const INPUTS_VALIDATION = {
  EMAIL: {
    MAX_LENGTH: 60,
    VALIDATION: validateEmail,
    HINT: HINTS.INVALID_EMAIL
  },
  FULL_NAME: {
    MAX_LENGTH: 60,
    VALIDATION: validateName,
    HINT: HINTS.NOT_FILLED,
    HINTVALID: 'Full name is not valid'
  },
  PHONE_NUMBER: {
    MAX_LENGTH: 60,
    HINT: HINTS.PHONE,
    VALIDATION: validatePhone,
    MASK: IMask.createMask({
      mask: '+ 1 - 000 - 000 - 0000'
    })
  },
  CITY: {
    MAX_LENGTH: 60,
    HINT: HINTS.NOT_FILLED,
    VALIDATION: validateNotEmpty
  },
  ADDRESS: {
    MAX_LENGTH: 60,
    HINT: HINTS.NOT_FILLED,
    VALIDATION: validateNotEmpty
  },
  APARTMENT: {
    MAX_LENGTH: 10,
    HINT: HINTS.NOT_FILLED,
    VALIDATION: validateNotEmpty,
    MASK: IMask.createMask({
      mask: '0000000000'
    })
  },
  ZIP: {
    MAX_LENGTH: 5,
    VALIDATION: validateZip,
    HINT: 'Required 5 digits',
    MASK: IMask.createMask({
      mask: '00000'
    })
  }
}
