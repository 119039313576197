import * as React from 'react'
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  ReactStripeElements
} from 'react-stripe-elements'
import { TEXTS } from '../../../Utils/constants'

import SelectionList from '../../../Components/Order/SelectionList/SelectionList'
import PriceSummary from '../../../Components/Order/PriceSummary/PriceSummary'
import ShippingInfo from '../../../Components/Order/ShippingInfo/ShippingInfo'

import { IProps } from './types'

import styles from './PaymentContainerView.module.scss'

class PaymentContainerView extends React.Component<IProps & ReactStripeElements.InjectedStripeProps> {
  componentDidMount() {
    const createToken = this.props.stripe!.createToken
    this.props.getCreateToken(createToken)
  }

  render() {
    const { meals, addons, handleFormValid } = this.props

    return (
      <div className={styles.container}>
        <h2 className={styles.heading}>We need some information from you to finalize your delivery</h2>
        <div className={styles.top}>
          <div>
            <h3 className={styles.h3}>Card info:</h3>
            <div className={styles.cardForm}>
              <div className={styles.cardItems}>
                <div className={styles.cardItem}>
                  <p className={styles.cardLabel}>
                    CARD NUMBER <span>*</span>
                  </p>
                  <CardNumberElement className={styles.stripeElement} onChange={handleFormValid} />
                </div>
                <div className={styles.flex}>
                  <div className={`${styles.cardItem} ${styles.cardExpiration}`}>
                    <p className={styles.cardLabel}>
                      EXPIRATION DATE <span>*</span>
                    </p>
                    <CardExpiryElement className={styles.stripeElement} onChange={handleFormValid} />
                  </div>
                  <div className={`${styles.cardItem} ${styles.cardCVC}`}>
                    <p className={styles.cardLabel}>
                      CVC <span>*</span>
                    </p>
                    <CardCVCElement className={styles.stripeElement} onChange={handleFormValid} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.summary}>
            <PriceSummary />
          </div>
        </div>
        <h2 className={styles.h2}>All correct? </h2>
        <div className={styles.listContainer}>
          <div className={styles.lists}>
            <SelectionList heading={TEXTS.SUMMARY.FIRST_TABLE_HEADING} meals={meals} />
            <SelectionList className={styles.list} heading={TEXTS.SUMMARY.SECOND_TABLE_HEADING} addons={addons} />
          </div>
          <ShippingInfo />
        </div>
      </div>
    )
  }
}

export default injectStripe<IProps>(PaymentContainerView)
