import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

export const getUserToken = () => {
  const stored = localStorage.getItem('token')

  if (stored) {
    return stored
  }

  return false
}

export const formatDate = (date: Date) => {
  date = new Date(date)
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  return `${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}-${year}`
}

export const showSucces = (msg: string) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT
  })
}

export const showTextError = (errorMsg: string) => {
  toast.error(errorMsg, {
    position: toast.POSITION.TOP_RIGHT
  })
}

export const showError = (error: AxiosError, defaultMsg: string = 'Error') => {
  const msg =
    error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : error.message
      ? error.message
      : defaultMsg

  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT
  })
}

export type Subtract<T, K> = Pick<T, Exclude<keyof T, keyof K>>
export type RecursivePartial<T> = { [K in keyof T]?: T[K] extends object ? RecursivePartial<T[K]> : T[K] | undefined }
