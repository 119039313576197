import * as React from 'react'

import { connect } from 'react-redux'

import { resetOrder } from '../../../../Redux/Services/OrderService/OrderService'
import { getOrderType } from '../../../../Redux/Services/OrderService/helpers'
import { TEXTS, ORDER_TYPE } from '../../../../Utils/constants'
import { IReduxStore } from '../../../../Utils/types'
import { IProps } from './types'

import MealPicker from '../../../../Components/Order/ProductPicker/ProductPicker'
import Button from '../../../../Components/FormElements/Button/Button'

import styles from './Food.module.scss'
import { Dispatch } from 'redux'

const Food: React.FunctionComponent<IProps> = ({ isProceedDisabled, handleSingleOrder, orderType, resetOrder }) => (
  <>
    <div className={styles.switcher} id="food">
      <h2 className={styles.h2}>{TEXTS.LANDING.MEALS_HEADING}</h2>
      <p className={styles.p}>{TEXTS.LANDING.MEALS_DESCRIPTION}</p>
      <MealPicker type="meal" />
    </div>
    <div className={styles.proceedContainer}>
      {orderType === ORDER_TYPE.SUBSCRIPTION ? (
        <p className={styles.info}>
          You have already picked a subscription!{' '}
          <span className={styles.reset} onClick={resetOrder}>
            Want to change it?
          </span>
        </p>
      ) : (
        <>
          <Button
            type="green"
            className={styles.button}
            onClick={handleSingleOrder}
            disabled={isProceedDisabled}
            placeholder="View my order summary"
          />
          <p className={styles.info}>You need to choose at least 5 meals for a one time order.</p>
        </>
      )}
    </div>
  </>
)

const mapStateToProps = (state: IReduxStore) => ({ orderType: getOrderType(state) })

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetOrder: () => dispatch(resetOrder())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Food)
