import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import Button from '../../../../../Components/FormElements/Button/Button'
import Input from '../../../../../Components/FormElements/Input/Input'
import { ReactComponent as Arrow } from '../../../../../assets/svg/arrowRight.svg'

import { ROUTES } from '../../../../../Utils/routes'
import { updateUser as updateUserCall } from '../../../../../Utils/api'
import { updateUser } from '../../../../../Redux/Services/UserService/UserService'
import { validateEmail, validateName, validatePhone } from '../../../../../Utils/constants/validation'
import { IProps, IState } from './types'
import styles from './ChangeInfo.module.scss'
import { showTextError, getUserToken, showError, RecursivePartial, showSucces } from '../../../../../Utils/helpers'
import { AxiosResponse, AxiosError } from 'axios'
import { IUserData } from '../../../../../Utils/types'

class ChangeInfo extends React.Component<RouteComponentProps & IProps, IState> {
  readonly state: IState = {
    isLoading: false,
    inputs: {
      fullName: this.props.data.name,
      email: this.props.data.email,
      phone: this.props.data.phone
    }
  }

  handleChange = (id: string, value: string) => {
    this.setState((state: IState) => ({
      inputs: { ...state.inputs, [id]: value }
    }))
  }

  handleSubmit = () => {
    const {
      inputs: { email, fullName, phone }
    } = this.state
    const { data } = this.props
    const token = getUserToken()

    if (email === data.email && fullName === data.name && phone === data.phone) {
      this.props.history.push(ROUTES.ACCOUNT_SETTINGS)
      return
    }

    if (email.length < 3) {
      showTextError('Email field is required!')
      return
    } else if (!validateEmail(email)) {
      showTextError('Email is not valid!')
      return
    } else if (!validateName(fullName)) {
      showTextError('The full name can only contain letters (min. 3 characters)!')
      return
    } else if (phone.length > 0 && !validatePhone(phone) && phone !== data.phone) {
      showTextError('The phone number is not valid!')
      return
    }

    const dataToUpdate: RecursivePartial<IUserData['user']> = {}

    if (data.email !== email) {
      dataToUpdate.user!.email = email
    }

    if (data.name !== fullName) {
      dataToUpdate.name = fullName
    }

    if (data.phone !== phone) {
      dataToUpdate.phone = phone
    }

    if (token) {
      this.setState({ isLoading: true })
      updateUserCall(dataToUpdate, token)
        .then((res: AxiosResponse<IUserData['user']>) => {
          this.props.updateUser(res.data)
          showSucces('The profile has been updated!')
          this.props.history.push(ROUTES.ACCOUNT_SETTINGS)
        })
        .catch((err: AxiosError) => {
          showError(err)
          this.setState({ isLoading: false })
        })
    }
  }

  handleDiscard = () => {
    this.props.history.push(ROUTES.ACCOUNT_SETTINGS)
  }

  render() {
    const {
      inputs: { email, fullName, phone },
      isLoading
    } = this.state

    return (
      <>
        <Input
          id="fullName"
          label="Full name"
          placeholder="john doe"
          className={styles.input}
          value={fullName}
          onChange={this.handleChange}
        />
        <Input
          id="email"
          label="Email"
          value={email}
          className={styles.input}
          onChange={this.handleChange}
          placeholder="johndoe@gmail.com"
        />
        <Input
          id="phone"
          type="phone"
          label="Phone number"
          value={phone}
          className={styles.input}
          placeholder="238-746-4419"
          onChange={this.handleChange}
        />
        <div className={styles.buttonContainer}>
          <Button className={styles.buttonText} type="text" onClick={this.handleDiscard}>
            <p>Discard</p>
          </Button>
          <Button className={styles.buttonSubmit} onClick={this.handleSubmit} type="green" loading={isLoading}>
            <p>Save new info</p>
            <div className={styles.buttonDot}>
              <Arrow />
            </div>
          </Button>
        </div>
      </>
    )
  }
}

export default ChangeInfo
