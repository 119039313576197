import { combineReducers } from 'redux'

import OrderReducer from './OrderService/OrderService'
import UserReducer from './UserService/UserService'
import DataReducer from './DataService/DataService'

export default combineReducers({
  order: OrderReducer,
  user: UserReducer,
  data: DataReducer
})
