import * as React from 'react'

import { connect } from 'react-redux'

import BorderCard from '../../Presentational/BorderCard/BorderCard'
import Button from '../../FormElements/Button/Button'
import { ReactComponent as Checkmark } from '../../../assets/svg/dividerCheckmark.svg'

import { TEXTS, ORDER_TYPE, STEPS } from '../../../Utils/constants'
import { ROUTES } from '../../../Utils/routes'
import { IReduxStore } from '../../../Utils/types'
import { IState, IProps } from './types'

import { getPackageInfo, getPackageType, getOrderType } from '../../../Redux/Services/OrderService/helpers'
import { resetOrder } from '../../../Redux/Services/OrderService/OrderService'

import {
  addonsClass,
  summaryClass,
  detailsClass,
  addonsTitleClass,
  summaryTitleClass,
  detailsTitleClass
} from './helpers'

import styles from './StepDivider.module.scss'
import { Dispatch } from 'redux'

class StepDivider extends React.Component<IProps, IState> {
  state: Readonly<IState> = {
    step: this.props.step
  }

  componentDidUpdate() {
    if (this.state.step !== this.props.step) {
      this.setState({ step: this.props.step })
    }
  }

  render() {
    const { step } = this.state
    const { packageInfo, packageType, orderType, resetOrder, history } = this.props

    return (
      <div className={styles.background}>
        {orderType === ORDER_TYPE.SUBSCRIPTION && (
          <BorderCard className={styles.planContainer} type="dashed">
            <div className={styles.plan}>
              {packageType !== 5 && (
                <h4 className={styles.heading}>{packageType === 10 ? 4 : packageType === 15 ? 7 : 10}% discount</h4>
              )}
              <div className={styles.action}>
                <div className={styles.packInfo}>
                  <p className={styles.p}>
                    <span className={styles.amount}>{packageType}</span> <span className={styles.meals}>meals</span>
                  </p>
                  <p className={styles.interval}>per week</p>
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    type="radial-decrement"
                    className={styles.cancel}
                    onClick={() => {
                      resetOrder()
                      history.push(ROUTES.MAIN)
                    }}
                  />
                  <p className={styles.cancelText}>Modify</p>
                </div>
              </div>
            </div>
          </BorderCard>
        )}
        <div className={styles.container}>
          <div className={styles.step}>
            <div className={styles.circleActive}>{step > STEPS.SELECT_DISHES ? <Checkmark /> : <p>1</p>}</div>
            <p className={styles.titleActive}>{TEXTS.ORDER_STEPS.FIRST}</p>
          </div>
          <div className={styles.step}>
            <div className={addonsClass(step)}>{step > STEPS.SELECT_ADDONS ? <Checkmark /> : <p>2</p>}</div>
            <p className={addonsTitleClass(step)}>{TEXTS.ORDER_STEPS.SECOND}</p>
          </div>
          <div className={styles.step}>
            <div className={summaryClass(step)}>{step > STEPS.SUMMARY ? <Checkmark /> : <p>3</p>}</div>
            <p className={summaryTitleClass(step)}>{TEXTS.ORDER_STEPS.THIRD}</p>
          </div>
          <div className={styles.step}>
            <div className={detailsClass(step)}>
              <p>4</p>
            </div>
            <p className={detailsTitleClass(step)}>{TEXTS.ORDER_STEPS.FOURTH}</p>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetOrder: () => dispatch(resetOrder())
})

const mapStateToProps = (state: IReduxStore) => ({
  packageInfo: getPackageInfo(state),
  packageType: getPackageType(state),
  orderType: getOrderType(state)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepDivider)
