import * as React from 'react'
import moment, { Moment } from 'moment'
import TimeBox from './Components/TimeBox/TimeBox'

import { IProps, IState } from './types'
import styles from './Timer.module.scss'

class Timer extends React.Component<IProps, IState> {
  private timer: number
  readonly state: IState = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  }

  constructor(props: IProps) {
    super(props)
    this.timer = window.setInterval(this.countdown, 1000)
  }

  countdown = () => {
    const { deliveryDates } = this.props
    let diff = undefined

    const firstDelivery = moment(deliveryDates[0]).subtract(48, 'hours')
    const secondDelivery = moment(deliveryDates[1]).subtract(48, 'hours')

    if (+firstDelivery.format('x') - Date.now() < 0) {
      diff = this.calculateDiff(+secondDelivery.format('x'))
    } else {
      diff = this.calculateDiff(+firstDelivery.format('x'))
    }

    if (diff) {
      const { days, hours, minutes, seconds } = diff

      this.setState({
        days,
        hours,
        minutes,
        seconds
      })
    } else {
      if (this.timer) {
        clearInterval(this.timer)
      }
    }
  }

  calculateDiff = (deliveryDate: number) => {
    let diff = deliveryDate - Date.now()
    let daysToDateFix
    let daysWithRest = diff / (24 * 60 * 60 * 1000)
    let days = Math.floor(daysWithRest)

    if (days < 1) {
      daysToDateFix = 1
    } else {
      daysToDateFix = days
    }

    const hoursWithRest = (daysWithRest % daysToDateFix) * 24
    const hours = Math.floor(hoursWithRest)

    const minutesWithRest = (hoursWithRest - hours) * 60
    const minutes = Math.floor(minutesWithRest)

    const secondsWithRest = Math.floor((minutesWithRest - minutes) * 60)
    const seconds = Math.floor(secondsWithRest)

    return { days, hours, minutes, seconds }
  }

  render() {
    const { days, hours, minutes, seconds } = this.state
    const { deliveryDates } = this.props
    let deadline = moment()

    const firstDelivery = moment(deliveryDates[0]).subtract(48, 'hours')
    const secondDelivery = moment(deliveryDates[1]).subtract(48, 'hours')

    if (+firstDelivery.format('x') - Date.now() < 0) {
      deadline = secondDelivery
    } else {
      deadline = firstDelivery
    }
    const dealineDayName = deadline.format('dddd')
    const deliveryDay = dealineDayName === 'Friday' ? 'Sunday night' : 'Wednesday night'

    return (
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <p className={styles.p}>
            <span className={styles.bold}>Neighborhoods available for delivery:</span>
          </p>
          <p className={styles.p}>Streeterville, River North, Gold Coast, River West, The Loop, Lakeshore East</p>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.timer}>
            <TimeBox withDivider type="days" time={days} />
            <TimeBox withDivider type="hours" time={hours} />
            <TimeBox withDivider type="minutes" time={minutes} />
            <TimeBox type="seconds" time={seconds} />
          </div>
          <p className={styles.text}>
            Place your order by {dealineDayName} at 12PM to receive your meals {deliveryDay}
          </p>
        </div>
      </div>
    )
  }
}

export default Timer
