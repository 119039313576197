export const items = [
  {
    text:
      'I’ve been using these meals for weight loss and they have helped tremendously. I also feel like my eating routine has been more structured ever since starting this program.',
    author: 'Rebecca S.',
    img: 'https://s3.amazonaws.com/redvike-chefs/48.png'
  },
  {
    text:
      'The meals are absolutely fantastic. They are tasty, organic, locally sourced, and best of all save me time! I love my experience with Elevated Chefs so far.',
    author: 'Ankit P.',
    img: 'https://s3.amazonaws.com/redvike-chefs/48.png'
  },
  {
    text: `As a 5’ tall female, it's hard to find meals that align with my natural metabolic rate. Eating out at restaurants I’ve always had to deal with portion sizes that are too large for my height and weight. Elevated Chefs has solved my problem! I’ve been ordering through Chef Sal for the last 2 years!`,
    author: 'Beth L.',
    img: 'https://s3.amazonaws.com/redvike-chefs/48.png'
  }
]
