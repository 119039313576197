import * as React from 'react'

import classNames from 'classnames'

import { IProps, IState } from './types'
import { IAddonObj } from '../../../../../Utils/types'

import Loader from '../../../../Presentational/Loader/Loader'
import Counter from '../../../../FormElements/Counter/Counter'

import styles from '../../styles/index.module.scss'

class AddonItem extends React.Component<IProps> {
  state: Readonly<IState> = {
    loaded: false
  }

  handleLoad = () => {
    this.setState({ loaded: true })
  }

  render() {
    const { addon, amount, type, addAddon, orderAddons } = this.props
    const { loaded } = this.state

    const imageClass = classNames(styles.mealImage, {
      [styles.invisible]: !loaded
    })

    const addonInOrder = orderAddons.find((item: IAddonObj) => item.addon.id === addon.id)

    return (
      <div className={styles.mealContainer} key={addon.id}>
        <img className={imageClass} src={addon.metadata.image} onLoad={this.handleLoad} />
        {!loaded && (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        )}
        <div className={styles.mealContent}>
          <h3 className={styles.mealName}>{addon.name}</h3>
          <div className={styles.counterContainer}>
            <p className={styles.mealPrice}>${addon.price}</p>
            <Counter
              type={type}
              initialAmount={addonInOrder ? addonInOrder.amount : amount}
              onChange={(amount: number) => addAddon(amount, addon)}
            />
            <p className={styles.mealCalories}>{addon.metadata.calories} cal</p>
          </div>
        </div>
      </div>
    )
  }
}

export default AddonItem
