import * as React from 'react'

import { ReactComponent as Pen } from '../../../../../assets/svg/pen.svg'

import { IProps } from './types'
import styles from './TopBar.module.scss'

const TopBar: React.FunctionComponent<IProps> = ({ mealPack, goToEditSubscription }) => (
  <div className={styles.topBar}>
    <div className={styles.content}>
      <p className={styles.packText}>
        <span className={styles.mealsAmount}>{mealPack}</span> meals
      </p>
      <span className={styles.divider} />
      <div className={styles.flex}>
        <p className={styles.textGreen}>Active</p>
        <p className={styles.label}>Status</p>
      </div>
    </div>
    <button onClick={goToEditSubscription} className={styles.button}>
      <Pen className={styles.icon} />
      Modify plan
    </button>
  </div>
)

export default TopBar
