import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { Moment } from 'moment'

import SubscribeContainerView from './View/SubscribeContainerView'
import { setOrderType, setSubscriptionType, setOrder } from '../../../Redux/Services/OrderService/OrderService'
import { updateUser } from '../../../Utils/api'
import { getUserToken } from '../../../Utils/helpers'
import { ORDER_TYPE } from '../../../Utils/constants'
import {
  ISubscriptionType,
  IReduxStore,
  IActiveOrder,
  IOrderType,
  IPackageInfo,
  IAddonDetails,
  IMealDetails
} from '../../../Utils/types'
import { ROUTES } from '../../../Utils/routes'
import { selectActiveOrder } from '../../../Redux/Services/UserService/UserService'

interface IState {
  isModalOpen: boolean
  packageData: {
    type: ISubscriptionType | null
    price: number
    name: string
  }
}

interface IProps {
  activeOrder: IActiveOrder
  deliveryDate: Moment | null
  setOrder: (meals: IMealDetails[], addons: IAddonDetails[]) => void
  setOrderType: (orderType: IOrderType) => void
  setSubscriptionType: (subscriptionType: ISubscriptionType, packageInfo: IPackageInfo) => void
}

const initializeState = () => ({
  isModalOpen: false,
  packageData: {
    type: null,
    price: 0,
    name: ''
  }
})

class SubscribeContainer extends React.Component<RouteComponentProps & IProps, IState> {
  state: Readonly<IState> = initializeState()

  handleSubmit = () => {
    const token = getUserToken()
    const { setOrderType, setSubscriptionType, setOrder, activeOrder } = this.props
    const { packageData } = this.state

    setOrderType(ORDER_TYPE.SUBSCRIPTION)
    setSubscriptionType(packageData.type!, { name: packageData.name, price: packageData.price })

    if (token) {
      updateUser({ maxMeals: packageData.type! }, token)

      if (activeOrder!.meals) {
        setOrder(activeOrder!.meals, activeOrder!.addons)
      }

      this.props.history.push({
        pathname: ROUTES.ORDER,
        state: {
          changeActiveMeals: true
        }
      })
    }
  }

  handleOpen = (packageData: { type: ISubscriptionType; price: number; name: string }) => {
    this.setState({ packageData })
    this.setState({ isModalOpen: true })
  }

  handleClose = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    const { activeOrder, deliveryDate } = this.props

    return (
      <SubscribeContainerView
        deliveryDate={deliveryDate}
        activePackage={activeOrder.maxMeals}
        onClick={this.handleOpen}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
        isModalOpen={this.state.isModalOpen}
      />
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  activeOrder: selectActiveOrder(state)
})

export default connect(
  mapStateToProps,
  { setOrderType, setSubscriptionType, setOrder }
)(SubscribeContainer)
