import * as React from 'react'

import { MainContext, IContext } from '../../../Containers/MainContainer/MainContainer'
import { TEXTS } from '../../../Utils/constants'
import { IProps } from './types'

import { getUserToken } from '../../../Utils/helpers'
import PriceSummary from '../../../Components/Order/PriceSummary/PriceSummary'
import DeliveryDatePicker from '../../../Components/Order/DeliveryDatePicker/DeliveryDatePicker'
import ShippingForm from '../../../Components/Order/ShippingForm/ShippingForm'

import styles from './ShippingContainerView.module.scss'

const ShippingContainerView: React.FunctionComponent<IProps> = ({
  zip,
  name,
  city,
  email,
  phone,
  address,
  onChange,
  apartment,
  selectedDate,
  packageType,
  isAddressShowed,
  handleSelectDate
}) => {
  const token = getUserToken()
  return (
    <MainContext.Consumer>
      {({ deliveryDates }: IContext) => (
        <div className={styles.container}>
          <h2 className={styles.h2}>
            {!token && packageType !== undefined ? TEXTS.SHIPPING.HEADING2 : TEXTS.SHIPPING.HEADING}
          </h2>
          <div className={styles.content}>
            {!token ? (
              <ShippingForm
                zip={zip}
                name={name}
                city={city}
                email={email}
                phone={phone}
                address={address}
                apartment={apartment}
                onChange={onChange}
                packageType={packageType}
                isAddressShowed={isAddressShowed}
              />
            ) : (
              <ShippingForm
                zip={zip}
                city={city}
                address={address}
                apartment={apartment}
                onChange={onChange}
                isAddressShowed={isAddressShowed}
              />
            )}
            <div className={styles.summary}>
              <PriceSummary />
              <DeliveryDatePicker
                selectedDate={selectedDate}
                deliveryDates={deliveryDates}
                handleSelectDate={handleSelectDate}
              />
            </div>
          </div>
        </div>
      )}
    </MainContext.Consumer>
  )
}

export default ShippingContainerView
