import * as React from 'react'
import Button from '../../../../Components/FormElements/Button/Button'
import CardInfo from '../Components/CardInfo/CardInfo'
import CardEdit from '../Components/CardEdit/CardEdit'
import { ReactComponent as Arrow } from '../../../../assets/svg/arrowRight.svg'
import { ICardInfo } from '../../../../Utils/types'
import { IElement } from '../Types'
import styles from './BillingContainerView.module.scss'

interface IProps {
  isEdit: boolean
  isLoading: boolean
  creditCardData: ICardInfo | undefined
  handleToggleEdit: () => void
  handleSetRef: (element: IElement, type: string) => void
  onSubmit: () => void
}

const BillingContainerView: React.FunctionComponent<IProps> = ({
  onSubmit,
  isEdit,
  isLoading,
  creditCardData,
  handleToggleEdit,
  handleSetRef
}) => (
  <div className={styles.container}>
    <div className={styles.form}>
      {!isEdit ? <CardInfo cardInfo={creditCardData!} /> : <CardEdit handleSetRef={handleSetRef} />}
    </div>
    <div className={styles.buttonContainer}>
      <Button
        loading={isLoading}
        className={styles.buttonSubmit}
        onClick={isEdit ? onSubmit : handleToggleEdit}
        type="green"
      >
        {!isEdit ? (
          <p>Change active card</p>
        ) : (
          <p>{creditCardData !== undefined ? 'Edit existing card' : 'Add new card'}</p>
        )}
        <div className={styles.buttonDot}>
          <Arrow />
        </div>
      </Button>
      {isEdit ? (
        <Button className={styles.buttonBack} onClick={handleToggleEdit} type="text">
          Back
        </Button>
      ) : null}
    </div>
  </div>
)

export default BillingContainerView
