import * as React from 'react'

import classNames from 'classnames'

import { IProps } from './types'

import { ReactComponent as Quote } from '../../../../../../assets/svg/quote.svg'

import styles from './Item.module.scss'

const Item: React.FunctionComponent<IProps> = ({ text, active, img, author }) => {
  const itemClass = classNames(styles.item, {
    [styles.itemActive]: active
  })

  const imgClass = classNames(styles.img, {
    [styles.imgActive]: active
  })

  return (
    <div className={styles.container}>
      <div className={itemClass}>
        <Quote />
        <p className={styles.p}>{text}</p>
        <img className={imgClass} src={img} />
      </div>
      <p className={styles.person}>{author}</p>
    </div>
  )
}

export default Item
