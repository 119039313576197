export const saveItem = (name: string, data: Object): void => {
  removeItem(name)
  localStorage.setItem(name, JSON.stringify(data))
}

export const getItem = (name: string): { [key: string]: any } | undefined => {
  const item = JSON.parse(localStorage.getItem(name)!)

  if (item) {
    return item
  } else {
    return undefined
  }
}

export const removeItem = (name: string): boolean => {
  const item = localStorage.getItem(name)

  if (item) {
    localStorage.removeItem(name)
    return false
  } else {
    return true
  }
}
