import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Link, RouteComponentProps } from 'react-router-dom'

import { ROUTES } from '../../../Utils/routes'
import ChangePassword from './Components/ChangePassword/ChangePassword'
import ChangeInfo from './Components/ChangeInfo/ChangeInfo'
import Input from '../../../Components/FormElements/Input/Input'
import ShouldRender from '../../../Components/Logical/ShouldRender/ShouldRender'
import Modal from '../../../Components/Logical/Modal/Modal'
import CancelModal from './Components/CancelModal/CancelModal'
import Button from '../../../Components/FormElements/Button/Button'
import {
  updateUser,
  userLogout,
  selectIsSubscription,
  setActiveOrder
} from '../../../Redux/Services/UserService/UserService'
import styles from './AccountSettingsView.module.scss'
import { IReduxStore } from '../../../Utils/types'
import { selectUserData } from '../../../Redux/Services/UserService/UserService'
import { restartOrder } from '../../../Redux/Services/OrderService/OrderService'
import { getUserToken, showError, showSucces } from '../../../Utils/helpers'
import { cancelSubscription } from '../../../Utils/api'
import { IProps, IState } from './types'

class AccountSettingsContainer extends React.Component<RouteComponentProps & IProps, IState> {
  readonly state: IState = {
    isLoading: false
  }
  private modal = React.createRef<Modal>()

  handleLogout = () => {
    this.props.userLogout()
    this.props.restartOrder()
    this.props.history.push(ROUTES.MAIN)
  }

  showCancelModal = () => {
    this.modal.current!.handleOpen()
  }

  cancelSubscription = async () => {
    const token = getUserToken()

    if (token) {
      this.setState({ isLoading: true })
      const results = await cancelSubscription(token).catch((err) => {
        showError(err)
        this.setState({ isLoading: false })
      })

      this.props.setActiveOrder({
        _id: '',
        __v: '',
        user: '',
        meals: [],
        addons: [],
        maxMeals: 0,
        totalPrice: 0,
        createdAt: new Date(),
        deliveryDate: new Date()
      })

      if (results) {
        this.props.updateUser(results.data)
        showSucces('Subscription cannceled!')
        this.setState({ isLoading: false })
      }
    }
  }

  render() {
    const { userData, updateUser } = this.props
    const { isLoading } = this.state
    const { name, phone, user } = userData

    return (
      <div className={styles.container}>
        <ShouldRender
          onlyIf={window.location.pathname === ROUTES.ACCOUNT_SETTINGS}
          render={
            <>
              <Input
                disabled
                id="fullName"
                value={name}
                label="Full name"
                placeholder="john doe"
                className={styles.input}
                onChange={(id: string, value: string) => {}}
              />
              <Input
                disabled
                id="email"
                label="Email"
                className={styles.input}
                value={user.email}
                placeholder="johndoe@gmail.com"
                onChange={(id: string, value: string) => {}}
              />
              <Input
                disabled
                id="phone"
                label="Phone number"
                value={phone ? phone : ''}
                className={styles.input}
                placeholder="238-746-4419"
                onChange={(id: string, value: string) => {}}
              />
            </>
          }
        />

        <Switch>
          <Route exact path={ROUTES.ACCOUNT_SETTINGS_PASSWORD} component={ChangePassword} />
          <Route
            exact
            path={ROUTES.ACCOUT_SETTINGS_INFO}
            render={(props: RouteComponentProps) => (
              <ChangeInfo
                {...props}
                updateUser={updateUser}
                data={{ name, phone: phone ? phone : '', email: user.email }}
              />
            )}
          />
        </Switch>
        <ShouldRender
          onlyIf={window.location.pathname === ROUTES.ACCOUNT_SETTINGS}
          render={
            <div className={styles.navigation}>
              <Link className={styles.action} to={ROUTES.ACCOUNT_SETTINGS_PASSWORD}>
                Change the password
              </Link>
              <Link className={styles.action} to={ROUTES.ACCOUT_SETTINGS_INFO}>
                Edit Account Info
              </Link>
              <Button className={`${styles.action} ${styles.buttonText}`} type="text" onClick={this.handleLogout}>
                <p>Log out</p>
              </Button>
              {this.props.isSubscription ? (
                <Button className={`${styles.action} ${styles.buttonText}`} type="text" onClick={this.showCancelModal}>
                  <p>Cancel Future Orders</p>
                </Button>
              ) : null}
            </div>
          }
        />

        <CancelModal modal={this.modal} loading={isLoading} onSubmit={this.cancelSubscription} />
      </div>
    )
  }
}

const mapStateToProps = (state: IReduxStore) => ({
  userData: selectUserData(state),
  isSubscription: selectIsSubscription(state)
})

export default connect(
  mapStateToProps,
  { updateUser, userLogout, restartOrder, setActiveOrder }
)(AccountSettingsContainer)
