import * as React from 'react'

import classNames from 'classnames'
import { IProps } from './types'

import { TEXTS } from '../../../Utils/constants'

import SelectionList from '../../../Components/Order/SelectionList/SelectionList'
import PriceSummary from '../../../Components/Order/PriceSummary/PriceSummary'
import MealPicker from '../../../Components/Order/ProductPicker/ProductPicker'

import styles from './MealsContainerView.module.scss'

const MealsContainerView: React.FunctionComponent<IProps> = ({ order, addMeal }) => {
  const bottomInfoClass = classNames(styles.bottomInfo, {
    [styles.flexModifier]: order.addons.length === 0
  })

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Choose your meals</h2>
      <div className={styles.mealContainer}>
        <MealPicker type="meal" />
      </div>
      <div className={bottomInfoClass}>
        <SelectionList addMeal={addMeal} meals={order.meals} heading={TEXTS.ADDONS.TABLE_HEADING} />
        <PriceSummary className={styles.summary} />
      </div>
    </div>
  )
}

export default MealsContainerView
