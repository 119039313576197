import { IReduxStore } from '../../../../Utils/types'

export function getOrder(state: IReduxStore) {
  return state.order
}

export function getPackageType(state: IReduxStore) {
  return state.order.packageType
}

export function getPackageInfo(state: IReduxStore) {
  return state.order.packageInfo
}

export function getMeals(state: IReduxStore) {
  return state.order.meals
}

export function getDiscountCode(state: IReduxStore) {
  return state.order.discountCode
}

export function getAddons(state: IReduxStore) {
  return state.order.addons
}

export function getTotalPrice(state: IReduxStore) {
  return state.order.totalPrice
}

export function getTotalMeals(state: IReduxStore) {
  return state.order.totalMeals
}

export function getOrderType(state: IReduxStore) {
  return state.order.orderType
}

export function getShipping(state: IReduxStore) {
  return state.order.shipping
}
